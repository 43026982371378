import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Profile } from "stores/actions";
import { PaginatedTable } from "components";
import { usePermissions } from "hooks/usePermission";
import MonitoringProfileView from "./ProfileSingleView";
import ProfileAddForm from "./ProfileAddForm";

const MonitoringProfiles = () => {
  const hasAddPermission = usePermissions(["MONITOR_PROFILE_ADD"]);
  const hasEditPermission = usePermissions(["MONITOR_PROFILE_EDIT"]);
  const { profiles } = useSelector((state) => state.profiles);

  const [showModal, setShowModal] = useState(false);
  const [currentProfile, setCurrentProfile] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedProfileItem, setSelectedProfileItem] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Profile.fetchProfiles("monitoring"));
  }, [dispatch]);

  useEffect(() => {
    if (profiles.length > 0) {
      setSelectedProfile(profiles[0]);
    }
  }, [profiles]);

  useEffect(() => {
    if (selectedProfile?.items?.length > 0) {
      setSelectedProfileItem(selectedProfile.items[0]);
    }
  }, [selectedProfile]);

  const handleClone = async (profile) => {
    const baseName = `${profile.name}_cloned`;
    const similarNames = profiles.filter((p) => p.name.startsWith(baseName));
    const newName =
      similarNames.length > 0
        ? `${baseName}_${similarNames.length + 1}`
        : baseName;

    const clonedData = await dispatch(
      Profile.createProfile({
        name: newName,
        type: "monitoring",
        details: profile.details,
      })
    );

    setCurrentProfile(clonedData?.payload);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    await dispatch(Profile.deleteProfile(id));
  };

  const onView = (profile) => {
    setSelectedProfile(profile);
  };

  const onEdit = () => {
    setCurrentProfile(selectedProfile);
    setShowModal(true);
  };

  return (
    <div>
      <Row className="my-3 mx-1">
        <Col>
          <div className="my-2">
            <h5>
              <b>Profiles</b>
            </h5>
            <small className="sub-info">
              All monitoring-related profiles are listed here
            </small>
          </div>
        </Col>
        <Col className="col-auto">
          {hasAddPermission && (
            <Button onClick={() => setShowModal(true)}>Add New Profile</Button>
          )}
        </Col>
      </Row>

      <Row>
        <Col md={2}>
          <PaginatedTable
            data={profiles}
            columns={[{ dataField: "name", text: "Name" }]}
            perPage={100}
            paginationRequired={false}
            filterRequired={false}
            onView={onView}
          />
        </Col>
        <Col>
          {selectedProfile && (
            <MonitoringProfileView
              selectedProfile={selectedProfile}
              onEdit={hasEditPermission ? onEdit : null}
            />
          )}
        </Col>
      </Row>

      {showModal && (
        <ProfileAddForm
          show={showModal}
          onClose={() => setShowModal(false)}
          currentProfile={currentProfile}
        />
      )}
    </div>
  );
};

export default MonitoringProfiles;
