import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Alert, Modal } from "react-bootstrap";
import * as yup from "yup";
import { CONTACT_INIT } from "constants/headendInit";
import deepClone from "utils/deepClone";

const TenantForm = ({
  organization,
  onSave,
  buttonName,
  showModal,
  closeModal,
}) => {
  const [formData, setFormData] = useState(organization);
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    setFormData(organization);
  }, [organization]);

  const tenantSchema = yup.object().shape({
    name: yup.string().required("Organization name is required."),
    contacts: yup
      .array()
      .of(
        yup.object().shape({
          contact_type: yup.string().required("Contact type is required."),
          name: yup.string().required("Contact name is required."),
          email: yup
            .array()
            .of(yup.string().email("Invalid email format."))
            .required("At least one email is required."),
        })
      )
      .min(1, "At least one contact is required."),
  });

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedContacts = deepClone(formData.contacts);
    updatedContacts[index][name] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      contacts: updatedContacts,
    }));
  };

  const handleAddContact = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      contacts: [...prevFormData.contacts, CONTACT_INIT],
    }));
  };

  const handleRemoveContact = (index) => {
    const updatedContacts = [...formData.contacts];
    updatedContacts.splice(index, 1);
    setFormData((prevFormData) => ({
      ...prevFormData,
      contacts: updatedContacts,
    }));
  };

  const handleAddEmail = (contactIndex) => {
    const updatedContacts = deepClone(formData.contacts);

    const emails = updatedContacts[contactIndex].email;
    emails.push("");
    updatedContacts[contactIndex].email = emails;
    setFormData((prevFormData) => ({
      ...prevFormData,
      contacts: updatedContacts,
    }));
  };

  const handleRemoveEmail = (contactIndex, emailIndex) => {
    const updatedContacts = deepClone(formData.contacts);
    updatedContacts[contactIndex].email.splice(emailIndex, 1);
    setFormData((prevFormData) => ({
      ...prevFormData,
      contacts: updatedContacts,
    }));
  };

  const handleSave = async () => {
    try {
      await tenantSchema.validate(formData, { abortEarly: false });
      setValidationErrors([]);
      onSave(formData, closeModal);
    } catch (error) {
      if (error.inner) {
        const errors = error.inner.map((err) => err.message);
        setValidationErrors(errors);
      } else {
        setValidationErrors([error.message]);
      }
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add New Organisation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {validationErrors.length > 0 && (
            <Alert variant="danger">
              {validationErrors.map((error, index) => (
                <p key={index}>{error}</p>
              ))}
            </Alert>
          )}

          <Form>
            <Row className="mb-3">
              <Form.Group controlId="formOrganizationName">
                <Form.Label>Name:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData?.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Col sm={11}>
                <h5>Contacts: </h5>
              </Col>
              <Col sm={1}>
                <Button
                  variant="primary float-right"
                  onClick={handleAddContact}
                >
                  +
                </Button>
              </Col>
            </Row>
            {formData?.contacts?.map((contact, contactIndex) => (
              <div key={contactIndex} className="border rounded p-3 mb-3">
                <h3>Contact {contactIndex + 1}</h3>
                <Row>
                  <Col>
                    <Form.Group controlId={`formContactType-${contactIndex}`}>
                      <Form.Label>Contact Type:</Form.Label>
                      <Form.Control
                        type="text"
                        name="contact_type"
                        value={contact?.contact_type}
                        onChange={(e) => handleInputChange(e, contactIndex)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId={`formContactName-${contactIndex}`}>
                      <Form.Label>Contact Name:</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={contact?.name}
                        onChange={(e) => handleInputChange(e, contactIndex)}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col>
                    <Form.Group controlId={`formContactType-${contactIndex}`}>
                      <Form.Label>Phone:</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone"
                        value={contact?.phone}
                        onChange={(e) => handleInputChange(e, contactIndex)}
                      />
                    </Form.Group>
                  </Col> */}
                  <Col>
                    <Form.Group controlId={`formContactName-${contactIndex}`}>
                      <Form.Label>Timezone:</Form.Label>
                      <Form.Control
                        type="text"
                        name="timezone"
                        value={contact?.timezone}
                        onChange={(e) => handleInputChange(e, contactIndex)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId={`formContactEmail-${contactIndex}`}>
                  <Row className=" my-2">
                    <Col sm={11}>
                      <Form.Label>Email(s): </Form.Label>
                    </Col>
                    <Col sm={1}>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => handleAddEmail(contactIndex)}
                      >
                        +
                      </Button>
                    </Col>
                  </Row>

                  {contact?.email.map((email, emailIndex) => (
                    <Row key={emailIndex} className="mb-2">
                      <Col xs={11}>
                        <Form.Control
                          type="text"
                          value={email}
                          placeholder="Email"
                          className="col-xs-8"
                          onChange={(e) => {
                            const updatedEmails = [...contact.email];
                            updatedEmails[emailIndex] = e.target.value;
                            handleInputChange(
                              {
                                target: { name: "email", value: updatedEmails },
                              },
                              contactIndex
                            );
                          }}
                        />
                      </Col>
                      {contact?.email.length > 1 && (
                        <Col xs={1}>
                          <Button
                            variant="danger"
                            size="sm"
                            className="ml-2 col-xs-4"
                            onClick={() =>
                              handleRemoveEmail(contactIndex, emailIndex)
                            }
                          >
                            -
                          </Button>
                        </Col>
                      )}
                    </Row>
                  ))}
                </Form.Group>
                <Button
                  variant="danger"
                  onClick={() => handleRemoveContact(contactIndex)}
                >
                  Remove Contact
                </Button>
              </div>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" type="submit" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TenantForm;
