import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  Form,
  Button,
  Card,
  Row,
  Badge,
  Collapse,
} from "react-bootstrap";
import randomNumber from "utils/randomNumber";
import { ORG_INIT } from "constants/headendInit";
import TenantForm from "../../Tenants/AddForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import createOrgTree from "utils/createOrgTree";
import { useDispatch } from "react-redux";
import { createTenant } from "stores/actions";

const AddOrganisationsTree = ({ organisations, updateFormData, isEdit }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [orgToEdit, setOrgToEdit] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [collapsedTenants, setCollapsedTenants] = useState({});

  const allOrganisations = useMemo(() => {
    return createOrgTree(organisations);
  }, [organisations]);

  const toggleCollapse = (id) => {
    setCollapsedTenants((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Function to update a node by id
  const updateNodeById = (nodes, targetId, newData, field) => {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node.id === targetId) {
        let updatedNode = { ...node, [field]: newData };
        if (field === "tenants") {
          updatedNode = { ...node, [field]: [...node[field], newData] };
        }
        nodes[i] = updatedNode;
        return true;
      }
      if (node.tenants && node.tenants.length > 0) {
        const nodeUpdated = updateNodeById(
          node.tenants,
          targetId,
          newData,
          field
        );
        if (nodeUpdated) {
          return true;
        }
      }
    }
    return false;
  };

  // Function to delete a node by id
  const deleteNodeById = (nodes, targetId) => {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id === targetId) {
        nodes.splice(i, 1); // Remove node from array
        return true; // Node deleted
      }
      if (nodes[i].tenants && nodes[i].tenants.length > 0) {
        const nodeDeleted = deleteNodeById(nodes[i].tenants, targetId);
        if (nodeDeleted) {
          return true;
        }
      }
    }
    return false;
  };

  const handleOrganisationChange = (orgId, e) => {
    const { name, value } = e.target;
    const updatedOrganisations = [...organisations];

    const updateOrg = updateNodeById(updatedOrganisations, orgId, value, name);
    if (updateOrg) updateFormData(updatedOrganisations);
  };

  const addTenant = (orgId) => {
    const updatedOrganisations = [...organisations];
    const updateOrg = updateNodeById(
      updatedOrganisations,
      orgId,
      {
        ...ORG_INIT,
        id: randomNumber(),
      },
      "tenants"
    );
    if (updateOrg) updateFormData(updatedOrganisations);
  };

  const addOrganisation = () => {
    updateFormData([
      ...organisations,
      {
        ...ORG_INIT,
        id: randomNumber(),
      },
    ]);
  };

  const saveOrg = async (org, index, handleClose) => {
    try {
      const res = await dispatch(createTenant(org));
      const updatedOrganisations = [...organisations];
      if (index !== -1) {
        updatedOrganisations[index] = res;
      } else {
        updatedOrganisations.push(res);
      }
      handleClose();
    } catch (error) {
      handleClose();
    }
  };

  const renderOrganisation = (organisation, orgIndex) => {
    const isCollapsed = collapsedTenants[organisation._id];
    return (
      <div key={organisation._id} className="tree-card">
        <div className="tree-body">
          <label>
            <b>
              {organisation.name}
              {organisation?.tenantCount ? (
                <>
                  {" -  "}
                  <Badge pill bg="info">
                    {organisation.tenantCount}
                  </Badge>
                </>
              ) : (
                ""
              )}
            </b>
            &nbsp;&nbsp;
            <FontAwesomeIcon
              className="link"
              icon={faEdit}
              onClick={() => {
                setOrgToEdit(organisation);
                setSelectedIndex(orgIndex);
                setShowModal(true);
              }}
            />
            {organisation.tenants.length > 0 && (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIcon
                  className="link"
                  icon={isCollapsed ? faChevronRight : faChevronDown}
                  onClick={() => toggleCollapse(organisation._id)}
                />
              </>
            )}
          </label>
          {organisation?.tenants?.length > 0 && (
            <Collapse in={!isCollapsed}>
              <div>
                {organisation.tenants.map((tenant, tenantIndex) => (
                  <div key={tenant._id} className="tree-node">
                    {renderOrganisation(tenant, tenantIndex)}
                  </div>
                ))}{" "}
                {!isEdit && (
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={() => addTenant(organisation._id)}
                    className="add-tenant-button"
                  >
                    + Tenant
                  </Button>
                )}
              </div>
            </Collapse>
          )}
        </div>
      </div>
    );
  };

  return (
    <Container>
      {allOrganisations?.map((organisation, orgIndex) => (
        <div key={organisation.id} className="mb-3">
          <Card>
            <Card.Header>Tenant {orgIndex + 1}</Card.Header>
            <Card.Body>{renderOrganisation(organisation, orgIndex)}</Card.Body>
          </Card>
        </div>
      ))}
      {!isEdit && (
        <Button size="sm" variant="primary" onClick={addOrganisation}>
          + Tenants
        </Button>
      )}
      <TenantForm
        organization={orgToEdit}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        onSave={(org, handleClose) => saveOrg(org, selectedIndex, handleClose)}
      />
      {/* <pre>{JSON.stringify(organisations, null, 2)}</pre> */}
    </Container>
  );
};

export default AddOrganisationsTree;
