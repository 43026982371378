import React, { useEffect } from "react";
import { Row, Col, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Profile } from "stores/actions";
import { Tab } from "bootstrap";
import MonitoringProfileItems from "../Components/ProfileItems/ProfileItems";
import MonitoringProfiles from "../Components/Profile/Profiles";

const MonitoringProfileTab = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Profile.fetchProfiles("monitoring"));
  }, []);

  return (
    <div>
      <Row className="mb-3 mx-1">
        <Col>
          <div className="my-2">
            <h5>
              <b>Monitoring Profiles</b>
            </h5>
            <small className="sub-info">
              All monitoring related profiles are available here
            </small>
          </div>
        </Col>
      </Row>
      <Tabs>
        <Tab eventKey="profiles" title="Profiles">
          <MonitoringProfiles />
        </Tab>
        <Tab eventKey="items" title="Profile Items">
          <MonitoringProfileItems />
        </Tab>
      </Tabs>
    </div>
  );
};

export default MonitoringProfileTab;
