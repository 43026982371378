// reducers/securitySlice.js
import { createSlice } from "@reduxjs/toolkit";
import { Security } from "../actions";

const passwordInit = {
  gui: {},
  ssh: {},
};

const securitySlice = createSlice({
  name: "security",
  initialState: {
    nmap: [],
    aiChart: [],
    aiWebsites: [],
    aiPolicyAction: [],
    passwords: passwordInit,
    loading: {
      nmap: false,
    },
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Security.fetchNmapReport.pending, (state) => {
        state.loading["nmap"] = true;
        state.error = null;
        state.nmap = [];
      })
      .addCase(Security.fetchNmapReport.fulfilled, (state, action) => {
        state.loading["nmap"] = false;
        state.nmap = action.payload;
      })
      .addCase(Security.fetchNmapReport.rejected, (state, action) => {
        state.loading["nmap"] = false;
        state.error = action.payload;
      })
      .addCase(Security.fetchGenerativeAiChart.pending, (state) => {
        state.loading["fetchGenerativeAiChart"] = true;
        state.error = null;
      })
      .addCase(Security.fetchGenerativeAiChart.fulfilled, (state, action) => {
        state.loading["fetchGenerativeAiChart"] = false;
        state.aiChart = action.payload.response;
      })
      .addCase(Security.fetchGenerativeAiChart.rejected, (state, action) => {
        state.loading["fetchGenerativeAiChart"] = false;
        state.error = action.payload;
      })
      .addCase(Security.fetchGenerativeAiWebsites.pending, (state) => {
        state.loading["fetchGenerativeAiWebsites"] = true;
        state.error = null;
      })
      .addCase(
        Security.fetchGenerativeAiWebsites.fulfilled,
        (state, action) => {
          state.loading["fetchGenerativeAiWebsites"] = false;
          state.aiWebsites = action.payload.response;
        }
      )
      .addCase(Security.fetchGenerativeAiWebsites.rejected, (state, action) => {
        state.loading["fetchGenerativeAiWebsites"] = false;
        state.error = action.payload;
      })
      .addCase(Security.fetchGenerativeAiPolicyAction.pending, (state) => {
        state.loading["fetchGenerativeAiPolicyAction"] = true;
        state.error = null;
      })
      .addCase(
        Security.fetchGenerativeAiPolicyAction.fulfilled,
        (state, action) => {
          state.loading["fetchGenerativeAiPolicyAction"] = false;
          state.aiPolicyAction = action.payload.response;
        }
      )
      .addCase(
        Security.fetchGenerativeAiPolicyAction.rejected,
        (state, action) => {
          state.loading["fetchGenerativeAiPolicyAction"] = false;
          state.error = action.payload;
        }
      )
      .addCase(Security.fetchVaultPasswords.pending, (state) => {
        state.loading["fetchVaultPasswords"] = true;
        state.error = null;
        state.passwords = passwordInit;
      })
      .addCase(Security.fetchVaultPasswords.fulfilled, (state, action) => {
        state.loading["fetchVaultPasswords"] = false;
        state.passwords = action.payload.data;
      })
      .addCase(Security.fetchVaultPasswords.rejected, (state, action) => {
        state.loading["fetchVaultPasswords"] = false;
        state.error = action.payload;
      })
      .addCase(Security.updateVaultPasswords.pending, (state) => {
        state.loading["updateVaultPasswords"] = true;
        state.error = null;
      })
      .addCase(Security.updateVaultPasswords.fulfilled, (state, action) => {
        state.loading["updateVaultPasswords"] = false;
      })
      .addCase(Security.updateVaultPasswords.rejected, (state, action) => {
        state.loading["updateVaultPasswords"] = false;
        state.error = action.payload;
      });
  },
});

export default securitySlice.reducer;
