const getTheDifference = (base, differences) => {
  if (!differences) return null;
  let result = {};

  differences.forEach((change) => {
    let pointer = result;
    let basePointer = base;

    change.path.forEach((key, index) => {
      if (index === change.path.length - 1) {
        if (change.kind === "N" || change.kind === "E") {
          // Ensure _id is included if present in base and change.rhs is an object
          pointer[key] = change.rhs;
          if (
            typeof change.rhs === "object" &&
            basePointer[key] &&
            basePointer[key]._id
          ) {
            pointer[key]._id = basePointer[key]._id;
          }
        } else if (change.kind === "D") {
          pointer[key] = null;
        }
      } else {
        if (!pointer[key]) {
          pointer[key] = Array.isArray(basePointer[key]) ? [] : {};
        }

        // Copy _id if it exists in the base object and hasn't been copied yet
        if (
          basePointer[key] &&
          basePointer[key]._id &&
          typeof pointer[key] === "object" &&
          !pointer[key]._id
        ) {
          pointer[key]._id = basePointer[key]._id;
        }

        pointer = pointer[key];
        basePointer = basePointer[key];
      }
    });
  });

  return result;
};

export default getTheDifference;
