import React from "react";

const ShimmerHostList = () => {
  const rows = [...Array(8)];

  return (
    <div className="shimmer-host-summary p-3">
      {/* Rows Shimmer */}
      {rows.map((_, index) => (
        <div key={index}>
          <div className="d-flex justify-content-between align-items-center ">
            {/* Left Placeholder (Text) */}
            <div
              className="shimmer-text-host"
              style={{ width: "40%", height: "18px" }}
            ></div>

            {/* Right Placeholder (Number) */}
            <div
              className="shimmer-text-host"
              style={{ width: "40px", height: "18px" }}
            ></div>
          </div>
          {/* Horizontal Line */}
          {index !== rows.length - 1 && <hr className="shimmer-line" />}
        </div>
      ))}
    </div>
  );
};

export default ShimmerHostList;
