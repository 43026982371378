import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faChartLine } from "@fortawesome/free-solid-svg-icons";
import useActivityLogger from "hooks/useActivity";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend
);

const BackupSummaryChart = () => {
  const [data, setData] = useState(null);
  const { summary } = useSelector((state) => state.backup);
  const [selectedChart, setSelectedChart] = useState("line");
  const logActivity = useActivityLogger();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lastWeekData = summary?.lastWeek || [];

        const labels = lastWeekData.map((day) => day.date);
        const failures = lastWeekData.map((day) => day.failed);
        const success = lastWeekData.map((day) => day.success);
        // const missing = lastWeekData.map((day) => day.missing);

        const chartData = {
          labels: labels,
          datasets: [
            {
              label: "Failed",
              backgroundColor: "rgba(221,53,69,0.2)",
              borderColor: "rgb(221 53 69)",
              borderWidth: 2,
              fill: true,
              data: failures,
            },
            // {
            //   label: "Missing",
            //   backgroundColor: "rgba(255,192,5,0.2)",
            //   borderColor: "rgb(255 192 5)",
            //   borderWidth: 2,
            //   fill: true,
            //   data: missing,
            // },
            {
              label: "Success",
              backgroundColor: "rgba(26,139,86,0.2)",
              borderColor: "rgb(26 139 86)",
              borderWidth: 2,
              fill: true,
              data: success,
            },
          ],
        };

        setData(chartData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [summary]);

  const updateChart = (type) => {
    logActivity({
      page: "dashboard",
      activity: "chart-change",
      name: type,
    });
    setSelectedChart(type);
  };

  return (
    <div>
      <div className="mt-2 mb-3">
        <Row>
          <Col>
            <h6>
              <b>Past 30 days summary</b>
            </h6>
            <small className="sub-info">Last week failed backups summary</small>
          </Col>
          <Col className="col-auto">
            <ButtonGroup>
              <Button
                variant={selectedChart === "line" ? "primary" : " "}
                onClick={() => updateChart("line")}
              >
                <FontAwesomeIcon icon={faChartLine} />
              </Button>
              {/* <Button
                variant={selectedChart === "bar" ? "primary" : " "}
                onClick={() => updateChart("bar")}
              >
                <FontAwesomeIcon icon={faChartBar} />
              </Button> */}
            </ButtonGroup>
          </Col>
        </Row>
      </div>
      {data &&
        (selectedChart === "line" ? (
          <Line data={data} height={90} />
        ) : (
          <Bar data={data} height={120} />
        ))}
    </div>
  );
};

export default BackupSummaryChart;
