import React from 'react'

const ShimmerCategoryList = () => {
  return (
    <div>
        {/* Grid layout with four shimmer cards */}
        <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        {Array.from({ length: 4 }).map((_, index) => (
          <div
            key={index}
            className="shimmer-card"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              padding: "10px",
              backgroundColor: "#f0f0f0",
              borderRadius: "8px",
            }}
          >
            <div
              className="shimmer-text"
              style={{
                height: "20px",
                backgroundColor: "#e0e0e0",
                borderRadius: "4px",
              }}
            ></div>
            <div
              className="shimmer-text"
              style={{
                height: "20px",
                backgroundColor: "#e0e0e0",
                borderRadius: "4px",
              }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ShimmerCategoryList
