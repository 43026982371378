// slices/tenantSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchDashboard,
  fetchCatSummary,
  globalSearch,
  fetchActivityLogs,
  fetchRegions,
  fetchVersion,
  fetchFeedbacks,
  updateFeedback,
} from "../actions";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    organisations: {},
    isps: {},
    hosts: {},
    headends: {},
    gateways: {},
    orchestrators: {},
    dc: {},
    searchResults: {},
    activityLogs: [],
    catSummary: [],
    regions: [],
    version: "1.0.0",
    feedbacks: [],
    loading: {
      dashboard: false,
      activity: false,
      search: false,
      catSummary: false,
      feedbacks: false,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboard.pending, (state) => {
        state.loading["dashboard"] = true;
        state.error = null;
      })
      .addCase(fetchDashboard.fulfilled, (state, action) => {
        state.loading["dashboard"] = false;
        const {
          organisations,
          isps,
          hosts,
          headends,
          gateways,
          orchestrators,
          dc,
        } = action.payload;
        state.organisations = organisations;
        state.isps = isps;
        state.headends = headends;
        state.hosts = hosts;
        state.gateways = gateways;
        state.orchestrators = orchestrators;
        state.dc = dc;
      })
      .addCase(fetchDashboard.rejected, (state, action) => {
        state.loading["dashboard"] = false;
        state.error = action.payload;
      })
      .addCase(fetchCatSummary.pending, (state) => {
        state.loading["catSummary"] = true;
        state.error = null;
      })
      .addCase(fetchCatSummary.fulfilled, (state, action) => {
        state.loading["catSummary"] = false;
        state.catSummary = action.payload;
      })
      .addCase(fetchCatSummary.rejected, (state, action) => {
        state.loading["catSummary"] = false;
        state.error = action.payload;
      })
      .addCase(fetchRegions.pending, (state) => {
        state.loading["regions"] = true;
        state.error = null;
      })
      .addCase(fetchRegions.fulfilled, (state, action) => {
        state.loading["regions"] = false;
        state.regions = action.payload;
      })
      .addCase(fetchRegions.rejected, (state, action) => {
        state.loading["regions"] = false;
        state.error = action.payload;
      })
      .addCase(globalSearch.pending, (state) => {
        state.loading["search"] = true;
        state.error = null;
      })
      .addCase(globalSearch.fulfilled, (state, action) => {
        state.loading["search"] = false;
        state.searchResults = action.payload;
      })
      .addCase(globalSearch.rejected, (state, action) => {
        state.loading["search"] = false;
        state.error = action.payload;
      })
      .addCase(fetchActivityLogs.pending, (state) => {
        state.loading["activity"] = true;
      })
      .addCase(fetchActivityLogs.fulfilled, (state, action) => {
        state.loading["activity"] = false;
        state.activityLogs = action.payload;
      })
      .addCase(fetchActivityLogs.rejected, (state, action) => {
        state.loading["activity"] = false;
      })
      .addCase(fetchVersion.fulfilled, (state, action) => {
        state.version = action.payload;
      })
      .addCase(fetchFeedbacks.fulfilled, (state, action) => {
        state.feedbacks = action.payload;
      })
      .addCase(updateFeedback.pending, (state) => {
        state.loading["feedbackUpdate"] = true;
      })
      .addCase(updateFeedback.fulfilled, (state, action) => {
        state.loading["feedbackUpdate"] = false;

        const updatedFeedback = action.payload;
        state.feedbacks = state.feedbacks.map((feedback) =>
          feedback._id === updatedFeedback._id ? updatedFeedback : feedback
        );
      })
      .addCase(updateFeedback.rejected, (state, action) => {
        state.loading["feedbackUpdate"] = false;
        state.error = action.payload;
      });
  },
});
export const {} = dashboardSlice.actions;

export default dashboardSlice.reducer;
