import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Card, Col, Row } from "react-bootstrap";
import OrchestratorsForm from "./AddForm";
import OrchestratorsListView from "./List";
import { useBreadcrumbs } from "contexts";
import {
  fetchHeadends,
  Orchestrators as OrchestratorsApi,
} from "stores/actions";
import { usePermissions } from "hooks/usePermission";
import useActivityLogger from "hooks/useActivity";
import OnboardConcerto from "./OnboardConcerto";
import capitalize from "utils/capitalize";

const Orchestrators = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [onboardModal, setOnboardModal] = useState(false);
  const [formData, setFormData] = useState();
  const { orchestrators } = useSelector((state) => state.orchestrators);
  const { headends } = useSelector((state) => state.headend);
  const { updateBreadcrumbs } = useBreadcrumbs();
  const logActivity = useActivityLogger();

  const sendLog = (page, activity, name) => {
    logActivity({
      page,
      activity,
      name,
    });
  };

  const colors = ["green", "orange", "blue"];

  useEffect(() => {
    if (headends.length === 0) dispatch(fetchHeadends());
    updateBreadcrumbs([{ text: "Home", url: "/dashboard" }]);
    sendLog("Orchestrators", "page-load", "Orchestrators");
    dispatch(OrchestratorsApi.fetchOrchestrators());
  }, []);

  const orchestratorCount = useMemo(() => {
    const data = {};
    orchestrators.forEach((orchestrator) => {
      const category = orchestrator?.category?.name;
      if (!data[category]) data[category] = 1;
      else data[category] += 1;
    });
    return data;
  }, [orchestrators]);

  return (
    <>
      <div className="middle-section">
        <Row>
          <Col>
            <div className="my-2">
              <h5>
                <b>Orchestrators</b>
              </h5>
              <small className="sub-info">
                List of all Concerto and Titan nodes
              </small>
            </div>
          </Col>
          {usePermissions(["WRAPPERS_ADD"]) && (
            <Col className="col-auto">
              <Button
                className="text-center mx-2"
                variant="primary"
                onClick={() => setShowModal(true)}
              >
                + Create Orchestrator
              </Button>
              <Button
                className="text-center"
                variant="secondary"
                onClick={() => setOnboardModal(true)}
              >
                + Onboard Concerto
              </Button>

              <OrchestratorsForm
                formData={formData}
                setFormData={setFormData}
                showModal={showModal}
                closeModal={() => setShowModal(false)}
                sendLog={sendLog}
              />

              <OnboardConcerto
                showModal={onboardModal}
                closeModal={() => setOnboardModal(false)}
                sendLog={sendLog}
              />
            </Col>
          )}
        </Row>
        <Row className="mt-2">
          <Col sm={8}>
            <Card className="mb-4">
              <Card.Body>
                <OrchestratorsListView sendLog={sendLog} />
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Row>
              {Object.keys(orchestratorCount)?.map((key, index) => (
                <Col>
                  <Card className={`${colors[index]} bg`}>
                    <Card.Body>
                      <h1>{orchestratorCount[key] || 0}</h1>
                      {capitalize(key)}
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            {/* <Activities type="orchestrator" /> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Orchestrators;
