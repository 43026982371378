import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Row,
  Button,
  Alert,
} from "react-bootstrap";
import HeadendDetailsView from "./DetailsView";
import { useBreadcrumbs } from "contexts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchHeadendById,
  fetchMaintenanceDeviceStatus,
  fetchMonitoringAuditReport,
  Security,
  Profile,
  refreshHeadend,
  fetchHeadends,
} from "stores/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCircleCheck,
  faCircleMinus,
  faClockRotateLeft,
  faRefresh,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";
import { useWebSocket } from "contexts";
import { usePermissions } from "hooks/usePermission";
import { fetchSingleBackup } from "stores/actions";
import BackupListView from "pages/Backups/components/List";
import useActivityLogger from "hooks/useActivity";
import Loader from "components/Loader";
import MonitorDashboard from "pages/Monitoring/Components/MonitoringAuditDashboard";
import BackButton from "components/BackButton";
import SystemStats from "./Components/SystemStats";
import EditButton from "components/EditButton";
import HeadendMonitor from "./Components/HeadendMontior";

const HeadendDetails = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  const { headend, headends, loading } = useSelector((state) => state.headend);
  const { backup } = useSelector((state) => state.backup);
  const { nmap } = useSelector((state) => state.security);
  const { monitoringReport } = useSelector((state) => state.audit);

  const { websocketService, connected } = useWebSocket();
  const [vdData, setVdData] = useState({
    cpuCount: 0,
    cpuLoad: "0",
    memory: "0",
    memoryFree: "0",
    disk: "0",
    diskUsage: "",
    activatedAppliances: 0,
    maxNumberOfAppliances: 0,
  });
  const [usageLoading, setUsageLoading] = useState(true);
  const logActivity = useActivityLogger();
  const canViewBackups = usePermissions(["BACKUPS_VIEW"]);
  const canEditHeaend = usePermissions(["HEADENDS_EDIT"]);
  const canRefreshHeaend = usePermissions(["HEADENDS_SYNC"]);
  const { id } = useParams();
  const [maintenanceStatus, setMaintenanceStatus] = useState({ status: "" });
  const [selected, setSelected] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newId = useMemo(() => {
    return selected || id;
  }, [selected]);

  const sendLog = (activity, name) => {
    logActivity({
      page: "headend-details",
      activity,
      name,
    });
  };

  useEffect(() => {
    dispatch(fetchHeadendById(newId));
    dispatch(Security.fetchNmapReport(newId));
    updatemaintenanceStatus();
    dispatch(Profile.fetchProfiles("monitoring"));
  }, [newId]);

  const updatemaintenanceStatus = useCallback(async () => {
    const data = await dispatch(
      fetchMaintenanceDeviceStatus({ type: "headend", id: newId })
    );
    setMaintenanceStatus(data?.payload);
  }, [newId]);

  useEffect(() => {
    updateBreadcrumbs([
      { text: "Home", url: "/dashboard" },
      { text: "Headends", url: "/headends" },
    ]);
    if (headends.length === 0) dispatch(fetchHeadends());
  }, []);

  const redirectToManage = (id) => {
    sendLog("headend-edit-click", headend?.name);
    navigate(`/headends/manage/${id}`);
  };

  useEffect(() => {
    if (connected) {
      websocketService.registerCallback("message", (data) => {
        if (data.room === newId) {
          if (usageLoading) setUsageLoading(false);
          const message = data.message;
          setVdData({ ...message?.systemDetails, ...message?.licenseCount });
        }
      });

      websocketService.joinRoom(newId);

      return () => {
        websocketService.leaveRoom();
      };
    }
  }, [connected, newId, websocketService]);

  useEffect(() => {
    const guiCredentials = headend?.directors?.[0]?.host?.guiCredentials?.[0];
    const { username, password } = guiCredentials || {};

    if (username && password) {
      websocketService.sendMessage({
        type: "message",
        message: { username, password, fqdn: headend?.fqdn, type: "headend" },
        room: newId,
      });
    }

    dispatch(fetchSingleBackup(headend?.code || headend?.name));
    dispatch(fetchMonitoringAuditReport(headend?.code || headend?.name));
  }, [headend]);

  const triggerRefresh = () => {
    sendLog("headend-refresh-click", headend?.name);
    dispatch(refreshHeadend(headend._id));
  };

  return (
    <>
      <div className={`middle-section`}>
        {maintenanceStatus?.status === "Under Maintenance" && (
          <Row className="mb-3 maintenance">
            <Col>
              <Alert variant="info">
                Headend is Under maintenance till {maintenanceStatus?.until}
                <a
                  className="float-end"
                  style={{ textDecoration: "none" }}
                  href={`/maintenance/${maintenanceStatus?.maintenance?._id}`}
                >
                  view details <FontAwesomeIcon icon={faArrowRight} />
                </a>
              </Alert>
            </Col>
          </Row>
        )}

        <BackButton title="Headends" />

        {loading["fetchSingle"] ? (
          <Loader />
        ) : (
          <Row className="">
            <Col sm={8}>
              <Row>
                <Col>
                  {" "}
                  <div className="my-2">
                    <h5>
                      <b>Headend Details</b>
                    </h5>
                    <small className="sub-info">
                      Detailed view of the headend
                    </small>
                  </div>
                </Col>
                <Col className="col-auto">
                  <h4 className="m-0">
                    <Dropdown.ItemText>
                      <DropdownButton
                        id={`headend-lists`}
                        title={`${headend?.code}-${headend?.name}`}
                        onSelect={(value) => {
                          console.log(value);
                          setSelected(value);
                        }}
                        className="cust-dropdown"
                      >
                        {headends.map((option, index) => (
                          <Dropdown.Item
                            key={index}
                            eventKey={`${option?._id}`}
                          >
                            {option?.code}-{option?.name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </Dropdown.ItemText>
                  </h4>
                </Col>
              </Row>
              <Card className="mb-3">
                <Card.Body>
                  <Row>
                    <Col>
                      {headend && (
                        <Row>
                          <Col>
                            <small>MS-CODE</small>
                            <h6>
                              <b>{headend?.code}</b>
                            </h6>
                          </Col>
                          <Col sm={3}>
                            <small>NAME</small>
                            <h6>
                              <b>{headend?.name}</b>
                            </h6>
                          </Col>
                          <Col>
                            <small>CATEGORY</small>
                            <h6>
                              <b>{headend?.category?.name}</b>
                            </h6>
                          </Col>
                          <Col>
                            <small>INFRA</small>
                            <h6>
                              <b>{headend?.infra?.name}</b>
                            </h6>
                          </Col>
                          <Col>
                            <small>ENVIRONMENT</small>
                            <h6>
                              <b>{headend?.envType?.name}</b>
                            </h6>
                          </Col>
                          <Col>
                            <small>ACTIVE</small>
                            <h6>
                              <FontAwesomeIcon
                                icon={
                                  headend?.active
                                    ? faCircleCheck
                                    : faCircleMinus
                                }
                                className={`${
                                  headend.active ? "green" : "red"
                                } cl`}
                              />
                            </h6>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <HeadendDetailsView headend={headend} />

              <Card className="mt-3 profile">
                <Card.Body>
                  <Row>
                    <Col sm="12">
                      <Col className="mb-3">
                        <div>
                          <h5>
                            <b>Monitoring</b>
                          </h5>
                          <small className="sub-info">
                            Monitoring information
                          </small>
                        </div>
                      </Col>
                    </Col>
                    <Col md={7}>
                      <HeadendMonitor />
                    </Col>

                    <Col md={5}>
                      <div className=" mb-3">
                        <h5>
                          <b>Monitoring Audit Report</b>
                        </h5>
                        <small className="sub-info">
                          <span>Check latest reports</span>
                        </small>
                      </div>
                      <MonitorDashboard
                        monitorAudit={monitoringReport}
                        showHeadend={false}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {canViewBackups && (
                <Card className="mt-3">
                  <Card.Body>
                    <BackupListView backups={backup} />
                  </Card.Body>
                </Card>
              )}
            </Col>

            <Col sm={4}>
              <Row className="mb-3">
                {maintenanceStatus?.status !== "Under Maintenance" && (
                  <Col sm={12} className="mb-3">
                    <Row>
                      <Col></Col>
                      {canRefreshHeaend && (
                        <Col className="col-auto">
                          <Button onClick={triggerRefresh} className="add-btn">
                            {loading["refresh"] ? (
                              <Loader />
                            ) : (
                              <>
                                <FontAwesomeIcon icon={faRefresh} /> Refresh
                              </>
                            )}
                          </Button>
                        </Col>
                      )}
                      {canEditHeaend && (
                        <Col className="col-auto">
                          <EditButton
                            handleClick={() => redirectToManage(headend?._id)}
                            name="Edit"
                            size="sm"
                            variant="custom"
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                )}

                <Col sm={12}>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col sm={1} style={{ fontSize: "2rem" }}>
                          <FontAwesomeIcon icon={faShieldHalved} />
                        </Col>
                        <Col>
                          <div className=" mb-1">
                            <h5>
                              <b>Nmap Report</b>
                            </h5>
                            <small className="sub-info">
                              <FontAwesomeIcon
                                icon={faClockRotateLeft}
                                className="green cl"
                              />{" "}
                              <span>last scanned : </span>
                              <b>{nmap?.lastUpdated}</b>
                            </small>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2 mb-1">
                        <Col sm={4}>
                          <b>PORT</b>
                        </Col>
                        <Col sm={4}>
                          <b>STATE</b>
                        </Col>
                        <Col sm={4}>
                          <b>SERVICE</b>
                        </Col>
                      </Row>

                      {nmap?.reports?.length > 0 &&
                        nmap?.reports[0]?.ports?.map((data) => (
                          <Row className="mt-2">
                            <Col sm={4}>
                              <Card.Text>{data?.port}</Card.Text>
                            </Col>
                            <Col sm={4}>
                              <Card.Text>{data?.state}</Card.Text>
                            </Col>
                            <Col sm={4}>
                              <Card.Text>{data?.service}</Card.Text>
                            </Col>
                          </Row>
                        ))}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <SystemStats isLoading={usageLoading} data={vdData} />
            </Col>
            <Col sm={12}></Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default HeadendDetails;
