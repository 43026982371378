const mapping = {
  update: "Updated",
  create: "Created",
  delete: "Deleted",
  ONBOARDED: "New headend has been onboarded",
};

const colors = {
  host: "orange",
  headend: "green",
  organisation: "blue",
  isps: "red",
};

const host_tabs = [
  { name: "directors", value: "director", type: "hostDirector" },
  { name: "controllers", value: "controller", type: "hostController" },
  { name: "gateways", value: "gateway", type: "hostGateway" },
  { name: "analytics", value: "analytics", type: "hostAnalytics" },
  { name: "vos", value: "vos", type: "vos" },
  { name: "pe", value: "pe", type: "vos" },
  { name: "cfw", value: "cfw", type: "vos" },
  { name: "vms", value: "vms", type: "hostVms" },
  { name: "titan", value: "titan", type: "titanHost" },
  { name: "concerto", value: "concerto", type: "orchestratorHost" },
  { name: "idp", value: "idp", type: "hostWithRegion" },
  { name: "waf", value: "waf", type: "waf" },
  { name: "iam", value: "iam", type: "hostWithRegion" },
  { name: "switch", value: "switch", type: "switch" },
  {
    name: "orchestrator upgrade",
    value: "orchestrator-upgrade",
    type: "waf",
  },
];

const notificationType = {
  upgrade: [
    { name: "Headend", value: "headends" },
    { name: "Directors", value: "directors" },
    { name: "Controllers", value: "controllers" },
    { name: "Analytics", value: "analytics" },
    { name: "Gateways", value: "gateways" },
  ],
  general: [
    { name: "Directors", value: "directors" },
    { name: "Controllers", value: "controllers" },
    { name: "Analytics", value: "analytics" },
    { name: "Gateways", value: "gateways" },
  ],
  emergency: [
    { name: "Directors", value: "directors" },
    { name: "Controllers", value: "controllers" },
    { name: "Analytics", value: "analytics" },
    { name: "Gateways", value: "gateways" },
  ],
  alert: [
    {
      name: "Controller status critical alarm",
      value: "Controller status critical alarm",
    },
    {
      name: "Versa Analytics node status critical alarm",
      value: "Versa Analytics node status critical alarm",
    },
    {
      name: "Versa Director license status critical alarm",
      value: "Versa Director license status critical alarm",
    },
    {
      name: "Versa Director certificate status critical alarm",
      value: "Versa Director certificate status critical alarm",
    },
    {
      name: "Versa Director disk critical alarm",
      value: "Versa Director disk critical alarm",
    },
    {
      name: "Versa Director CPU critical alarm",
      value: "Versa Director CPU critical alarm",
    },
    {
      name: "Versa Director HA status critical alarm",
      value: "Versa Director HA status critical alarm",
    },
    {
      name: "Versa Director memory status critical alarm",
      value: "Versa Director memory status critical alarm",
    },
    {
      name: "Versa Director uptime status critical alarm",
      value: "Versa Director uptime status critical alarm",
    },
    {
      name: "Versa analytics node inaccessible from Versa Director critical alarm",
      value:
        "Versa analytics node inaccessible from Versa Director critical alarm",
    },
    { name: "CPE status critical alarm", value: "CPE status critical alarm" },
  ],
};

const ipOptions = {
  director: ["Management", "Control", "LAN"],
  analytics: ["Management", "Control", "Interconnect", "LAN"],
  waf: ["Management", "WAN", "LAN"],
  default: ["Management", "Control", "WAN", "LAN"],
};

export { mapping, colors, host_tabs, notificationType, ipOptions };
