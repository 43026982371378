// pages/Unauthorized.js
import DefaultPage from "components/DefaultPage";
import useActivityLogger from "hooks/useActivity";
import React, { useEffect } from "react";

const Unauthorized = () => {
  return (
    <DefaultPage
      title="404 Not found"
      description="You seems lost, please check the URL."
      page="not-found"
      activity="browse-different-url"
    />
  );
};

export default Unauthorized;
