import React from "react";

const ShimmerCategoryBox = () => {
  return (
    <div className="headends-category-shimmer mb-3">
      {/* Title Shimmer */}
      <div
        className="shimmer-text"
        style={{ width: "70%", height: "50px" }}
      ></div>

      {[...Array(4)].map((_, index) => (
        <div
          key={index}
          className="d-flex justify-content-between align-items-center"
        >
          {/* Category name shimmer */}
          <div
            className="shimmer-text d-flex justify-content-between align-items-center p-3"
            style={{ width: "100%", height: "60px" }}
          >
            <div
              className="shimmer-text-dark"
              style={{ width: "70px", height: "45px" }}
            ></div>
            <div
              className="shimmer-text-dark"
              style={{ width: "50px", height: "45px" }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ShimmerCategoryBox;
