import React, { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GATEWAY_INIT } from "constants/headendInit";
import deepClone from "utils/deepClone";
import { PaginatedTable } from "components";
import HostForm from "../../Host/AddForm";

const HeadendGatewaysForm = ({
  gateways,
  updateFormData,
  updateHostInformation,
  isEdit,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [hostToEdit, setEditToHost] = useState(null);
  const { regions } = useSelector((state) => state.dashboard);

  const columns = [
    { dataField: "name", text: "Name" },
    { dataField: "fqdn", text: "fqdn" },
    { dataField: "region", text: "Region" },
  ];

  const handleGatewayChange = (index, e) => {
    const { name, value } = e.target;
    const updatedGateways = deepClone(gateways);
    updatedGateways[index] = { ...updatedGateways[index], [name]: value };
    updateFormData(updatedGateways);
  };

  const addGateway = () => {
    updateFormData([...gateways, { ...GATEWAY_INIT, isPrimary: false }]);
  };

  const onEdit = (item) => {
    setEditToHost(item?.host);
    setShowModal(true);
  };

  const updateHost = (hostData) => {
    const updatedGateways = deepClone(gateways);
    const index = updatedGateways.findIndex(
      (gateway) => gateway?.host.name === hostData.name
    );
    if (index > -1) {
      updatedGateways[index].host = hostData;
    }
    updateFormData(updatedGateways);
  };

  const gatewaysData = useMemo(() => {
    return gateways?.map((gateway) => {
      return {
        ...gateway,
        id: gateway?.host._id,
        primary: gateway?.host?.isPrimary ? "Primary" : "Secondary",
        name: gateway?.host?.name,
        region: regions.find((region) => region._id === gateway?.host?.region)
          ?.name,
        fqdn: gateway?.host.fqdn,
      };
    });
  }, [gateways]);

  return (
    <div>
      <PaginatedTable
        data={gatewaysData}
        columns={columns}
        itemsPerPage={25}
        onEdit={onEdit}
      />

      {!isEdit && (
        <Button variant="primary" size="sm" onClick={addGateway}>
          + Gateway
        </Button>
      )}

      <HostForm
        host={hostToEdit}
        removeHost={setEditToHost}
        showModal={showModal}
        isEdit={true}
        updateHostDetails={updateHost}
        closeModal={() => {
          setEditToHost(null);
          setShowModal(false);
        }}
      />
    </div>
  );
};

export default HeadendGatewaysForm;
