import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { usePermissions } from "hooks/usePermission";
import { useNavigate } from "react-router-dom";
import ShimmerTable from "components/Shimmer/ShimmerTable";
import ShimmerMaps from "components/Shimmer/ShimmerMaps";

const DCListView = ({ sendLog }) => {
  const navigate = useNavigate();

  // Extract data and loading state
  const { dataCenters = [], loading} = useSelector((state) => state.dataCenter);
  const {loading: regionsLoading} = useSelector((state)=> state.dashboard);

  // Hooks are called unconditionally
  const dcData = useMemo(() => {
    return dataCenters.map((dc) => ({
      ...dc,
      name: dc?.data_center,
      aristaSwName: dc?.arista_sw?.name,
      gatewayNames: dc?.gateway?.map((gateway) => gateway?.name).join(",") || "",
    }));
  }, [dataCenters]);

  const hasViewPermission = usePermissions(["DC_VIEW"]);

  const onView = (item) => {
    sendLog("Data Center", "click-view-dc", item?.name);
    navigate(`/dc/${item?._id}`);
  };
  if(regionsLoading?.dashboard){
    return  <ShimmerMaps height={"40vh"} width="100%"/>
   }
 
  // Conditional rendering based on the loading state
  if (loading) {
    return (
      <>
        <ShimmerTable />
      </>
    );
  }

  return (
    <div>
      <PaginatedTable
        data={dcData}
        columns={COLUMNS["dc"]}
        itemsPerPage={25}
        onView={hasViewPermission ? onView : undefined}
        isLoading={loading}
      />
    </div>
  );
};

export default DCListView;
