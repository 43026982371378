// reducers/hostSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { Orchestrators } from "../actions";

const orchestratorSlice = createSlice({
  name: "orchestrators",
  initialState: {
    orchestrators: [],
    orchestrator: {},
    loading: {
      fetchOrchestrators: false,
      fetchOrchestrators: false,
      saveOrchestrators: false,
      onboardConcerto: false,
      refresh: false,
    },
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Orchestrators.fetchOrchestrators.pending, (state) => {
        state.loading["fetchOrchestrators"] = true;
      })
      .addCase(Orchestrators.fetchOrchestrators.fulfilled, (state, action) => {
        state.loading["fetchOrchestrators"] = false;
        state.orchestrators = action.payload;
      })
      .addCase(Orchestrators.fetchOrchestrators.rejected, (state, action) => {
        state.loading["fetchOrchestrators"] = false;
        state.error = action.payload;
      })
      .addCase(Orchestrators.fetchOrchestratorsById.pending, (state) => {
        state.loading["fetchOrchestrators"] = true;
      })
      .addCase(
        Orchestrators.fetchOrchestratorsById.fulfilled,
        (state, action) => {
          state.loading["fetchOrchestrators"] = false;
          state.orchestrator = action.payload;
        }
      )
      .addCase(
        Orchestrators.fetchOrchestratorsById.rejected,
        (state, action) => {
          state.loading["fetchOrchestrators"] = false;
          state.error = action.payload;
        }
      )
      .addCase(Orchestrators.saveOrchestrators.pending, (state) => {
        state.loading["saveOrchestrators"] = true;
      })
      .addCase(Orchestrators.saveOrchestrators.fulfilled, (state, action) => {
        state.loading["saveOrchestrators"] = false;
        state.orchestrators = [action.payload, ...state.orchestrators];
      })
      .addCase(Orchestrators.saveOrchestrators.rejected, (state, action) => {
        state.loading["saveOrchestrators"] = false;
        state.error = action.payload;
      })
      .addCase(Orchestrators.updateOrchestrators.pending, (state) => {
        state.loading["updateOrchestrators"] = true;
      })
      .addCase(Orchestrators.updateOrchestrators.fulfilled, (state, action) => {
        state.loading["updateOrchestrators"] = false;
        state.orchestrators = state.orchestrators.map((wrap) => {
          if (wrap._id === action?.payload?._id) {
            return action?.payload;
          } else return wrap;
        });
      })
      .addCase(Orchestrators.updateOrchestrators.rejected, (state, action) => {
        state.loading["updateOrchestrators"] = false;
        state.error = action.payload;
      })
      .addCase(Orchestrators.onboardConcerto.pending, (state) => {
        state.loading["onboardConcerto"] = true;
      })
      .addCase(Orchestrators.onboardConcerto.fulfilled, (state, action) => {
        state.loading["onboardConcerto"] = false;
      })
      .addCase(Orchestrators.onboardConcerto.rejected, (state, action) => {
        state.loading["onboardConcerto"] = false;
      })
      .addCase(Orchestrators.refreshConcerto.pending, (state) => {
        state.loading["refresh"] = true;
      })
      .addCase(Orchestrators.refreshConcerto.fulfilled, (state, action) => {
        state.loading["refresh"] = false;
        state.orchestrator = action.payload;
      })
      .addCase(Orchestrators.refreshConcerto.rejected, (state, action) => {
        state.loading["refresh"] = false;
      });
  },
});

export const {} = orchestratorSlice.actions;
export default orchestratorSlice.reducer;
