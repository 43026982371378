import { useEffect, useState } from "react";
import { Form, Button, Modal, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Profile } from "stores/actions";
import Cron from "react-cron-generator";

const BackupProfileAddForm = ({ show, onClose, currentProfile }) => {
  const dispatch = useDispatch();
  const initData = {
    name: "",
    type: "backup",
    status: "enabled",
    details: {
      enabled: true,
      cronsExpression: "0 0 * * *", // Default cron (Midnight daily)
      cronsExpressionText: "Every day at midnight", // Default readable cron
      retentionPeriod: "7",
    },
  };

  const [profileForm, setProfileForm] = useState(initData);

  useEffect(() => {
    if (currentProfile?._id) {
      setProfileForm(currentProfile ? currentProfile : initData);
    }
  }, [currentProfile]);

  const handleFormChange = (field, value) => {
    setProfileForm({ ...profileForm, [field]: value });
  };

  const handleFieldChange = (field, value) => {
    setProfileForm({
      ...profileForm,
      details: { ...profileForm.details, [field]: value },
    });
  };

  // Handles cron changes and stores both expression and human-readable text
  const handleCronChange = (cronValue, cronText) => {
    setProfileForm((prevForm) => ({
      ...prevForm,
      details: {
        ...prevForm.details,
        cronsExpression: cronValue,
        cronsExpressionText: cronText, // Store human-readable text
      },
    }));
  };

  const handleSave = async () => {
    if (currentProfile) {
      await dispatch(Profile.updateProfileItem(profileForm));
    } else {
      await dispatch(Profile.createProfileItem(profileForm));
    }
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose} size="lg" enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          {profileForm._id ? "Edit Profile" : "Add Backup Profile"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Name Field */}
          <Form.Group controlId="profileName" as={Row} className="mt-3">
            <Form.Label column sm={3}>
              Name
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={profileForm.name}
                onChange={(e) => handleFormChange("name", e.target.value)}
              />
            </Col>
          </Form.Group>

          {/* Cron Expression Selector */}
          <Form.Group controlId="cronsExpression" as={Row} className="mt-3">
            <Form.Label column sm={3}>
              Frequency
            </Form.Label>
            <Col sm={9}>
              <Cron
                value={profileForm.details.cronsExpression}
                onChange={(value, text) => handleCronChange(value, text)}
                showResultText={true} // Enables human-readable text
                showResultCron={true} // Shows cron expression
              />
            </Col>
          </Form.Group>

          {/* Retention Period */}
          <Form.Group controlId="retentionPeriod" as={Row} className="mt-3">
            <Form.Label column sm={3}>
              Retention Period
            </Form.Label>
            <Col sm={3}>
              <Form.Control
                type="number"
                placeholder="Enter retention period"
                value={profileForm.details.retentionPeriod}
                onChange={(e) =>
                  handleFieldChange("retentionPeriod", e.target.value)
                }
              />
            </Col>
            <Col>Days</Col>
          </Form.Group>

          {/* Enable Backup Checkbox */}
          <Form.Group controlId="backupEnabled" as={Row} className="mt-3">
            <Form.Label column sm={3}>
              Enable
            </Form.Label>
            <Col sm={9}>
              <Form.Check
                type="checkbox"
                checked={profileForm.details.enabled || false}
                onChange={(e) => handleFieldChange("enabled", e.target.checked)}
              />
            </Col>
          </Form.Group>

          {/* Encryption Required */}
          <Form.Group controlId="backupIsEncryption" as={Row} className="mt-3">
            <Form.Label column sm={3}>
              Encryption Required
            </Form.Label>
            <Col sm={9}>
              <Form.Check
                type="checkbox"
                checked={profileForm.details.isEncryption || false}
                onChange={(e) =>
                  handleFieldChange("isEncryption", e.target.checked)
                }
              />
            </Col>
          </Form.Group>

          {/* Jump Server Required */}
          <Form.Group
            controlId="backupJumpServerRequired"
            as={Row}
            className="mt-3"
          >
            <Form.Label column sm={3}>
              Jump Server Required
            </Form.Label>
            <Col sm={9}>
              <Form.Check
                type="checkbox"
                checked={profileForm.details.jumpServerRequired || false}
                onChange={(e) =>
                  handleFieldChange("jumpServerRequired", e.target.checked)
                }
              />
            </Col>
          </Form.Group>

          {/* S3 Upload */}
          <Form.Group controlId="s3Upload" as={Row} className="mt-3">
            <Form.Label column sm={3}>
              S3 Upload
            </Form.Label>
            <Col sm={9}>
              <Form.Check
                type="checkbox"
                checked={profileForm.details.s3Upload || false}
                onChange={(e) =>
                  handleFieldChange("s3Upload", e.target.checked)
                }
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BackupProfileAddForm;
