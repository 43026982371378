import API from "services/api";
import createThunk from "utils/createThunk";

// API calls
const api = {
  createProfile: (profile) => API.post("/profiles", profile),
  createProfileItem: (item) => API.post("/profiles/items", item),
  fetchProfiles: (type) => API.get(`/profiles?type=${type}`),
  fetchProfileItems: (type) => API.get(`/profiles/items?type=${type}`),
  fetchProfileById: (id) => API.get(`/profiles/${id}`),
  updateProfile: (data) => API.put(`/profiles/${data._id}`, data),
  updateProfileItem: (data) => API.put(`/profiles/items/${data._id}`, data),
  deleteProfile: (id) => API.delete(`/profiles/${id}`),
  deleteProfileItem: (id) => API.delete(`/profiles/items/${id}`),
};

// Async thunks
const createProfile = createThunk("profiles/createProfile", api.createProfile);
const createProfileItem = createThunk(
  "profiles/createProfileItem",
  api.createProfileItem
);
const fetchProfiles = createThunk("profiles/fetchProfiles", api.fetchProfiles);
const fetchProfileItems = createThunk(
  "profiles/fetchProfileItems",
  api.fetchProfileItems
);
const fetchProfileById = createThunk(
  "profiles/fetchProfileById",
  api.fetchProfileById
);
const updateProfile = createThunk("profiles/updateProfile", api.updateProfile);
const updateProfileItem = createThunk(
  "profiles/updateProfileItem",
  api.updateProfileItem
);
const deleteProfile = createThunk("profiles/deleteProfile", api.deleteProfile);
const deleteProfileItem = createThunk(
  "profiles/deleteProfileItem",
  api.deleteProfileItem
);

export {
  createProfile,
  createProfileItem,
  fetchProfiles,
  fetchProfileItems,
  fetchProfileById,
  updateProfile,
  updateProfileItem,
  deleteProfile,
  deleteProfileItem,
};
