import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";
import React, { useState, useEffect, useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  ResponsiveContainer,
  Bar,
  BarChart,
} from "recharts";
import WebsitePieChart from "./WebsitePieChart";

const GenerativeAi = () => {
  const { aiChart, aiWebsites, aiPolicyAction, loading } = useSelector(
    (state) => state.security
  );

  const chartData = useMemo(() => {
    return (
      aiChart?.map((item) => ({
        time: new Date(item._time).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        }), // Time formatted for the X-axis
        value: item._value, // Bandwidth value for the Y-axis
      })) || []
    );
  }, [aiChart]);

  const policyActionData = useMemo(() => {
    const groupedData = aiPolicyAction?.reduce((acc, item) => {
      const { policyAction, _value } = item; // Assuming `policyAction` and `_value` are returned by the query
      if (!acc[policyAction]) {
        acc[policyAction] = { name: policyAction, value: 0 }; // Recharts expects 'name' and 'value' keys
      }
      acc[policyAction].value += _value;
      return acc;
    }, {});

    // Convert grouped object to array
    return Object.values(groupedData);
  }, [aiPolicyAction]);

  const websiteData = useMemo(() => {
    const groupedData = aiWebsites?.reduce((acc, item) => {
      const { website, _value } = item; // Assuming `website` and `_value` are returned by the query
      if (!acc[website]) {
        acc[website] = { website, sessions: 0 };
      }
      acc[website].sessions += _value;
      return acc;
    }, {});

    // Convert object to array and sort by sessions in descending order
    return Object.values(groupedData).sort((a, b) => b.sessions - a.sessions);
  }, [aiWebsites]);

  return (
    <>
      <Card className="mb-3 pb-5">
        <Card.Body>
          <div style={{ width: "100%", height: "400px" }}>
            <div className="my-2">
              <h5>
                <b>Total Bandwidth Usage </b>
              </h5>
              <small className="sub-info">
                Total Bandwidth used by Generative AI Tools
              </small>
            </div>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={chartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="value"
                  fill="rgba(136, 132, 216, 0.2)" // Light background fill for the area
                  strokeWidth={0}
                />
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#8884d8" // Line color
                  dot={false} // Remove dots for smoother lines
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Card.Body>
      </Card>

      <Row>
        <Col>
          <Card className="h-100">
            <Card.Body>
              <div style={{ width: "100%", height: "400px" }}>
                <div className="my-2">
                  <h5>
                    <b>Policy actions over time </b>
                  </h5>
                  <small className="sub-info">
                    Total Bandwidth used by Generative AI Tools
                  </small>
                </div>
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    data={policyActionData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="value" fill="#8884d8" name="Sessions" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <div className="my-2">
                <h5>
                  <b>AI tools by session</b>
                </h5>
                <small className="sub-info">
                  Top Generative AI tools by session
                </small>
              </div>
              <PaginatedTable
                data={websiteData}
                columns={COLUMNS["websites"]}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* </Card.Body>
        </Card> */}
    </>
  );
};

export default GenerativeAi;
