// pages/Unauthorized.js
import DefaultPage from "components/DefaultPage";
import React from "react";

const Unauthorized = () => {
  return (
    <DefaultPage
      title="401 Unauthorized"
      description="You do not have permission to view this page."
      page="unauthorised-access"
      activity="access-unathorised-page"
    />
  );
};

export default Unauthorized;
