import React, { useMemo, useState } from "react";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import SingleView from "pages/Host/SingleHostView";

const DeviceTableView = ({ data, type, columnsKey, title }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // Process Data
  const tableData = useMemo(() => {
    return data?.map((item) => ({
      ...item?.host,
      id: item?.host?._id,
      name: item?.host?.name,
      type: item?.host?.category?.name || item?.host?.subtype,
      region: item?.host?.region?.name || item?.host?.region,
      ip: item?.host?.ipAddress,
      bundleVersion: item?.host?.appIdDetails?.appIdInstalledBundleVersion,
      tenants:
        item?.host?.orgs?.length > 0
          ? item?.host.orgs.join(" , ")
          : item?.host?.ownerOrgName,
    }));
  }, [data]);

  const onView = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  return (
    <>
      {title?.length > 0 ||
        (type?.length > 0 && (
          <div className="my-3">
            <h5>
              <b>{title || type?.toUpperCase()}</b>
            </h5>
            <small className="sub-info">List of all {title || type}</small>
          </div>
        ))}
      {tableData.length > 0 ? (
        <PaginatedTable
          data={tableData}
          columns={COLUMNS[columnsKey]}
          itemsPerPage={50}
          onView={onView}
          paginationRequired={false}
        />
      ) : (
        <p>No {title || type} available.</p>
      )}

      {showModal && selectedItem && (
        <SingleView
          host={selectedItem}
          showModal={showModal}
          closeModal={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default DeviceTableView;
