import BackButton from "components/BackButton";
import { usePermissions } from "hooks/usePermission";
import { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, ListGroup, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Host } from "stores/actions";
import capitalize from "utils/capitalize";
import HostForm from "../AddForm";
import PasswordManager from "components/PasswordManager";
import useActivityLogger from "hooks/useActivity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import EditButton from "components/EditButton";

const HostDetails = () => {
  const { host } = useSelector((state) => state.host);
  const { id } = useParams();
  const dispatch = useDispatch();
  const hasEditPermission = usePermissions(["HOSTS_EDIT"]);
  const hasManagePasswordPermission = usePermissions(["HOSTS_PASSWORD"]);
  const [showModal, setShowModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const logActivity = useActivityLogger();

  useEffect(() => {
    dispatch(Host.fetchSingleHost(id));
  }, [id]);

  const sendLog = (page, event, name) => {
    logActivity(page, event, name);
  };

  const isDirector = useMemo(() => {
    const categoryName = host?.category?.name || host?.category;
    return categoryName?.toLowerCase() === "director";
  }, [host]);

  const onEdit = () => {
    sendLog("host", "click-host-edit", host?.name);
    setShowModal(true);
  };

  const onViewPassword = () => {
    sendLog("host", "click-host-password-view", host?.name);
    setShowPasswordModal(true);
  };

  return (
    <div className="middle-section">
      <BackButton title="Hosts" />
      <Row className="my-2">
        <Col>
          <div>
            <h5>
              <b>Host Details</b>
            </h5>
            <small className="sub-info">Detailed view of the Host</small>
          </div>
        </Col>
        <Col className="col-auto">
          <Row>
            {hasManagePasswordPermission && (
              <Col className="col-auto">
                <Button onClick={onViewPassword} className="add-btn">
                  <FontAwesomeIcon icon={faKey} /> Password
                </Button>
              </Col>
            )}
            {hasEditPermission && (
              <Col className="col-auto">
                <EditButton
                  handleClick={onEdit}
                  name="Edit"
                  size="sm"
                  variant="custom"
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6} className="mb-3">
          <Card className="h-100">
            <Row>
              <Col md={4}>
                <Card.Body>
                  <div>
                    <h5>
                      <b>Basic Details</b>
                    </h5>
                    <small className="sub-info">
                      Basic details view of the Host
                    </small>
                  </div>
                </Card.Body>
              </Col>
              <Col md={12}>
                <ListGroup variant="flush">
                  {[
                    { label: "Name", value: host?.name },
                    { label: "Host Name", value: host?.hostName },
                    { label: "Headend", value: host?.headendName },
                    { label: "Cluster", value: host?.analyticName },
                    {
                      label: "Category",
                      value: host?.category?.name
                        ? capitalize(host?.category?.name)
                        : host?.category,
                    },
                    {
                      label: "Device Type",
                      value: host?.subtype || host?.type,
                    },
                    { label: "FQDN", value: host?.fqdn },
                    { label: "IP", value: host?.ipAddress },
                    { label: "Owner Org", value: host?.ownerOrgName },
                    { label: "Location", value: host?.location },
                    {
                      label: "Region",
                      value: host?.region?.name || host?.region,
                    },
                  ]
                    .filter((item) => item.value)
                    .map((item, index) => (
                      <ListGroup.Item key={index}>
                        <span>{item.label}</span>
                        <b>{item.value}</b>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={6} className="mb-3">
          <Card className="h-100">
            <Card.Body>
              <div className="mb-3">
                <h5>
                  <b>IPs</b>
                </h5>
                <small className="sub-info">
                  All IPs related to device will be displayed
                </small>
              </div>

              <Table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Private Ip</th>
                    <th>Public Ip</th>
                    <th>Port</th>
                  </tr>
                </thead>
                <tbody>
                  {host?.ips?.map((ip, ipIndex) => (
                    <tr className="details mb-2" key={`cred-${ipIndex}`}>
                      {Object.entries(ip).map(
                        ([key, value], index) =>
                          key !== "_id" && (
                            <td key={`${ipIndex}-${index}`}>{value}</td>
                          )
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} sm={12}>
          <Card className="h-100">
            <Row>
              <Col>
                <Card.Body>
                  <div>
                    <h5>
                      <b>License Details</b>
                    </h5>
                    <small className="sub-info">
                      Information about the solution tiers added to the device
                    </small>
                  </div>
                </Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <span>Solution Tier</span>

                    <b>{host?.license?.solutionTier}</b>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span>Bandwidth</span>

                    <b>{host?.license?.bandwidth}</b>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span>Start Date</span>

                    <b>{host?.license?.startDate}</b>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {" "}
                    <span>End Date</span>
                    <b>{host?.license?.endDate}</b>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col md={isDirector ? 4 : 8} sm={12} lg={isDirector ? 4 : 8}>
          <Card className="h-100">
            <Row>
              <Col>
                <Card.Body>
                  <div>
                    <h5>
                      <b>Other Details</b>
                    </h5>
                    <small className="sub-info">
                      Information about the device's softwares and hardwares
                    </small>
                  </div>
                </Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <span>CPU </span>
                    <b>{host?.hardware?.cpu || host?.cpu}</b>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span> No of CPUs </span>
                    <b>{host?.hardware?.cpuCount || host?.no_of_cpu}</b>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span>Memory </span>
                    <b>{host?.hardware?.memory || host?.memory}</b>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span>Hard Disk </span>
                    <b>{host?.hardware?.disk || host?.disk}</b>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              {!isDirector && (
                <Col>
                  <ListGroup variant="flush">
                    <>
                      <ListGroup.Item>
                        <span>Software Version </span>
                        <b>{host?.softwareVersion}</b>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <span>OSS Pack Version </span>
                        <b>{host?.osspackVersion}</b>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <span>SPack Version </span>
                        <b>{host?.spackVersion}</b>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <span>Serial No </span>
                        <b>{host?.hardware?.serialNo}</b>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <span>Model </span>
                        <b>{host?.hardware?.model}</b>
                      </ListGroup.Item>
                    </>
                  </ListGroup>
                </Col>
              )}
            </Row>
          </Card>
        </Col>
        {isDirector && (
          <Col>
            <Card>
              <Card.Body>
                <div>
                  <h5>
                    <b>Package Details</b>
                  </h5>
                  <small className="sub-info">
                    Information about the device's versions and packages
                  </small>
                </div>
              </Card.Body>
              <ListGroup variant="flush">
                {Object.entries(host?.packageInfo).map(([key, value]) => (
                  <ListGroup.Item key={key}>
                    <span>{capitalize(key)}</span>
                    <b>{value}</b>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
          </Col>
        )}
      </Row>

      {showModal && hasEditPermission && (
        <HostForm
          host={host}
          showModal={showModal}
          closeModal={() => {
            setShowModal(false);
          }}
        />
      )}
      {hasManagePasswordPermission && (
        <PasswordManager
          showModal={showPasswordModal}
          closeModal={() => setShowPasswordModal(false)}
          host={host}
        />
      )}

      {/* {orgMapping?.length > 0 && (
        <Card>
          <Card.Body>
            <Card.Title>
              <b>Tenants</b>
            </Card.Title>
            <Card.Text>Lists all organisations</Card.Text>
            <PaginatedTable
              data={orgMapping}
              columns={COLUMNS["orgMapping"]}
              itemsPerPage={10}
            />
          </Card.Body>
        </Card>
      )} */}
    </div>
  );
};

export default HostDetails;
