import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "services/api";

const fetchMaintenance = createAsyncThunk(
  "maintenances/fetchMaintenance",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/maintenance`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchMaintenanceDetails = createAsyncThunk(
  "maintenances/fetchMaintenanceDetails",
  async (id, { rejectWithValue }) => {
    try {
      const response = await API.get(`/maintenance/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchMaintenanceDeviceStatus = createAsyncThunk(
  "maintenances/fetchMaintenanceDeviceStatus",
  async (query, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `/maintenance/status/${query?.type}/${query?.id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchMaintenanceSummary = createAsyncThunk(
  "maintenances/fetchMaintenanceSummary",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/summary`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const saveMaintenance = createAsyncThunk(
  "maintenances/saveMaintenance",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/maintenance`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const updateMaintenance = createAsyncThunk(
  "maintenances/updateMaintenance",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.put(`/maintenance/${data?.data?._id}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const deleteMaintenance = createAsyncThunk(
  "maintenances/deleteMaintenance",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await API.get(`/maintenance/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export {
  fetchMaintenance,
  fetchMaintenanceDetails,
  fetchMaintenanceDeviceStatus,
  fetchMaintenanceSummary,
  saveMaintenance,
  deleteMaintenance,
  updateMaintenance,
};
