// reducers/hostSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { Host } from "../actions";

const hostSlice = createSlice({
  name: "hosts",
  initialState: {
    hosts: [],
    host: {},
    summary: {},
    loading: {
      fetchHosts: true,
      fetchSummary: false,
    },
    error: null,
  },
  reducers: {
    setHosts: (state, action) => {
      state.hosts = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(Host.fetchHosts.pending, (state) => {
        state.loading["fetchHosts"] = true;
        state.error = null;
        state.hosts = [];
      })
      .addCase(Host.fetchHosts.fulfilled, (state, action) => {
        state.loading["fetchHosts"] = false;
        state.hosts = action.payload;
      })
      .addCase(Host.fetchHosts.rejected, (state, action) => {
        state.loading["fetchHosts"] = false;
        state.error = action.payload;
      })
      .addCase(Host.fetchSingleHost.pending, (state) => {
        state.loading["fetchSingleHost"] = true;
        state.error = null;
        state.host = {};
      })
      .addCase(Host.fetchSingleHost.fulfilled, (state, action) => {
        state.loading["fetchSingleHost"] = false;
        state.host = action.payload;
      })
      .addCase(Host.fetchSingleHost.rejected, (state, action) => {
        state.loading["fetchSingleHost"] = false;
        state.error = action.payload;
      })
      .addCase(Host.fetchHostSummary.pending, (state) => {
        state.loading["fetchSummary"] = true;
        state.error = null;
      })
      .addCase(Host.fetchHostSummary.fulfilled, (state, action) => {
        state.loading["fetchSummary"] = false;
        state.summary = action.payload;
      })
      .addCase(Host.fetchHostSummary.rejected, (state, action) => {
        state.loading["fetchSummary"] = false;
        state.error = action.payload;
      });
  },
});

export const { setHosts, setLoading, setError } = hostSlice.actions;
export default hostSlice.reducer;
