// reducers/securitySlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchBackupAuditReport,
  fetchMonitoringAuditReport,
  fetchMonitoringAuditSummary,
} from "../actions";

const auditSlice = createSlice({
  name: "audit",
  initialState: {
    backupReport: [],
    monitoringReport: [],
    monitoringSummary: {
      critical: 0,
      // mismatch: 0,
      enabled: 0,
    },
    backupAuditSummary: {
      critical: 0,
      // mismatch: 0,
      enabled: 0,
    },
    loading: {
      backupReport: false,
      monitoringReport: false,
    },
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBackupAuditReport.pending, (state) => {
        state.loading["backupReport"] = true;
        state.error = null;
      })
      .addCase(fetchBackupAuditReport.fulfilled, (state, action) => {
        state.loading["backupReport"] = false;
        state.backupReport = action.payload;
      })
      .addCase(fetchBackupAuditReport.rejected, (state, action) => {
        state.loading["backupReport"] = false;
        state.error = action.payload;
      })
      .addCase(fetchMonitoringAuditReport.pending, (state) => {
        state.loading["monitoringReport"] = true;
        state.error = null;
      })
      .addCase(fetchMonitoringAuditReport.fulfilled, (state, action) => {
        state.loading["monitoringReport"] = false;
        state.monitoringReport = action.payload?.reports;
      })
      .addCase(fetchMonitoringAuditReport.rejected, (state, action) => {
        state.loading["monitoringReport"] = false;
        state.error = action.payload;
      })
      .addCase(fetchMonitoringAuditSummary.pending, (state) => {
        state.loading["monitoringSummary"] = true;
        state.error = null;
      })
      .addCase(fetchMonitoringAuditSummary.fulfilled, (state, action) => {
        state.loading["monitoringSummary"] = false;
        state.monitoringSummary = action.payload;
      })
      .addCase(fetchMonitoringAuditSummary.rejected, (state, action) => {
        state.loading["monitoringSummary"] = false;
        state.error = action.payload;
      });
  },
});

export default auditSlice.reducer;
