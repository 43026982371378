const common = {
  name: { dataField: "name", text: "Name" },
  headend: { dataField: "headend", text: "Headend" },
  fqdn: { dataField: "fqdn", text: "FQDN", link: true },
  spack: { dataField: "spackVersion", text: "Spack Version" },
  ossPack: { dataField: "osspackVersion", text: "OSSpack Version" },
  version: { dataField: "softwareVersion", text: "Version" },
  tenants: { dataField: "tenants", text: "Tenants" },
  tenant: { dataField: "tenant", text: "Tenant" },
  owner: { dataField: "owner", text: "Tenant" },
  type: { dataField: "type", text: "Type" },
  bundle: {
    dataField: "bundleVersion",
    text: "Installed Bundle ID",
  },
  region: { dataField: "region", text: "Region" },
  category: { dataField: "category", text: "Category" },
  infra: { dataField: "infra", text: "Infra" },
  env: { dataField: "envType", text: "Environment" },
  ip: { dataField: "ipAddress", text: "IP" },
  siteName: { dataField: "siteName", text: "DC Site Name" },
  ownerOrg: { dataField: "ownerOrgName", text: "Tenant" },
  globalOrg: { dataField: "globalOrgId", text: "Global Org ID" },
  headendName: { dataField: "headendName", text: "Headend" },
  role: { dataField: "role", text: "Role" },
  twoFactor: { dataField: "twoFactor", text: "Two Factor" },
  userType: { dataField: "userType", text: "User Type" },
  email: { dataField: "email", text: "Email" },
  orchestrator: { dataField: "orchestrator", text: "Orchestrator" },
  status: { dataField: "status", text: "Status" },
};

const hostCommon = [
  common.spack,
  common.ossPack,
  common.version,
  common.region,
];
const ossPackNVersion = [common.ossPack, common.version];
const nameWithFqdn = [common.name, common.fqdn];
const nameWithHeadend = [common.name, common.headend];
const gateway = [common.tenants, common.type, ...hostCommon, common.bundle];
const withRegion = [...nameWithFqdn, common.region];

const COLUMNS = {
  headend: [...nameWithFqdn, common.category, common.infra, common.env],
  host: [...nameWithHeadend, common.owner, ...hostCommon],
  tenantHost: [...nameWithHeadend, , ...hostCommon],
  idp: [...nameWithFqdn],
  waf: [...nameWithHeadend, common.region],
  hostDirector: [...nameWithHeadend, ...ossPackNVersion, common.region],
  hostController: [...nameWithHeadend, , ...hostCommon, common.bundle],
  hostAnalytics: [
    ...nameWithHeadend,
    common.category,
    common.ossPack,
    common.version,
    common.region,
  ],
  hostVms: [
    ...nameWithHeadend,
    common.owner,
    ...ossPackNVersion,
    common.region,
  ],
  hostGateway: [...nameWithHeadend, , ...gateway],
  vos: [...nameWithHeadend, common.category, common.owner, ...hostCommon],
  dcGateway: [
    common.name, // FIX : Change the logic to accept nameWithHeadend
    common.headendName,
    common.ownerOrg,
    common.ip,
    common.spack,
    common.ossPack,
  ],
  orgs: [
    common.name,
    { dataField: "headends", text: "Headends" },
    common.globalOrg,
    { dataField: "contacts", text: "Contacts" },
  ],
  tenants: [
    common.name,
    { dataField: "parentOrg", text: "Parent Tenant" },
    common.globalOrg,
    common.headend,
  ],
  director: [
    ...nameWithFqdn,
    { dataField: "primary", text: "Pri/Sec" },
    common.version,
    common.region,
  ],
  controller: [
    common.name,
    { dataField: "ip", text: "IP" },
    common.version,
    common.bundle,
    common.region,
  ],
  orgMapping: [
    common.name,
    { dataField: "localId", text: "Local ID" },
    { dataField: "localKey", text: "Local Key" },
    { dataField: "remoteId", text: "Remote ID" },
    { dataField: "remoteKey", text: "Remote Key" },
  ],
  appliance: [
    common.name,
    common.type,
    { dataField: "ownerOrg", text: "Tenant" },
    common.version,
    common.bundle,
  ],
  vms: [common.name, common.type],
  hostWithRegion: [...withRegion],
  switch: [
    common.name,
    { dataField: "subtype", text: "Sub Category" },
    { dataField: "siteName", text: "DC Site Name" },
    common.region,
  ],
  gateway: [common.name, ...gateway],
  analytics: [
    common.name,
    common.region,
    { dataField: "subtype", text: "Sub Category" },
    { dataField: "cluster", text: "Cluster" },
  ],
  wafs: [common.name, common.region],
  isp: [
    common.name,
    { dataField: "dcName", text: "Data Center Code" },
    { dataField: "aristaSwName", text: "Arista SW Name" },
    { dataField: "aristaSwIp", text: "Arista SW Ip" },
  ],
  dcIsp: [
    { dataField: "isp", text: "ISP" },
    { dataField: "isp_side_ip", text: "ISP side IP" },
    { dataField: "versa_side_ip", text: "Versa side IP" },
    { dataField: "local_as", text: "Local As" },
    { dataField: "remote_as", text: "Remote As" },
    { dataField: "circuit_id", text: "Circuit ID" },
    { dataField: "complaint_url", text: "Contacts" },
  ],
  dc: [
    common.name,
    { dataField: "site", text: "Site Name" },
    { dataField: "aristaSwName", text: "Arista SW Name" },
    { dataField: "gatewayNames", text: "Gateways" },
  ],
  backupStatus: [{ dataField: "name", text: "Server" }],
  monitoring: [{ dataField: "name", text: "Headend" }],
  monitoringAudit: [
    common.name,
    // { dataField: "frequency", text: "Frequency" },
    // { dataField: "script", text: "Script" },
    { dataField: "status", text: "In Sync" },
    // { dataField: "parameters_monitored", text: "Monitored Params" },
    // { dataField: "audit_time", text: "Audit Time" },
  ],
  backupStatusDetail: [
    { dataField: "backupName", text: "File Path" },
    { dataField: "lastBackupTime", text: "Backup Time" },
    // { dataField: "type", text: "Device" },
    // { dataField: "backupType", text: "Type" },
    common.status,
    { dataField: "message", text: "Message" },
  ],
  backupSummary: [
    { dataField: "serverName", text: "Server Name" },
    { dataField: "backupName", text: "File Path" },
    { dataField: "lastBackupTime", text: "Backup Time" },
    { dataField: "type", text: "Device" },
    common.status,
    { dataField: "message", text: "Message" },
  ],
  category: [{ dataField: "name", text: "Server" }],
  orchestrators: [
    ...nameWithFqdn,
    { dataField: "headends", text: "Headends" },
    common.category,
  ],
  orchestratorHost: [...nameWithFqdn, common.orchestrator, common.region],
  titanHost: [common.name, common.orchestrator, common.region],
  roles: [common.name],
  user: [common.name, common.email],
  headendUsers: [
    common.name,
    common.role,
    common.tenant,
    common.twoFactor,
    common.userType,
  ],
  searchUsers: [
    common.name,
    common.headendName,
    common.tenant,
    common.role,
    common.twoFactor,
    common.userType,
  ],
  orchUsers: [
    { dataField: "username", text: "Username" },
    common.name,
    common.email,
    common.tenant,
    common.twoFactor,
  ],
  activities: [
    common.name,
    { dataField: "action", text: "Action" },
    { dataField: "createdAt", text: "Created On" },
    { dataField: "updatedAt", text: "Updated On" },
  ],
  userActions: [
    { dataField: "user", text: "User" },
    { dataField: "activity", text: "Acitivty" },
    { dataField: "count", text: "Count" },
  ],
  backupAudit: [
    { dataField: "device_name", text: "Device Name" },

    { dataField: "audit_time", text: "Audit Time" },
    { dataField: "status", text: "Backup Status" },
    // { dataField: "comment", text: "Comment" },
    { dataField: "files", text: "Comments" },
  ],
  subscriptions: [
    common.name,
    { dataField: "parent", text: "Parent " },
    { dataField: "license", text: "Subscriptions" },
  ],
  feedbacks: [
    common.name,
    { dataField: "page", text: "Page " },
    { dataField: "feedback", text: "Feedback Message" },
    { dataField: "adminCommentsFormatted", text: "Admin Comments" },
    { dataField: "markAsCompletedCheckbox", text: "Completed" },
    { dataField: "createdAt", text: "Created On" },
  ],
  websites: [
    { dataField: "website", text: "Website " },
    { dataField: "sessions", text: "Session" },
  ],
  maintenance: [
    ...nameWithHeadend,
    { dataField: "notification", text: "Notification" },
    { dataField: "notificationType", text: "Notification Type " },
    { dataField: "from", text: "From " },
    { dataField: "to", text: "To " },
    { dataField: "currentStatus", text: "Current Status" },
  ],
  backupProfiles: [
    common.name,
    common.status,
    { dataField: "cronsExpressionText", text: "Frequency" },
    { dataField: "retentionPeriod", text: "Retention Period(Days) " },
    { dataField: "isEncryption", text: "Encryption Enabled " },
    { dataField: "jumpServerRequired", text: "Jump Server Enabled" },
    { dataField: "s3Upload", text: "S3 Upload" },
  ],
  grootMonitoring: [
    { dataField: "key", text: "Name" },
    { dataField: "enabled", text: "Status" },
  ],
  ip: [
    { dataField: "type", text: "Type" },
    { dataField: "private_ip", text: "Private IP" },
    { dataField: "public_ip", text: "Public IP" },
    { dataField: "port", text: "Port" },
  ],
};

export default COLUMNS;
