import { PaginatedTable } from "components";
import Highlight from "components/HighlightText";
import COLUMNS from "constants/columns";
import HostSingleView from "pages/Host/SingleHostView";
import { useMemo, useState } from "react";
const {
  Accordion,
  Badge,
  ListGroup,
  Card,
  Col,
  Row,
} = require("react-bootstrap");

const HostResult = ({ hosts, sendLog, query }) => {
  const [selectedHost, setSelectedHost] = useState(null);
  const [showHostModal, setShowHostmodal] = useState(false);

  const selectHost = (host) => {
    sendLog("host-click", host?.name);
    setSelectedHost(host);
    setShowHostmodal(true);
  };

  const hostData = useMemo(() => {
    return hosts?.map((host) => ({
      ...host,
      id: host._id,
      name: (
        <div className="link" onClick={() => selectHost(host)}>
          <Highlight text={host?.name} highlight={query} />
        </div>
      ),
      headend: (
        <Highlight
          text={`${host?.headendCode ?? ""} ${host?.headendName ?? ""}`}
          highlight={query}
        />
      ),
      category: (
        <Highlight
          text={host?.subtype || host?.category?.name}
          highlight={query}
        />
      ),
      spackVersion: host?.spackVersion,
      owner: <Highlight text={host?.ownerOrgName} highlight={query} />,
      osspackVersion: host?.osspackVersion,
    }));
  }, [hosts]);

  return (
    <Accordion.Item eventKey="hosts">
      <Accordion.Header>
        Hosts :
        <Badge pill bg="info">
          {hosts?.length}
        </Badge>
      </Accordion.Header>
      <Accordion.Body>
        <PaginatedTable
          data={hostData || []}
          columns={COLUMNS["host"]}
          perPage={20}
        />
      </Accordion.Body>
      <HostSingleView
        host={selectedHost}
        removeHost={setSelectedHost}
        showModal={showHostModal}
        closeModal={() => setShowHostmodal(false)}
      />
    </Accordion.Item>
  );
};

export default HostResult;
