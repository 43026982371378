import React, { useEffect, useMemo, useState, useCallback } from "react";
import HostList from "./List";
import HostForm from "./AddForm";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap";
import { useBreadcrumbs } from "contexts";
import { useDispatch, useSelector } from "react-redux";
import { fetchHeadends } from "stores/actions";
import { HOST_INIT } from "constants/headendInit";
import { usePermissions } from "hooks/usePermission";
import useActivityLogger from "hooks/useActivity";

const Host = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  const { hosts } = useSelector((state) => state.host);
  const { headends } = useSelector((state) => state.headend);
  const [showModal, setShowModal] = useState(false);
  const [newHost, setNewHost] = useState(HOST_INIT);
  const [selectedHeadend, setSelectedHeadend] = useState();
  const [isInitialLoad, setIsInitialLoad] = useState(false);
  const logActivity = useActivityLogger();
  const dispatch = useDispatch();
  const canAddHost = usePermissions(["HOSTS_ADD"]);

  // Memoize the sendLog function to prevent it from being recreated on every render
  const sendLog = useCallback(
    (page, activity, name) => {
      logActivity({
        page,
        activity,
        name,
      });
    },
    [logActivity]
  );

  useEffect(() => {
    if (headends.length > 0) {
      setSelectedHeadend(`${headends[0].code}-${headends[0].name}`);
    }
  }, [headends]);

  useEffect(() => {
    if (!isInitialLoad && hosts.length === 0) {
      // dispatch(fetchHosts());
      setIsInitialLoad(true);
    }
  }, [dispatch, isInitialLoad, hosts]);

  // Disable exhaustive-deps to ensure this effect only runs on mount
  useEffect(() => {
    sendLog("host", "page-load", "Host");
    updateBreadcrumbs([{ text: "Home", url: "/dashboard" }]);
    if (headends.length === 0 && canAddHost) dispatch(fetchHeadends());
  }, []);

  const handleAddNew = () => {
    setNewHost(HOST_INIT);
    setShowModal(true);
  };

  const headendDetails = useMemo(() => {
    return headends.find(
      (head) => `${head.code}-${head.name}` === selectedHeadend
    );
  }, [selectedHeadend, headends]);

  const totalAppliances = useMemo(() => {
    return hosts.reduce((acc, host) => acc + host?.hosts?.length, 0);
  }, [hosts]);

  return (
    <>
      <div className="middle-section">
        <Row>
          <Col sm={12}>
            <Row className="my-2">
              <Col>
                <div>
                  <h5>
                    <b>Hosts</b>
                  </h5>
                  <small className="sub-info">List of all Appliances</small>
                </div>
              </Col>

              {canAddHost && (
                <>
                  <Col className="col-auto">
                    <Dropdown.ItemText>
                      <DropdownButton
                        id={`headend-lists`}
                        title={`${selectedHeadend}`}
                        className="filter-btn cust-dropdown"
                        onSelect={(value) => {
                          setSelectedHeadend(value);
                        }}
                      >
                        <Dropdown.Item eventKey={"All"}>All</Dropdown.Item>
                        {headends.map((option, index) => (
                          <Dropdown.Item
                            key={`${option.code}-${option?.name}-${index}`}
                            eventKey={`${option.code}-${option?.name}`}
                          >
                            {option.code}-{option?.name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </Dropdown.ItemText>
                  </Col>
                  <Col className="col-auto">
                    <Button
                      className="text-center"
                      variant="secondary"
                      onClick={handleAddNew}
                    >
                      + New Host
                    </Button>
                    <HostForm
                      host={newHost}
                      removeHost={setNewHost}
                      showModal={showModal}
                      closeModal={() => setShowModal(false)}
                      headend={headendDetails}
                    />
                  </Col>
                </>
              )}
            </Row>

            <Card className="my-2">
              <Card.Body>
                <HostList sendLog={sendLog} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(Host);
