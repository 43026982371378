import React from "react";
import {
  faArrowsToCircle,
  faBuilding,
  faGamepad,
  faNetworkWired,
  faServer,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import DashboardCard from "components/DashboardCard";

const Summary = ({ navigateToPage }) => {
  const {
    organisations,
    dc,
    hosts,
    headends,
    gateways,
    orchestrators,
    loading,
  } = useSelector((state) => state.dashboard);

  const cardData = [
    {
      title: "Data Center",
      total: dc?.total,
      loading: loading["dashboard"],
      defaultIcon: faNetworkWired,
      hoverIcon: faArrowRight,
      bgColorClass: "green",
      onClick: () => navigateToPage("/dc", "DC"),
    },
    {
      title: "Orchestrators",
      total: orchestrators?.total,
      loading: loading["dashboard"],
      defaultIcon: faNetworkWired,
      hoverIcon: faArrowRight,
      bgColorClass: "orange",
      onClick: () => navigateToPage("/orchestrators", "Orchestrators"),
    },

    {
      title: "Headends",
      total: headends?.total,
      loading: loading["dashboard"],
      defaultIcon: faGamepad,
      hoverIcon: faArrowRight,
      bgColorClass: "red",
      onClick: () => navigateToPage("/headends", "Headends"),
    },
    {
      title: "Gateways",
      total: gateways?.total,
      loading: loading["dashboard"],
      defaultIcon: faArrowsToCircle,
      hoverIcon: faArrowRight,
      bgColorClass: "violet",
      onClick: () => navigateToPage("/gateways", "Gateways"),
    },
    {
      title: "Hosts",
      total: hosts?.total,
      loading: loading["dashboard"],
      defaultIcon: faServer,
      hoverIcon: faArrowRight,
      bgColorClass: "violet1",
      onClick: () => navigateToPage("/host", "Hosts"),
    },
    {
      title: "Tenants",
      total: organisations?.total,
      loading: loading["dashboard"],
      defaultIcon: faBuilding,
      hoverIcon: faArrowRight,
      bgColorClass: "blue",
      onClick: () => navigateToPage("/tenants", "Tenants"),
    },
  ];

  return (
    <Row>
      {cardData.map((card, index) => (
        <DashboardCard key={index} {...card} />
      ))}
    </Row>
  );
};

export default Summary;
