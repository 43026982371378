import {
  faCheckCircle,
  faClone,
  faEdit,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";
import React from "react";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";

const MonitoringProfileItemView = ({ profile, onEdit, onClone }) => {
  if (!profile) {
    return;
  }

  return (
    <div className=" mt-4">
      {profile && (
        <>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col>
                  <div>
                    <h5>
                      <b>{profile?.name}</b>
                    </h5>
                  </div>
                </Col>
                <Col>
                  <div>
                    Frequency :<b>{profile?.frequency}</b>
                  </div>
                </Col>
                <Col md={2}>
                  <div>
                    Status :
                    {profile?.status === "enabled" ? (
                      <FontAwesomeIcon
                        className="cl green"
                        icon={faCheckCircle}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="cl red"
                        icon={faTimesCircle}
                      />
                    )}
                  </div>
                </Col>
                <Col className="col-auto">
                  {onEdit && (
                    <Button
                      variant="warning"
                      className="mx-2"
                      onClick={() => onEdit(profile)}
                    >
                      <FontAwesomeIcon icon={faEdit} className="mx-2" /> Edit
                    </Button>
                  )}
                  {onClone && (
                    <Button variant="primary" onClick={() => onClone(profile)}>
                      <FontAwesomeIcon icon={faClone} className="mx-2" />
                      Clone
                    </Button>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {profile?.triggers?.length > 0 && (
            <>
              <Row>
                <Col md={12} className="mb-3">
                  <div>
                    <h5 className="my-3">
                      <b>Trigger</b>
                    </h5>
                    <small className="sub-info">
                      Detailed view of <b>{profile.name}</b>
                    </small>
                  </div>
                </Col>
                {profile?.triggers?.map((trigger, index) => (
                  <Col key={index} md={4}>
                    <Card className="mb-3">
                      <Card.Body>
                        <ListGroup variant="flush">
                          {Object.entries(trigger).map(([key, value], idx) => (
                            <ListGroup.Item key={idx} className="mb-1">
                              <span style={{ textTransform: "capitalize" }}>
                                {key}
                              </span>
                              <b style={{ fontSize: "0.9rem" }}> {value}</b>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          )}
          {profile?.details?.grootMonitoring?.length > 0 && (
            <Card className="mb-3">
              <Card.Body>
                <h5 className="my-3">
                  <b>Groot Monitoring</b>
                </h5>
                <PaginatedTable
                  data={
                    profile?.details?.grootMonitoring?.map((groot) => {
                      return {
                        key: groot.key,
                        enabled: groot.enabled ? "enabled" : "not-enabled",
                      };
                    }) || []
                  }
                  columns={COLUMNS["grootMonitoring"]}
                  perPage={25}
                />
              </Card.Body>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default MonitoringProfileItemView;
