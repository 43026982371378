import {
  faArrowRight,
  faArrowsToCircle,
} from "@fortawesome/free-solid-svg-icons";
import GatewayMap from "components/Map/gatewayMap";
import ShimmerCard from "components/Shimmer/ShimmerCard";
import useActivityLogger from "hooks/useActivity";
import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Col,
  Dropdown,
  DropdownButton,
  ListGroup,
  Row,
} from "react-bootstrap";
import { useSelector } from "react-redux";

const GatewaySummary = ({ navigateToPage, isDashboard = false }) => {
  const { gateways, loading } = useSelector((state) => state.dashboard);
  //   const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedVal, setSelectedVal] = useState(null);

  const logActivity = useActivityLogger();
  const countries = useMemo(() => {
    return gateways?.locationBased?.map((gateway) => gateway?.country);
  }, [gateways]);

  useEffect(() => {
    if (gateways?.locationBased?.length > 0) {
      setSelectedCountry(gateways?.locationBased[0].country);
      setSelectedVal(gateways?.locationBased[0]);
    }
  }, [gateways]);

  const changeCountry = (country) => {
    setSelectedCountry(country);
    const findDetails = gateways?.locationBased.find(
      (location) => location.country === country
    );
    logActivity({
      page: "dashboard",
      activity: "gateway-country-change",
      name: country,
    });
    setSelectedVal(findDetails);
  };

  const groupDevicesByCoordinates = useMemo(() => {
    const groupedDevices = {};

    gateways?.gatewayDetails?.forEach((device) => {
      const coordinates = device?.hostDetails?.applianceLocation;
      if (coordinates) {
        const key = `${coordinates?.latitude},${coordinates?.longitude}`;
        if (!groupedDevices[key]) {
          groupedDevices[key] = {
            lat: coordinates?.latitude,
            lng: coordinates?.longitude,
            devices: [],
          };
        }

        groupedDevices[key].devices.push({
          gatewayName: device.gatewayName,
          country: device.country,
          location: device.location,
          serialNumber: device.hostDetails.license.serialNumber,
          softwareVersion: device.hostDetails.softwareVersion,
        });
      }
    });

    return Object.values(groupedDevices);
  }, [gateways]);

  const gatewayData = {
    title: "Gateways",
    total: gateways?.total,
    loading: loading["dashboard"],
    defaultIcon: faArrowsToCircle,
    hoverIcon: faArrowRight,
    bgColorClass: "violet",
    onClick: () => navigateToPage("/host#gateway", "Gateways"),
  };
  if (loading?.dashboard) {
    return <ShimmerCard />;
  }
  return (
    <Card className="mb-3 h-100">
      <Card.Body>
        <div className=" mb-3">
          <h5>
            <b>Gateway Summary</b>
          </h5>
          <small className="sub-info">Location and Gateways</small>
        </div>
        <Row>
          {isDashboard ? (
            <Col className="mb-3 px-3">
              <Dropdown.ItemText className="mb-3 w-100">
                <DropdownButton
                  id={`gateway-summary`}
                  className="filter-btn cust-dropdown w-100"
                  title={`${selectedCountry}`}
                  onSelect={(value) => {
                    changeCountry(value);
                  }}
                >
                  {countries?.map((option, index) => (
                    <Dropdown.Item key={index} eventKey={option}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Dropdown.ItemText>

              <Row className="mt-4 mb-2">
                <Col>
                  <div>
                    <h5>
                      <b>{selectedVal?.country}</b>
                    </h5>
                  </div>
                </Col>
                <Col className="col-auto">
                  <b className="gradient-txt" style={{ fontSize: "1.3rem" }}>
                    {selectedVal?.totalGatewayCount}
                  </b>
                </Col>
              </Row>
              <div
                style={{
                  maxHeight: "28vh",
                  // minHeight: "28vh",
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                {selectedVal?.locations?.map((location) => (
                  <div className="mb-2" key={location?.location}>
                    <Row className="mt-1 ">
                      <Col md={10}>
                        <div>
                          <h6
                            className="gradient-txt"
                            style={{ fontSize: "1.1rem" }}
                          >
                            <span style={{ textTransform: "capitalize" }}>
                              <b>{location?.location}</b>
                            </span>
                          </h6>
                        </div>
                      </Col>
                      <Col className="col-auto">
                        <b
                          className="gradient-txt"
                          style={{ fontSize: "1.1rem" }}
                        >
                          {location?.gatewayCount}
                        </b>
                      </Col>
                    </Row>

                    <ListGroup variant="flush">
                      {location?.gateways.map((gateway) => (
                        <ListGroup.Item key={gateway?.gatewayName}>
                          <span style={{ textTransform: "capitalize" }}>
                            <b>{gateway?.gatewayName}</b>
                          </span>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                    {/* <hr /> */}
                  </div>
                ))}
              </div>
            </Col>
          ) : (
            <Col>
              <GatewayMap
                groupedDeviceData={groupDevicesByCoordinates}
                selectedCountry={selectedCountry}
              />
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default GatewaySummary;
