import React from "react";
import { ListGroup } from "react-bootstrap";

const PageActivity = ({ pageActivity, loading }) => (
  <div>
    <div className="mt-2 mb-3">
      <h5>
        <b>Page Activity</b>
      </h5>
    </div>
    {!loading && (
      <ListGroup>
        {pageActivity?.map((page) => (
          <ListGroup.Item key={page._id}>
            <strong>{page._id || "Other"}</strong>
            <ul>
              {page?.activities?.map((activity, index) => (
                <li key={`activity-${index}`}>
                  {activity.activity}: {activity.count}
                </li>
              ))}
            </ul>
          </ListGroup.Item>
        ))}
      </ListGroup>
    )}
  </div>
);

export default PageActivity;
