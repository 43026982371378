import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PaginatedTable } from "components";

import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import MonitoringProfileItemView from "../ProfileItems/ProfileSingleView";

const MonitoringProfileView = ({ selectedProfile, onEdit, onClone }) => {
  const [selectedProfileItem, setSelectedProfileItem] = useState({});

  useEffect(() => {
    if (selectedProfile?._id) setSelectedProfileItem(selectedProfile?.items[0]);
  }, [selectedProfile]);

  if (!selectedProfile) {
    return;
  }

  return (
    <div className="">
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col md={12}>
              <Row>
                <Col>
                  <div className="mb-3">
                    <h5>
                      <b>{selectedProfile?.name}</b>
                    </h5>
                    <small className="sub-info">
                      Detailed view of <b>{selectedProfile?.name}</b>
                    </small>
                  </div>
                </Col>
                <Col className="col-auto">
                  {onEdit && (
                    <Button variant="warning" className="mx-2" onClick={onEdit}>
                      <FontAwesomeIcon icon={faEdit} className="mx-2" /> Edit
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
            <Col md={3}>
              <PaginatedTable
                data={selectedProfile?.items}
                columns={[{ dataField: "name", text: "Name" }]}
                perPage={100}
                filterRequired={false}
                paginationRequired={false}
                onView={(item) => setSelectedProfileItem(item)}
              />
            </Col>
            <Col>
              <MonitoringProfileItemView profile={selectedProfileItem} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default MonitoringProfileView;
