import { useEffect, useState } from "react";
import { Form, Button, Modal, Col, Row, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Profile } from "stores/actions";

const ProfileItemsAddForm = ({ show, onClose, currentProfile }) => {
  const dispatch = useDispatch();

  const defaultExpression = {
    retries: "",
    severity: "",
    high_threshold: "",
    low_threshold: "",
    function: "",
  };

  const initData = {
    name: "",
    frequency: "",
    status: "enabled",
    key: "",
    triggers: [defaultExpression],
    type: "monitoring",
    grootMonitoring: [{ key: "", enabled: true }],
  };

  const [profileForm, setProfileForm] = useState(initData);

  useEffect(() => {
    setProfileForm(
      currentProfile?._id ? { ...initData, ...currentProfile } : initData
    );
  }, [currentProfile]);

  const handleFormChange = (field, value) => {
    setProfileForm((prev) => ({ ...prev, [field]: value }));
  };

  const handleNestedChange = (field, index, key, value) => {
    setProfileForm((prev) => {
      const updatedArray = [...prev[field]];
      updatedArray[index] = { ...updatedArray[index], [key]: value };
      return { ...prev, [field]: updatedArray };
    });
  };

  const addNestedItem = (field, defaultItem) => {
    setProfileForm((prev) => ({
      ...prev,
      [field]: [...prev[field], defaultItem],
    }));
  };

  const handleSave = async () => {
    const action = currentProfile?._id
      ? Profile.updateProfileItem(profileForm)
      : Profile.createProfileItem(profileForm);
    await dispatch(action);
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          {profileForm._id
            ? "Edit Monitoring Profile"
            : "Add Monitoring Profile"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Card className="mb-3">
            <Card.Body>
              <Form.Group controlId="profileName" as={Row} className="mt-3">
                <Form.Label column sm={3}>
                  Name
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="text"
                    value={profileForm.name}
                    placeholder="Enter name"
                    onChange={(e) => handleFormChange("name", e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                controlId="monitoringEnabled"
                as={Row}
                className="mt-3"
              >
                <Form.Label column sm={3}>
                  Enabled
                </Form.Label>
                <Col sm={9}>
                  <Form.Check
                    type="checkbox"
                    checked={profileForm.status === "enabled"}
                    onChange={(e) =>
                      handleFormChange(
                        "status",
                        e.target.checked ? "enabled" : "disabled"
                      )
                    }
                  />
                </Col>
              </Form.Group>
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Body>
              <Form.Group controlId="triggers" className="mt-3">
                <Row className="mb-2">
                  <Col>
                    <Form.Label>
                      <b>Triggers</b>
                    </Form.Label>
                  </Col>
                  <Col className="col-auto">
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() =>
                        addNestedItem("triggers", defaultExpression)
                      }
                    >
                      +
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>Retries</Col>
                  <Col>Severity</Col>
                  <Col>High Threshold</Col>
                  <Col>Low Threshold</Col>
                  <Col>Function</Col>
                </Row>
                {profileForm?.triggers?.map((trigger, index) => (
                  <Row className="mt-3" key={index}>
                    <Col>
                      <Form.Control
                        type="text"
                        value={trigger.retries}
                        placeholder="Retries"
                        onChange={(e) =>
                          handleNestedChange(
                            "triggers",
                            index,
                            "retries",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        as="select"
                        value={trigger.severity}
                        onChange={(e) =>
                          handleNestedChange(
                            "triggers",
                            index,
                            "severity",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select</option>
                        <option value="Info">Info</option>
                        <option value="Warning">Warning</option>
                        <option value="High">High</option>
                        <option value="Disaster">Disaster</option>
                      </Form.Control>
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        value={trigger.high_threshold}
                        placeholder="High Threshold"
                        onChange={(e) =>
                          handleNestedChange(
                            "triggers",
                            index,
                            "high_threshold",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        value={trigger.low_threshold}
                        placeholder="Low Threshold"
                        onChange={(e) =>
                          handleNestedChange(
                            "triggers",
                            index,
                            "low_threshold",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        value={trigger.function}
                        placeholder="Function"
                        onChange={(e) =>
                          handleNestedChange(
                            "triggers",
                            index,
                            "function",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                  </Row>
                ))}
              </Form.Group>
            </Card.Body>
          </Card>

          {/* <Card className="mb-3">
            <Card.Body>
              <Form.Group controlId="grootMonitoring">
                <Row className="mb-2">
                  <Col>
                    <Form.Label>
                      <b>Groot Monitoring</b>
                    </Form.Label>
                  </Col>
                  <Col className="col-auto">
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() =>
                        addNestedItem("grootMonitoring", {
                          key: "",
                          enabled: false,
                        })
                      }
                    >
                      +
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md={1}>Enabled</Col>
                  <Col md={3}>Key</Col>
                </Row>
                {profileForm.grootMonitoring.map((groot, index) => (
                  <Row className="mt-3" key={index}>
                    <Col md={1}>
                      <Form.Check
                        type="checkbox"
                        checked={groot.enabled}
                        onChange={(e) =>
                          handleNestedChange(
                            "grootMonitoring",
                            index,
                            "enabled",
                            e.target.checked
                          )
                        }
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Control
                        type="text"
                        value={groot.key}
                        placeholder="Key"
                        onChange={(e) =>
                          handleNestedChange(
                            "grootMonitoring",
                            index,
                            "key",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                  </Row>
                ))}
              </Form.Group>
            </Card.Body>
          </Card> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProfileItemsAddForm;
