import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import SingleView from "./SingleHostView";
import HostForm from "./AddForm";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { usePermissions } from "hooks/usePermission";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Host } from "stores/actions";
import GatewayTab from "components/GatewayTab";
import { host_tabs } from "constants/mapping";
import ShimmerTable from "components/Shimmer/ShimmerTable";
import PasswordManager from "components/PasswordManager";

const HostList = ({ sendLog }) => {
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [selectedHost, setSelectedHost] = useState(null);
  const [hostToEdit, setEditToHost] = useState(null);
  const { hosts, loading } = useSelector((state) => state.host);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hash = location.hash.substring(1) || "director";
  const hasViewPermission = usePermissions(["HOSTS_VIEW"]);
  const hasEditPermission = usePermissions(["HOSTS_EDIT"]);
  const hasManagePasswordPermission = usePermissions(["HOSTS_PASSWORD"]);

  const fetchHost = (category) => {
    dispatch(Host.fetchHosts(category));
  };

  useEffect(() => {
    if (!location.hash) {
      fetchHost("director");
      navigate("#director", { replace: true });
    } else {
      fetchHost(location.hash.replace("#", ""));
    }
  }, [location.hash, navigate]);

  const handleSelect = useCallback(
    (key) => {
      navigate(`#${key}`);
    },
    [navigate]
  );

  const getHostData = useCallback((hosts) => {
    return hosts?.map((host) => ({
      ...host,
      id: host._id,
      name: host?.name,
      headend: `${host?.headendCode || ""}-${host?.headendName || ""}`,
      category: host?.type || host?.subtype || host?.category?.name,
      region: host?.region?.name || host?.region,
      spackVersion: host?.spackVersion,
      owner: host?.ownerOrgName,
      osspackVersion: host?.osspackVersion,
      bundleVersion: host?.appIdDetails?.appIdInstalledBundleVersion,
      region: host?.region?.name,
    }));
  }, []);

  const onView = useCallback(
    (item) => {
      sendLog("host", "click-host-view", item?.name);
      if (item?.category?.name === "gateway") {
        setSelectedHost(item);
      } else setSelectedHost(hosts.find((host) => item._id === host._id));
      setShowViewModal(true);
    },
    [hosts, sendLog]
  );

  const onEdit = useCallback(
    (item) => {
      sendLog("host", "click-host-edit", item?.name);
      if (item?.category?.name === "gateway") {
        setEditToHost(item);
      } else setEditToHost(hosts.find((data) => item._id === data._id));
      setShowModal(true);
    },
    [hosts, sendLog]
  );

  const onViewPassword = useCallback(
    (item) => {
      sendLog("host", "click-host-password-view", item?.name);
      setSelectedHost(item);
      setShowPasswordModal(true);
    },
    [hosts, sendLog]
  );

  const TabContent = useMemo(
    () =>
      ({ hosts, category, type }) => {
        const hostData = getHostData(hosts);
        return category === "gateway" ? (
          <GatewayTab categorised={hosts} onEdit={onEdit} onView={onView} />
        ) : (
          <PaginatedTable
            data={hostData || []}
            columns={COLUMNS[type || "host"]}
            perPage={20}
            onView={hasViewPermission ? onView : null}
            onEdit={hasEditPermission ? onEdit : null}
            onViewPassword={hasManagePasswordPermission ? onViewPassword : null}
          />
        );
      },
    [getHostData, onView, onEdit, hasViewPermission, hasEditPermission]
  );

  return (
    <div>
      {hosts && (
        <Tabs className={`hosts-tabs`} activeKey={hash} onSelect={handleSelect}>
          {host_tabs?.map((tab) => {
            return (
              <Tab
                key={tab?.name?.toLowerCase()}
                eventKey={tab?.value?.toLowerCase()}
                title={tab?.name}
                className="mt-3"
              >
                {loading["fetchHosts"] ? (
                  <ShimmerTable />
                ) : (
                  <TabContent
                    hosts={hosts}
                    category={tab?.value}
                    type={tab?.type}
                  />
                )}
              </Tab>
            );
          })}
        </Tabs>
      )}
      {showModal && (
        <HostForm
          host={hostToEdit}
          removeHost={setEditToHost}
          showModal={showModal}
          closeModal={() => {
            setEditToHost(null);
            setShowModal(false);
          }}
          isEdit={!!hostToEdit?._id}
        />
      )}
      {showViewModal && (
        <SingleView
          host={selectedHost}
          removeHost={setSelectedHost}
          showModal={showViewModal}
          closeModal={() => setShowViewModal(false)}
        />
      )}
      {hasManagePasswordPermission && (
        <PasswordManager
          showModal={showPasswordModal}
          closeModal={() => setShowPasswordModal(false)}
          host={selectedHost}
        />
      )}
    </div>
  );
};

export default HostList;
