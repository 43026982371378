import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";

const EditButton = ({ handleClick, name, ...props }) => {
  return (
    <Button onClick={handleClick} {...props} className="edit-btn">
      <FontAwesomeIcon icon={faEdit} className="nav-icon" />
      &nbsp;{name}
    </Button>
  );
};

export default EditButton;
