// actions/hostActions.js
import API from "services/api";
import { startLoading, apiError } from "../slices/apiSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showAlert } from "../slices/alertSlice";
import MESSAGES from "constants/messages";
import React from "react";

const fetchHosts = createAsyncThunk(
  "hosts/fetchHosts",
  async (name, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(`/hosts?category=${name}`);
      return response.data;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["host-fetch-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

const fetchSingleHost = createAsyncThunk(
  "hosts/fetchSingleHost",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(`/hosts/${id}`);
      return response.data;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["host-fetch-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

const fetchHostSummary = createAsyncThunk(
  "hosts/fetchHostSummary",
  async (name, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(`/hosts/summary?headend=${name}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const saveHost = (hostData) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await API.post("/hosts", hostData);
    dispatch(fetchHosts()); // Refresh the list after saving
    return response.data;
  } catch (error) {
    dispatch(apiError(error.message));
    throw error;
  }
};

const updateHost = (hostData) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await API.put(`/hosts/${hostData._id}`, hostData);
    dispatch(
      showAlert({
        message: MESSAGES["host-update-sc"],
        variant: "success",
      })
    );
    return response.data;
  } catch (error) {
    dispatch(apiError(error.message));
    throw error;
  }
};

const uploadHosts = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await API.post("/hosts/bulkupload", formData, config);
    return response.data;
  } catch (error) {
    dispatch(apiError(error.message));
    throw error;
  }
};

export {
  fetchHosts,
  fetchHostSummary,
  fetchSingleHost,
  saveHost,
  uploadHosts,
  updateHost,
};
