import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Security } from "stores/actions";
import deepClone from "utils/deepClone";
import ShimmerCard from "./Shimmer/ShimmerCard";
import PasswordInput from "./PasswordInput";

const PasswordManager = ({ showModal, closeModal, host }) => {
  const { passwords, loading } = useSelector((state) => state.security);
  const { headends } = useSelector((state) => state.headend);
  const dispatch = useDispatch();

  const sshInit = {
    username: "",
    password: "",
    pem: "",
    jump: false,
    passphrase: "",
  };

  const guiInit = {
    userType: "admin",
    username: "",
    password: "",
    clientId: "",
    clientSecret: "",
    isTacacs: false,
  };

  const [selectedSSHCredentials, setSelectedSSHCredentials] = useState([]);
  const [selectedGuiCredentials, setSelectedGuiCredentials] = useState([]);
  const [formData, setFormData] = useState({
    sshCredentials: [],
    guiCredentials: [],
  });

  useEffect(() => {
    // Transform SSH credentials

    const sshCredentials =
      Object.entries(passwords?.ssh || {}).length > 0
        ? Object.entries(passwords.ssh).map(([username, details]) => ({
            username,
            ...details,
          }))
        : [sshInit];

    // Transform GUI credentials
    let guiInitMonitor = deepClone(guiInit);
    guiInitMonitor["userType"] = "monitoring";
    const guiCredentials =
      Object.entries(passwords?.gui || {}).length > 0
        ? Object.entries(passwords.gui).map(([username, details]) => ({
            username,
            ...details,
          }))
        : [guiInitMonitor, guiInit];

    setFormData({ sshCredentials, guiCredentials });
  }, [passwords]);

  const basePath = useMemo(() => {
    if (!host?._id) return "";
    const hostHeadend =
      headends.find(
        (headend) =>
          headend?.code === host?.headendCode &&
          headend.name === host?.headendName
      ) || null;
    if (!hostHeadend) return "";
    const path = `${hostHeadend?.code}-${hostHeadend?.category?.name}-${
      hostHeadend?.name
    }/${host?.category?.name?.trim()}/${host?.name?.trim()}`;

    return path;
  }, [[host, headends]]);

  /** Fetch all passwords from Vault and update the form */
  const fetchPasswords = async () => {
    if (basePath?.length > 0) {
      try {
        dispatch(
          Security.fetchVaultPasswords({ type: "list", path: basePath })
        );
      } catch (error) {
        console.error("❌ Error fetching passwords:", error.message);
      }
    }
  };

  /** Handle form input change */
  const handleChange = (type, index, e) => {
    const { name, value, checked, type: inputType } = e.target;
    const updatedList = deepClone(formData[type]);

    updatedList[index][name] = inputType === "checkbox" ? checked : value;
    setFormData((prev) => ({ ...prev, [type]: updatedList }));
  };

  /** Add new credentials */
  const handleAddItem = (type, defaultItem) => {
    setFormData((prev) => ({
      ...prev,
      [type]: [...(prev[type] || []), defaultItem],
    }));
  };

  /** Delete selected credentials */
  const handleBulkDelete = async (type) => {
    const selectedList =
      type === "sshCredentials"
        ? selectedSSHCredentials
        : selectedGuiCredentials;
    if (!selectedList.length) return;

    // Filter out selected items
    const updatedList = formData[type].filter(
      (_, index) => !selectedList.includes(index)
    );
    setFormData((prev) => ({ ...prev, [type]: updatedList }));

    type === "sshCredentials"
      ? setSelectedSSHCredentials([])
      : setSelectedGuiCredentials([]);
  };

  /** Save or update secrets in Vault */
  const handleSave = async () => {
    // const path = `${host?.headendCode.trim()}-${host?.headendName.trim()}/${host?.category.trim()}/${host?.name.trim()}`;

    try {
      const formatData = {};
      formData.guiCredentials.forEach((gui) => {
        let data = deepClone(gui);
        delete data?.username;
        formatData[`gui/${gui.username}`] = data;
      });
      formData.sshCredentials.forEach((ssh) => {
        let data = deepClone(ssh);
        delete data?.username;
        formatData[`ssh/${ssh.username}`] = data;
      });

      await dispatch(
        Security.updateVaultPasswords({
          path: basePath,
          data: formatData,
          type: "list",
        })
      );
      setSelectedGuiCredentials([]);
      setSelectedSSHCredentials([]);
      closeModal();
      console.log("Secrets saved successfully");
    } catch (error) {
      console.error("Error saving secrets:", error.message);
    }
  };

  /** Handle checkbox selection */
  const handleCheckboxChange = (type, index) => {
    const setSelected =
      type === "sshCredentials"
        ? setSelectedSSHCredentials
        : setSelectedGuiCredentials;
    setSelected((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  /** Render SSH Credentials */
  const renderSSHFields = () =>
    formData.sshCredentials?.map((ssh, index) => (
      <tr key={`ssh-${index}`}>
        <td>
          <Form.Check
            type="checkbox"
            checked={selectedSSHCredentials.includes(index)}
            onChange={() => handleCheckboxChange("sshCredentials", index)}
          />
        </td>
        <td>
          <Form.Control
            type={"text"}
            name={"username"}
            value={ssh["username"] || ""}
            onChange={(e) => handleChange("sshCredentials", index, e)}
            placeholder={`Enter username`}
          />
        </td>
        <td>
          <PasswordInput
            name="password"
            value={ssh["password"]}
            setValue={(e) => handleChange("sshCredentials", index, e)}
          />
        </td>
        <td>
          <Form.Control
            type={"text"}
            name={"passphrase"}
            value={ssh["passphrase"] || ""}
            onChange={(e) => handleChange("sshCredentials", index, e)}
            placeholder={`Enter passphrase`}
          />
        </td>
        <td>
          <Form.Check
            type="checkbox"
            name="jump"
            checked={ssh.jump}
            onChange={(e) => handleChange("guiCredentials", index, e)}
          />
        </td>
        <td colSpan={3}>
          <Form.Control
            as="textarea"
            rows={2}
            name="pem"
            value={ssh.pem}
            onChange={(e) => handleChange("sshCredentials", index, e)}
            placeholder={`Enter keyfile`}
          />
        </td>
      </tr>
    ));

  /** Render GUI Credentials */
  const renderGuiFields = () =>
    formData.guiCredentials?.map((gui, index) => (
      <tr key={`gui-${index}`} className="mb-2 align-items-center">
        {/* <td>
          <Form.Check
            type="checkbox"
            checked={selectedGuiCredentials.includes(index)}
            onChange={() => handleCheckboxChange("guiCredentials", index)}
          />
        </td> */}
        {/* <td>
          <Form.Control
            as="select"
            name="userType"
            value={gui.userType}
            onChange={(e) => handleChange("guiCredentials", index, e)}
            className="form-select"
            disabled
          >
            {guiUserTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Form.Control>
        </td> */}
        <td>
          <Form.Control
            type={"text"}
            name={"username"}
            value={gui.userType}
            // onChange={(e) => handleChange("guiCredentials", index, e)}
            // placeholder={`Enter username`}
            disabled
          />
        </td>
        <td>
          <Form.Control
            type={"text"}
            name={"username"}
            value={gui["username"] || ""}
            onChange={(e) => handleChange("guiCredentials", index, e)}
            placeholder={`Enter username`}
          />
        </td>
        <td>
          <PasswordInput
            name="password"
            value={gui["password"]}
            setValue={(e) => handleChange("guiCredentials", index, e)}
          />
        </td>
        {["clientId", "clientSecret"].map((field) => (
          <td key={field}>
            <Form.Control
              type={field === "password" ? "password" : "text"}
              name={field}
              value={gui[field]}
              onChange={(e) => handleChange("guiCredentials", index, e)}
              placeholder={`Enter ${field}`}
            />
          </td>
        ))}
        <td>
          <Form.Check
            type="checkbox"
            name="isTacacs"
            checked={gui.isTacacs}
            onChange={(e) => handleChange("guiCredentials", index, e)}
          />
        </td>
      </tr>
    ));

  useEffect(() => {
    fetchPasswords();
  }, [basePath]);

  return (
    <Modal show={showModal} onHide={closeModal} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Password Manager</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading["fetchVaultPasswords"] ? (
          <ShimmerCard />
        ) : (
          <>
            <Card className="mb-4">
              <Card.Body>
                <Row className="mb-3">
                  <Col>
                    <div>
                      <h5>
                        <b>SSH Credentials</b>
                      </h5>
                      <small className="sub-info">Manage SSH credentials</small>
                    </div>
                  </Col>
                  <Col className="col-auto">
                    <Button
                      className="mx-2 py-2"
                      onClick={() => handleAddItem("sshCredentials", sshInit)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <Button
                      className="py-2"
                      variant="danger"
                      onClick={() => handleBulkDelete("sshCredentials")}
                      disabled={!selectedSSHCredentials.length}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </Col>
                </Row>
                <Table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Username</th>
                      <th>Password</th>
                      <th>Passphrase</th>
                      <th>Jumpserver</th>
                      <th style={{ width: "35%" }}>Key File</th>
                    </tr>
                  </thead>
                  <tbody>{renderSSHFields()}</tbody>
                </Table>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Row className="mb-3">
                  <Col>
                    <div>
                      <h5>
                        <b>GUI Credentials</b>
                      </h5>
                      <small className="sub-info">Manage GUI credentials</small>
                    </div>
                  </Col>
                  {/* <Col className="col-auto">
                    <Button
                      className="mx-2 py-2"
                      onClick={() => handleAddItem("guiCredentials", guiInit)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <Button
                      variant="danger"
                      className="py-2"
                      onClick={() => handleBulkDelete("guiCredentials")}
                      disabled={!selectedGuiCredentials.length}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </Col> */}
                </Row>
                <Table>
                  <thead>
                    <tr>
                      {/* <th></th> */}
                      <th>User Type</th>
                      <th>Username</th>
                      <th>Password</th>
                      <th>Client ID</th>
                      <th>Client Secret</th>
                      <th>TACACS</th>
                    </tr>
                  </thead>
                  <tbody>{renderGuiFields()}</tbody>
                </Table>
              </Card.Body>
            </Card>
          </>
        )}
      </Modal.Body>
      {loading["fetchVaultPasswords"] ? (
        <></>
      ) : (
        <Modal.Footer>
          <Button
            className=""
            variant="primary"
            onClick={handleSave}
            disabled={loading["updateVaultPasswords"]}
          >
            {loading["updateVaultPasswords"] ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {"  "}
                saving...
              </>
            ) : (
              "Save"
            )}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default PasswordManager;
