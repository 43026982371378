const MESSAGES = {
  "host-fetch-sc": "Host loaded successfully",
  "host-fetch-fa": "Failed to load Hosts",
  "host-create-sc": "Host created successfully",
  "host-create-fa": "Failed to create Host",
  "host-update-sc": "Host updated successfully",
  "host-update-fa": "Failed to update Host",
  "host-delete-sc": "Host deleted successfully",
  "host-delete-fa": "Failed to delete Host",
  "headends-fetch-sc": "Headends loaded successfully",
  "headends-fetch-fa": "Failed to load Headends",
  "headend-onboard-sc": "Headend onboarded successfully",
  "headend-onboard-fa": "Failed to onboard Headend",
  "headend-save-sc": "Headend saved successfully",
  "headend-save-fa": "Failed to save headend",
  "backup-fetch-sc": "Backups loaded successfully",
  "backup-fetch-fa": "Failed to load Backups",
  "feedback-completed-sc": "Feedback marked as completed successfully",
  "feedback-open-sc": "Feedback marked as open successfully",
  "feedback-status-fa": "Failed to update feedback status",
  "comment-added-sc": "Comment added successfully",
  "comment-added-fa": "Failed to add comment",
  "login-sc": "Login successful.",
  "login-fa": "Login failed. Please check your credentials.",
  "role-create-sc": "Role created successfully.",
  "role-create-fa": "Failed to create the role.",
  "role-update-sc": "Role updated successfully.",
  "role-update-fa": "Failed to update the role.",
  "role-delete-sc": "Role deleted successfully.",
  "role-delete-fa": "Failed to delete the role.",
  "page-create-sc": "Page created successfully.",
  "page-create-fa": "Failed to create the page.",
  "page-update-sc": "Page updated successfully.",
  "page-update-fa": "Failed to update the page.",
  "page-delete-sc": "Page deleted successfully.",
  "page-delete-fa": "Failed to delete the page.",
  "permission-create-sc": "Permission created successfully.",
  "permission-create-fa": "Failed to create the permission.",
  "permission-update-sc": "Permission updated successfully.",
  "permission-update-fa": "Failed to update the permission.",
  "permission-delete-sc": "Permission deleted successfully.",
  "permission-delete-fa": "Failed to delete the permission.",
  "update-user-role-sc": "User role updated successfully",
  "update-user-role-fa": "Failed to update the user role",
};

export default MESSAGES;
