import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "services/api";
import { showAlert } from "stores/slices/alertSlice";

const fetchNmapReport = createAsyncThunk(
  "security/fetchSecurity",
  async (_, { rejectWithValue }) => {
    try {
      // Make the API call to fetch Nmap reports
      const response = await API.get(`/security/nmap`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchGenerativeAiChart = createAsyncThunk(
  "security/fetchGenerativeAiChart",
  async (id = null, { rejectWithValue }) => {
    try {
      const response = await API.get(`/security/generative-ai/chart`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchGenerativeAiWebsites = createAsyncThunk(
  "security/fetchGenerativeAiWebsites",
  async (id = null, { rejectWithValue }) => {
    try {
      const response = await API.get(`/security/generative-ai/websites`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchGenerativeAiPolicyAction = createAsyncThunk(
  "security/fetchGenerativeAiPolicyAction",
  async (id = null, { rejectWithValue }) => {
    try {
      const response = await API.get(`/security/generative-ai/policy-actions`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchVaultPasswords = createAsyncThunk(
  "security/fetchVaultPasswords",
  async ({ type = "", path }, { rejectWithValue }) => {
    try {
      const url = type === "list" ? "password/list" : "password";
      const response = await API.get(`/security/${url}?path=${path}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const updateVaultPasswords = createAsyncThunk(
  "security/updateVaultPasswords",
  async ({ path, data, type = "" }, { dispatch, rejectWithValue }) => {
    try {
      console.log(path, data, type);
      const url = type === "list" ? "password/list" : "password";
      const formattedData = {
        path,
        data,
      };
      const response = await API.post(`/security/${url}`, formattedData);
      dispatch(
        showAlert({
          message: "Updated password successfully",
          variant: "success",
        })
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export {
  fetchNmapReport,
  fetchGenerativeAiChart,
  fetchGenerativeAiWebsites,
  fetchGenerativeAiPolicyAction,
  fetchVaultPasswords,
  updateVaultPasswords,
};
