import React, { useCallback } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import DeviceTableView from "./DeviceTableView";

const AnalyticsView = ({ analytics = [] }) => {
  const { regions } = useSelector((state) => state.dashboard);

  const getAnalyticsNodes = useCallback(
    (analytic) => {
      const newData = [];

      analytic?.nodes?.forEach((aData) => {
        let data = {
          ...aData,
          name: aData.name,
          analyticsName: analytic.name,
          region:
            regions.find((region) => region._id === analytic?.region) ||
            aData?.region?.name,
          id: aData?._id,
          cluster: aData?.analyticName,
          active: aData?.active ? "Yes" : "No",
        };
        newData.push({ host: data });
      });

      return newData;
    },
    [analytics]
  );

  return (
    <>
      <div className="my-3">
        <h5>
          <b>Analytics</b>
        </h5>
        <small className="sub-info">List of all Analytics nodes</small>
      </div>
      {analytics?.map((analytic) => (
        <Card key={analytic?.name}>
          <Card.Body>
            <div className="my-3">
              <h5>
                <b>{analytic?.name}</b>
              </h5>
              <small className="sub-info">
                List of all nodes under <b>{analytic?.name}</b>
              </small>
            </div>
            <DeviceTableView
              data={getAnalyticsNodes(analytic)}
              type=""
              columnsKey="analytics"
              title=""
            />
          </Card.Body>
        </Card>
      ))}
    </>
  );
};

export default AnalyticsView;
