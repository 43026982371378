import React, { useState, useEffect, useMemo } from "react";
import { Button, Row, Col, Tabs } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { Profile } from "stores/actions";

import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";
import { usePermissions } from "hooks/usePermission";
import { Tab } from "bootstrap";
import ProfileItemsAddForm from "./ProfileItemsAddForm";
import MonitoringProfileView from "../Profile/ProfileSingleView";
import MonitoringProfileItemView from "./ProfileSingleView";
import deepClone from "utils/deepClone";

const MonitoringProfileItems = () => {
  const hasAddPermission = usePermissions(["MONITOR_PROFILE_ADD"]);
  const hasEditPermission = usePermissions(["MONITOR_PROFILE_EDIT"]);
  const { profileItems } = useSelector((state) => state.profiles);
  const [showModal, setShowModal] = useState(false);
  const [currentProfile, setCurrentProfile] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Profile.fetchProfileItems("monitoring"));
  }, []);

  useEffect(() => {
    if (profileItems.length > 0) {
      setSelectedProfile(profileItems[0]);
    }
  }, [profileItems]);

  const handleClone = async (profile) => {
    let name = `${profile.name}_cloned`;
    const profileNames = profileItems.filter((prof) =>
      prof.name.includes(name)
    );
    const clonedOne = deepClone(profile);
    delete clonedOne._id;
    const data = {
      ...clonedOne,
      name:
        profileNames.length > 0 ? `${name}_${profileNames.length + 1}` : name,
      type: "monitoring",
    };
    const clonedData = await dispatch(Profile.createProfileItem(data));
    setCurrentProfile(clonedData?.payload);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    await dispatch(Profile.deleteProfileItem(id));
  };

  const onView = (profile) => {
    setSelectedProfile(profile);
    // setShowModal(true);
  };

  const onEdit = (profile) => {
    setCurrentProfile(profile);
    setShowModal(true);
  };

  return (
    <div>
      <Row className="my-3 mx-1">
        <Col>
          <div className="my-2">
            <h5>
              <b>Profile Items</b>
            </h5>
            <small className="sub-info">
              All monitoring related profiles items are listed here
            </small>
          </div>
        </Col>
        <Col className="col-auto">
          {hasAddPermission && (
            <Button onClick={() => setShowModal(true)}>Add New Profile</Button>
          )}
        </Col>
      </Row>

      <Row>
        <Col md={3}>
          <PaginatedTable
            data={profileItems}
            columns={[{ dataField: "name", text: "Name" }]}
            perPage={50}
            filterRequired={false}
            onView={onView}
          />
        </Col>
        <Col>
          {profileItems.length > 0 ? (
            <MonitoringProfileItemView
              profile={selectedProfile}
              onEdit={onEdit}
              onClone={handleClone}
            />
          ) : (
            <></>
          )}
        </Col>
      </Row>

      <ProfileItemsAddForm
        show={showModal}
        onClose={() => setShowModal(false)}
        currentProfile={currentProfile}
      />
    </div>
  );
};

export default MonitoringProfileItems;
