import React from "react";
import { Tabs, Tab, Card } from "react-bootstrap";
import {
  AnalyticsView,
  AppliancesView,
  OrganisationView,
  UsersView,
} from "./Details";
import DeviceTableView from "./Details/DeviceTableView";

const HeadendDetailsView = ({ headend }) => {
  return (
    <>
      {headend && (
        <Card>
          <Card.Body>
            <Tabs
              defaultActiveKey="direcotrs"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="direcotrs"
                title={`Directors(${headend.directors.length})`}
              >
                <DeviceTableView
                  data={headend.directors}
                  type="director"
                  columnsKey="director"
                  title="Directors"
                />
              </Tab>
              <Tab
                eventKey="organisations"
                title={`Tenants (${headend.organisations.length})`}
              >
                <OrganisationView tenants={headend.organisations} />
              </Tab>
              <Tab
                eventKey="controllers"
                title={`Controllers(${headend.controllers.length})`}
              >
                <DeviceTableView
                  data={headend.controllers}
                  type="controller"
                  columnsKey="controller"
                  title="Controllers"
                />
              </Tab>
              <Tab
                eventKey="analytics"
                title={`Analytics(${headend.analytics.reduce(
                  (acc, analytic) => acc + analytic?.nodes?.length,
                  0
                )})`}
              >
                <AnalyticsView analytics={headend.analytics} />
              </Tab>
              {headend?.gateways?.length > 0 && (
                <Tab
                  eventKey="gateways"
                  title={`Gateways(${headend.gateways.length})`}
                >
                  <DeviceTableView
                    data={headend.gateways}
                    type="gateway"
                    columnsKey="gateway"
                    title="Gateways"
                  />
                </Tab>
              )}
              <Tab
                eventKey="appliances"
                title={`Appliances(${headend.appliances.length})`}
              >
                <AppliancesView appliances={headend.appliances} />
              </Tab>
              {headend?.pe?.length > 0 && (
                <Tab eventKey="pe" title={`PE(${headend?.pe?.length})`}>
                  <DeviceTableView
                    data={headend?.pe}
                    type="pe"
                    columnsKey="appliance"
                    title="PE"
                  />
                </Tab>
              )}
              {headend?.cfw?.length > 0 && (
                <Tab eventKey="cfw" title={`CFW(${headend?.cfw?.length})`}>
                  <DeviceTableView
                    data={headend?.cfw}
                    type="cfw"
                    columnsKey="appliance"
                    title="CFW"
                  />
                </Tab>
              )}
              {headend?.vms?.length > 0 && (
                <Tab eventKey="vms" title={`Vms(${headend.vms.length})`}>
                  <DeviceTableView
                    data={headend?.vms}
                    type="vms"
                    columnsKey="vms"
                    title="VMS"
                  />
                </Tab>
              )}
              <Tab
                eventKey="users"
                title={`Users(${
                  headend?.tenantUsers?.length + headend?.adminUsers?.length
                })`}
              >
                <UsersView
                  tenantUsers={headend?.tenantUsers}
                  adminUsers={headend?.adminUsers}
                />
              </Tab>
              {headend?.["orchestrator-upgrade"]?.length > 0 && (
                <Tab
                  eventKey="orchestrators"
                  title={`Orchestrators upgrade(${
                    headend?.["orchestrator-upgrade"]?.length || 0
                  })`}
                >
                  <DeviceTableView
                    data={headend?.["orchestrator-upgrade"]}
                    type="orchestrator"
                    columnsKey="wafs"
                    title="Orchestrators"
                  />
                </Tab>
              )}
              {headend?.["waf"]?.length > 0 && (
                <Tab eventKey="WAF" title={`WAF(${headend?.waf?.length || 0})`}>
                  <DeviceTableView
                    data={headend?.waf}
                    type="waf"
                    columnsKey="wafs"
                    title="WAF"
                  />
                </Tab>
              )}
            </Tabs>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default HeadendDetailsView;
