import { useState } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const PasswordInput = ({ value, setValue, name }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Form.Group controlId="password">
      <InputGroup>
        <Form.Control
          type={showPassword ? "text" : "password"}
          placeholder="Enter password"
          value={value}
          onChange={(e) => setValue(e)}
          name={name}
        />
        <Button
          variant="outline-secondary"
          onClick={() => setShowPassword(!showPassword)}
        >
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
        </Button>
      </InputGroup>
    </Form.Group>
  );
};

export default PasswordInput;
