import React from "react";
import { Card, Row, Col } from "react-bootstrap";

const ShimmerBoxes = () => {
  const rows = [...Array(3)];

  return (
    <Card.Body>
      <Row className="d-flex justify-content-center align-items-center mb-3">
        <Col className="col-auto">
          <div className="shimmer-circle"></div>
        </Col>
      </Row>

      <div className="shimmer-list">
        {rows.map((_, index) => (
          <React.Fragment key={index}>
            <Row className="shimmer-item mb-1">
              <Col>
                <div
                  className="shimmer-text shimmer-item-label"
                  style={{ width: "40%" }}
                >
                  &nbsp;
                </div>
              </Col>
              <Col className="col-auto">
                <div
                  className="shimmer-text shimmer-item-number"
                  style={{ width: "30px" }}
                >
                  &nbsp;
                </div>
              </Col>
            </Row>
            {index !== rows.length - 1 && <hr />}
          </React.Fragment>
        ))}
      </div>
    </Card.Body>
  );
};

export default ShimmerBoxes;
