import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { fetchBackups, fetchBackupSummary } from "stores/actions";
import useActivityLogger from "hooks/useActivity";
import BackupHistory from "./tabs/BackupHistory";
import BackupProfileTab from "./tabs/BackupProfiles";
import { usePermissions } from "hooks/usePermission";
import BackupAuditReport from "./tabs/BackupAuditReport";
import BackupSummary from "./components/BackupSummary";

const Backups = () => {
  const dispatch = useDispatch();
  const hasProfileViewPermission = usePermissions(["BACKUP_PROFILE_VIEW"]);
  const logActivity = useActivityLogger();
  const [key, setKey] = useState(
    localStorage.getItem("backup_page") || "summary"
  );

  const handleKey = (key) => {
    setKey(key);
    localStorage.setItem("backup_page", key);
  };

  useEffect(() => {
    logActivity({
      page: "backups",
      activity: "page-load",
    });
    dispatch(fetchBackups());
    dispatch(fetchBackupSummary());
  }, []);

  return (
    <div className="middle-section">
      <div className="my-2">
        <h5>
          <b>Backup</b>
        </h5>
        <small className="sub-info">
          All backup related details are available here
        </small>
      </div>

      <Card className="mt-3">
        <Card.Body>
          <Tabs
            id="controlled-tabs"
            activeKey={key}
            onSelect={handleKey}
            className="mb-3  mr-3 p-0"
          >
            <Tab eventKey="summary" title="SUMMARY">
              {/* <BackupAuditReport /> */}
              <BackupSummary />
            </Tab>
            <Tab eventKey="audit" title="AUDIT">
              <BackupHistory />
            </Tab>
            {hasProfileViewPermission && (
              <Tab eventKey="profile" title="PROFILES">
                <BackupProfileTab />
              </Tab>
            )}
          </Tabs>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Backups;
