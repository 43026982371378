import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Dashboard,
  Login,
  Logout,
  Redirect as OIDCRedirect,
  Tenants,
  Search,
  Host,
  Headend,
  Categories,
  HeadendDetails,
  Isps,
  Backups,
  Orchestrators,
  Manage,
  Profile,
  Unauthorized,
  NotFound,
  Activities,
  Security,
  DataCenter,
  SilentRenew,
  Monitoring,
  TenantDetails,
  OrchestratorsDetails,
  Gateway,
  DataCenterDetails,
  Maintenance,
  MaintenanceDetails,
  HostDetails,
} from "pages";

import HeadendForm from "pages/Headend/AddForm";
import RouteWithLayout from "./routeWithLayout"; // Adjust the path to where your RouteWithLayout is
import LogoutCallback from "pages/Auth/Idp/LogoutCallback";
import Feedbacks from "pages/Manage/Tabs/Feedbacks";

const ClientRoutes = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/callback" element={<OIDCRedirect />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/logout-callback" element={<LogoutCallback />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route
            path="/dashboard"
            exact
            element={
              <RouteWithLayout
                component={Dashboard}
                permissions={["DASHBOARD_VIEW"]}
              />
            }
          />
          <Route
            path="/tenants"
            exact
            element={
              <RouteWithLayout
                component={Tenants}
                permissions={["TENANTS_VIEW"]}
              />
            }
          />
          <Route
            path="/tenants/:id"
            exact
            element={
              <RouteWithLayout
                component={TenantDetails}
                permissions={["TENANTS_VIEW"]}
              />
            }
          />
          <Route
            path="/host"
            exact
            element={
              <RouteWithLayout component={Host} permissions={["HOSTS_VIEW"]} />
            }
          />
          <Route
            path="/host/:id"
            exact
            element={
              <RouteWithLayout
                component={HostDetails}
                permissions={["HOSTS_VIEW"]}
              />
            }
          />
          <Route
            path="/headends"
            exact
            element={
              <RouteWithLayout
                component={Headend}
                permissions={["HEADENDS_VIEW"]}
              />
            }
          />
          <Route
            path="/headends/manage/:id"
            exact
            element={
              <RouteWithLayout
                component={HeadendForm}
                permissions={["HEADENDS_EDIT"]}
              />
            }
          />
          <Route
            path="/headends/:id"
            exact
            element={
              <RouteWithLayout
                component={HeadendDetails}
                permissions={["HEADENDS_VIEW"]}
              />
            }
          />
          <Route
            path="/categories"
            exact
            element={
              <RouteWithLayout
                component={Categories}
                permissions={["CATEGORIES_VIEW"]}
              />
            }
          />
          <Route
            path="/security"
            exact
            element={
              <RouteWithLayout
                component={Security}
                permissions={["SECURITY_VIEW"]}
              />
            }
          />
          <Route
            path="/orchestrators"
            exact
            element={
              <RouteWithLayout
                component={Orchestrators}
                permissions={["WRAPPERS_VIEW"]}
              />
            }
          />
          <Route
            path="/orchestrators/:id"
            exact
            element={
              <RouteWithLayout
                component={OrchestratorsDetails}
                permissions={["WRAPPERS_VIEW"]}
              />
            }
          />
          <Route
            path="/isps"
            exact
            element={
              <RouteWithLayout component={Isps} permissions={["ISPS_VIEW"]} />
            }
          />
          <Route
            path="/dc"
            exact
            element={
              <RouteWithLayout
                component={DataCenter}
                permissions={["DC_VIEW"]}
              />
            }
          />
          <Route
            path="/dc/:id"
            exact
            element={
              <RouteWithLayout
                component={DataCenterDetails}
                permissions={["DC_VIEW"]}
              />
            }
          />
          <Route
            path="/search"
            exact
            element={
              <RouteWithLayout
                component={Search}
                permissions={["SEARCH_VIEW"]}
              />
            }
          />
          <Route
            path="/backups"
            exact
            element={
              <RouteWithLayout
                component={Backups}
                permissions={["BACKUPS_VIEW"]}
              />
            }
          />
          <Route
            path="/manage"
            exact
            element={
              <RouteWithLayout
                component={Manage}
                permissions={["MANAGE_VIEW"]}
              />
            }
          />

          <Route
            path="/"
            element={
              <RouteWithLayout
                component={Dashboard}
                permissions={["DASHBOARD_VIEW"]}
              />
            }
          />

          <Route
            path="/profile"
            element={
              <RouteWithLayout
                component={Profile}
                permissions={["PROFILE_VIEW"]}
              />
            }
          />

          <Route
            path="/activities"
            element={
              <RouteWithLayout
                component={Activities}
                permissions={["ACTIVITIES_VIEW"]}
              />
            }
          />

          <Route
            path="/monitoring"
            element={
              <RouteWithLayout
                component={Monitoring}
                permissions={["MONITORING_VIEW"]}
              />
            }
          />
          <Route path="/silent-renew" element={<SilentRenew />} />
          <Route
            path="/gateways"
            element={
              <RouteWithLayout
                component={Gateway}
                permissions={["GATEWAY_VIEW"]}
              />
            }
          />
          <Route
            path="/maintenance"
            element={
              <RouteWithLayout
                component={Maintenance}
                permissions={["NOTIFICATION_VIEW"]}
              />
            }
          />
          <Route
            path="/maintenance/:id"
            element={
              <RouteWithLayout
                component={MaintenanceDetails}
                permissions={["NOTIFICATION_VIEW"]}
              />
            }
          />
          <Route
            path="/feedbacks"
            element={
              <RouteWithLayout
                component={Feedbacks}
                permissions={["FEEDBACKS_VIEW"]}
              />
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

export default ClientRoutes;
