import { PaginatedTable } from "components";
import Highlight from "components/HighlightText";
import COLUMNS from "constants/columns";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const { Accordion, Badge } = require("react-bootstrap");

const UsersResult = ({ users, sendLog, query }) => {
  const navigate = useNavigate();

  const redirectToHeadend = (id, name) => {
    sendLog("redirect", name);
    navigate(`/headends/${id}`);
  };

  const userDetails = useMemo(() => {
    return users.map((user) => ({
      ...user?.user,
      name: <Highlight text={user?.user?.name} highlight={query} />,
      tenant: <Highlight text={user?.user?.tenant} highlight={query} />,
      twoFactor: user?.user.twoFactor ? "success" : "failed",
      headendId: user?.headendId,
      headendName: (
        <div
          className="link"
          onClick={() =>
            redirectToHeadend(
              user?.headendId,
              `${user.headendCode}-${user.headendName}`
            )
          }
        >
          <Highlight
            text={`${user.headendCode}-${user.headendName}`}
            highlight={query}
          />
        </div>
      ),
    }));
  }, [users]);

  return (
    <Accordion.Item eventKey="users">
      <Accordion.Header>
        User :
        <Badge pill bg="info">
          {users?.length}
        </Badge>
      </Accordion.Header>
      <Accordion.Body>
        <PaginatedTable
          data={userDetails}
          columns={COLUMNS["searchUsers"]}
          itemsPerPage={10}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default UsersResult;
