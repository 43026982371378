import React from "react";
import API from "services/api";
import { showAlert } from "../slices/alertSlice";
import MESSAGES from "../../constants/messages";
import { createAsyncThunk } from "@reduxjs/toolkit";

const register = createAsyncThunk(
  "auth/login",
  async (userData, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.post("/auth/register", userData);
      dispatch(
        showAlert({
          message: MESSAGES["login-sc"],
          variant: "success",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["login-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

const fetchUser = createAsyncThunk(
  "users/fetchUser",
  async (email, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(`/users?email=${email}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get("/users");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const updateUser = createAsyncThunk(
  "users/updateUser",
  async (user, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.put(`/users/${user._id}`, user);
      dispatch(
        showAlert({
          message: MESSAGES["update-user-role-sc"],
          variant: "success",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["update-user-role-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

const updateUserPages = createAsyncThunk(
  "users/updateUserPages",
  async ({ userId, updatedPages }, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.put(`/users/${userId}/pages`, updatedPages);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchRoles = createAsyncThunk(
  "roles/fetchRoles",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get("/roles");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchPages = createAsyncThunk(
  "pages/fetchPages",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get("/pages");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchPermissions = createAsyncThunk(
  "permissions/fetchPermissions",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get("/permissions");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const createRole = createAsyncThunk(
  "roles/createRole",
  async (role, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.post("/roles", role);
      dispatch(
        showAlert({
          message: MESSAGES["role-create-sc"],
          variant: "success",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["role-create-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

const updateRole = createAsyncThunk(
  "roles/updateRole",
  async ({ roleId, updatedRole }, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.put(`/roles/${roleId}`, updatedRole);
      dispatch(
        showAlert({
          message: MESSAGES["role-update-sc"],
          variant: "success",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["role-update-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

const deleteRole = createAsyncThunk(
  "roles/deleteRole",
  async (roleId, { dispatch, rejectWithValue }) => {
    try {
      await API.delete(`/roles/${roleId}`);
      dispatch(
        showAlert({
          message: MESSAGES["role-delete-sc"],
          variant: "success",
        })
      );
      return roleId;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["role-delete-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

const createPage = createAsyncThunk(
  "pages/createPage",
  async (page, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.post("/pages", page);
      dispatch(
        showAlert({
          message: MESSAGES["page-create-sc"],
          variant: "success",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["page-create-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

const updatePage = createAsyncThunk(
  "pages/updatePage",
  async ({ pageId, updatedPage }, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.put(`/pages/${pageId}`, updatedPage);
      dispatch(
        showAlert({
          message: MESSAGES["page-update-sc"],
          variant: "success",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["page-update-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

const deletePage = createAsyncThunk(
  "pages/deletePage",
  async (pageId, { dispatch, rejectWithValue }) => {
    try {
      await API.delete(`/pages/${pageId}`);
      dispatch(
        showAlert({
          message: MESSAGES["page-delete-sc"],
          variant: "success",
        })
      );
      return pageId;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["page-delete-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

const createPermission = createAsyncThunk(
  "permission/createPermission",
  async (permission, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.post("/permissions", permission);
      dispatch(
        showAlert({
          message: MESSAGES["permission-create-sc"],
          variant: "success",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["permission-create-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

const updatePermission = createAsyncThunk(
  "permission/updatePermission",
  async (
    { permissionId, updatedPermission },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await API.put(
        `/permissions/${permissionId}`,
        updatedPermission
      );
      dispatch(
        showAlert({
          message: MESSAGES["permission-update-sc"],
          variant: "success",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["permission-update-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

const deletePermission = createAsyncThunk(
  "permission/deletePermission",
  async (permissionId, { dispatch, rejectWithValue }) => {
    try {
      await API.delete(`/permissions/${permissionId}`);
      dispatch(
        showAlert({
          message: MESSAGES["permission-delete-sc"],
          variant: "success",
        })
      );
      return permissionId;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["permission-delete-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export {
  register,
  fetchUser,
  fetchUsers,
  updateUser,
  updateUserPages,
  fetchRoles,
  fetchPages,
  fetchPermissions,
  createRole,
  updateRole,
  deleteRole,
  createPage,
  updatePage,
  deletePage,
  createPermission,
  updatePermission,
  deletePermission,
};
