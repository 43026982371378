import React from "react";
import {
  faCertificate,
  faHardDrive,
  faMemory,
  faMicrochip,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DiskUsageSection from "./DiskUsageSection";
import { ProgressCard } from "components";
import { Card } from "react-bootstrap";
import Loader from "components/Loader";
import { convertToGB } from "utils/percentageVariant";

// Helper to parse disk usage
const parseDiskUsage = (diskUsageString = "") => {
  return diskUsageString.length > 0
    ? diskUsageString.split(";").map((partition) => {
        const [partitionName, size, free, usedRatio] = partition.split(",");
        return {
          partition: partitionName.split("=")[1],
          size: size.split("=")[1],
          free: free.split("=")[1],
          usedRatio: parseFloat(usedRatio.split("=")[1]),
        };
      })
    : [];
};

const SystemStats = ({ data, isLoading }) => {
  // Parse disk usage
  const diskUsageData = parseDiskUsage(data?.diskUsage);

  // Calculate memory usage percentage
  const totalMemory = convertToGB(data.memory);
  const freeMemory = convertToGB(data.memoryFree);
  const memoryUsedPercentage = ((totalMemory - freeMemory) / totalMemory) * 100;

  // Calculate CPU usage percentage
  const cpuLoadPercentage = (parseFloat(data.cpuLoad) / data.cpuCount) * 100;

  const licensePercentage =
    ((data.maxNumberOfAppliances - data.activatedAppliances) /
      data.maxNumberOfAppliances) *
    100;

  return (
    <div className="system-stats-container">
      {isLoading ? (
        <div className="py-4">
          <Loader />
          <center>
            <small>"Fetching system stats..."</small>
          </center>
        </div>
      ) : (
        <>
          <ProgressCard
            icon={<FontAwesomeIcon icon={faCertificate} />}
            title="License"
            subInfo="Appliances License Usage"
            data={[
              {
                label: (
                  <>
                    Activated :<strong>{data.activatedAppliances}</strong> |
                    Free :
                    <strong>
                      {data.maxNumberOfAppliances - data.activatedAppliances}
                    </strong>{" "}
                    | Total:
                    <strong>{data.maxNumberOfAppliances}</strong>
                  </>
                ),
                percentage: 100 - licensePercentage,
              },
            ]}
          />

          <ProgressCard
            icon={<FontAwesomeIcon icon={faMicrochip} />}
            title="CPU"
            subInfo="CPU information"
            data={[
              {
                label: (
                  <>
                    Cores: <strong>{data.cpuCount}</strong> | Load:{" "}
                    <strong>{data.cpuLoad}</strong>
                  </>
                ),
                percentage: cpuLoadPercentage,
              },
            ]}
          />

          {/* Memory Usage */}
          <ProgressCard
            icon={<FontAwesomeIcon icon={faMemory} />}
            title="Memory"
            subInfo="Memory information"
            data={[
              {
                label: (
                  <>
                    Total: <strong>{data.memory}</strong> | Free:{" "}
                    <strong>{data.memoryFree}</strong>
                  </>
                ),
                percentage: memoryUsedPercentage,
              },
            ]}
          />

          {/* Disk Usage */}
          <Card>
            <Card.Body>
              <h4>
                <FontAwesomeIcon icon={faHardDrive} className="mx-2" />
                <b>Disk</b>
              </h4>
              <small className="sub-info">Disk information</small>
              <DiskUsageSection diskUsageData={diskUsageData} />
            </Card.Body>
          </Card>
        </>
      )}
    </div>
  );
};

export default SystemStats;
