import React from "react";

const ShimmerTable = () => {
  const rows = [...Array(20)];
  const columns = 4;

  return (
    <div className="shimmer-table-container">
      {/* Table Header Shimmer */}
      <div className="shimmer-table-header d-flex justify-content-between">
        {[...Array(columns)].map((_, index) => (
          <div
            key={index}
            className="shimmer-text-header"
            style={{ width: "10%", marginRight: "40px" }}
          ></div>
        ))}
        {/* Additional smaller header column */}
        <div
          className="shimmer-text-header"
          style={{ width: "2%", height: "30px", marginRight: "10px" }}
        ></div>
      </div>

      {/* Table Rows Shimmer */}
      {rows.map((_, rowIndex) => (
        <React.Fragment key={rowIndex}>
          <div className="shimmer-table-row d-flex justify-content-between">
            {[...Array(columns)].map((_, colIndex) => (
              <div
                key={colIndex}
                className="shimmer-text"
                style={{ width: "10%", height: "15px", marginRight: "40px" }} 
              ></div>
            ))}
            {/* Additional smaller row column */}
            <div
              className="shimmer-text"
              style={{ width: "2%", height: "30px", marginRight: "10px" }} 
            ></div>
          </div>
          {/* Horizontal Line */}
          {rowIndex < rows.length - 1 && (
            <div
              style={{
                height: "1px",
                backgroundColor: "#e0e0e0",
                margin: "2px 0",
                width: "100%",
              }}
            ></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ShimmerTable;
