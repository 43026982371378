import { createAsyncThunk, current } from "@reduxjs/toolkit";
import API from "services/api";
import React from "react";
import { showAlert } from "stores/slices/alertSlice";
import { startLoading, apiError } from "../slices/apiSlice";
import MESSAGES from "constants/messages";

const fetchDashboard = createAsyncThunk(
  "dashboard/fetchDashboard",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/dashboard`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchCatSummary = createAsyncThunk(
  "dashboard/fetchCatSummary",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/dashboard/cat-summary`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const globalSearch = createAsyncThunk(
  "dashboard/globalSearch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(`/dashboard/search?query=${data}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const bulkUpload = (formData) => async (dispatch) => {
  try {
    const response = await API.post("/dashboard/bulkupload", formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const sendFeedback = (formData) => async (dispatch) => {
  try {
    const response = await API.post("/dashboard/feedback", formData);
    dispatch(
      showAlert({
        message: "Feedback sent successfully",
        variant: "success",
      })
    );
    return response;
  } catch (error) {
    dispatch(
      showAlert({
        message: "Failed to send feedback",
        variant: "danger",
      })
    );
    throw error;
  }
};

const fetchRegions = createAsyncThunk(
  "dashboard/fetchRegions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/region`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchVersion = createAsyncThunk(
  "dashboard/fetchVersion",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/dashboard/version`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchFeedbacks = createAsyncThunk(
  "dashboard/fetchFeedbacks",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/dashboard/feedback`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const updateFeedback = createAsyncThunk(
  "dashboard/updateFeedback",
  async ({ id, updateData }, { rejectWithValue, dispatch }) => {
    try {
      const endpoint = `/dashboard/feedback/${id}`;

      // Make the API request with the update data
      const response = await API.put(endpoint, updateData);

      // Determine the success message based on the fields updated
      if (updateData.currentStatus !== undefined) {
        const message =
          updateData.currentStatus === "completed"
            ? MESSAGES["feedback-completed-sc"]
            : MESSAGES["feedback-open-sc"];
        dispatch(
          showAlert({
            message,
            variant: "success",
          })
        );
      } else if (updateData.comments) {
        dispatch(
          showAlert({
            message: MESSAGES["comment-added-sc"],
            variant: "success",
          })
        );
      }

      // Return the updated feedback data
      return response.data;
    } catch (error) {
      // Log the error for debugging
      console.error("Error in updateFeedback:", error);

      // Return the error message via rejectWithValue for the rejected state
      return rejectWithValue(error.message);
    }
  }
);

export {
  fetchDashboard,
  fetchCatSummary,
  globalSearch,
  bulkUpload,
  sendFeedback,
  fetchRegions,
  fetchVersion,
  fetchFeedbacks,
  updateFeedback,
};
