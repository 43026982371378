import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHeadends, fetchHeadendsUnderMaintenance } from "stores/actions";
import { useNavigate } from "react-router-dom";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { usePermissions } from "hooks/usePermission";
import useActivityLogger from "hooks/useActivity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";

const HeadendList = ({ redirectToManage, isSearch = false }) => {
  const { headends, loading, underMaintenance } = useSelector(
    (state) => state.headend
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logActivity = useActivityLogger();

  useEffect(() => {
    if (!isSearch && headends.length === 0) {
      dispatch(fetchHeadends());
    }
    dispatch(fetchHeadendsUnderMaintenance());
  }, [dispatch, isSearch, headends.length]);

  const headendData = useMemo(() => {
    return headends.map((head) => {
      const maintenanceData = underMaintenance?.filter(
        (data) => data?.headend === head?._id
      );
      const heaend = {
        id: head?._id,
        name: `${head?.code}-${head?.name}`,
        // code: head?.code,
        infra: head?.infra?.name,
        category: head?.category?.name,
        fqdn: head?.fqdn,
        envType: head?.envType?.name,
        active: head?.active ? "Yes" : "No",
      };
      if (maintenanceData?.length > 0) {
        heaend["name"] = (
          <>
            <FontAwesomeIcon
              className="cl orange mx-2"
              icon={faScrewdriverWrench}
            />
            {heaend["name"]}
          </>
        );
      }
      return heaend;
    });
  }, [headends]);

  const onView = (item) => {
    logActivity({
      page: "dashboard",
      activity: "view-headend",
      name: item?.name,
    });
    navigate(`/headends/${item.id}`);
  };
  const onEdit = (item) => {
    redirectToManage("edit-headend", item);
  };

  return (
    <div className="mt-2">
      <PaginatedTable
        data={headendData}
        columns={COLUMNS["headend"]}
        perPage={20}
        onView={usePermissions(["HEADENDS_VIEW"]) && onView}
        onEdit={usePermissions(["HEADENDS_EDIT"]) && onEdit}
        isLoading={loading["fetchHeadends"]}
      />
    </div>
  );
};

export default HeadendList;
