import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Card,
  Alert,
  Badge,
  Accordion,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHeadendById,
  fetchHeadends,
  fetchHosts,
  saveMaintenance,
  updateMaintenance,
} from "stores/actions";
import { MultiSelectSearch } from "components";
import { notificationType } from "constants/mapping";
import capitalize from "utils/capitalize";
import TEMPLATES from "constants/templates";
import maintenanceTemplate from "./template";

const MaintenanceAddForm = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const { template, createdMaintenance } = useSelector(
    (state) => state.maintenance
  );
  const [formState, setFormState] = useState({
    headendType: "",
    selectedHeadend: "",
    selectedDevices: [],
    headendList: [],
  });

  const [formData, setFormData] = useState({
    headend: "",
    name: "",
    notificationType: "",
    notification: "",
    devices: [],
    maintenanceTime: {
      to: "",
      from: "",
    },
    release: {
      to: "",
      from: "",
    },
    serviceImpact: "",
    ticket: "NA",
    issueDetails: "",
    impactDetails: "",
    impactedDevices: "",
    activityDescription: "",
    currentStatus: "PLANNED",
    user: "",
    reason: "",
  });

  const { headends, headend } = useSelector((state) => state.headend);
  const { categories } = useSelector((state) => state.categories);

  useEffect(() => {
    if (headends?.length === 0) dispatch(fetchHeadends());
  }, [dispatch, headends]);

  useEffect(() => {
    if (formState?.selectedHeadend)
      dispatch(fetchHeadendById(formState?.selectedHeadend));
  }, [formState?.selectedHeadend]);

  const handleStateChange = (field, value) => {
    setFormState((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleFormDataChange = (path, value) => {
    setFormData((prevState) => {
      const keys = path.split(".");
      const newData = { ...prevState }; // Create a shallow copy of the formData object
      let temp = newData;

      // Traverse the object to the desired key
      for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        temp[key] = { ...temp[key] }; // Create a shallow copy of each level
        temp = temp[key];
      }

      // Set the value at the last key
      temp[keys[keys.length - 1]] = value;

      return newData;
    });
  };

  const handleTypeChange = (value) => {
    const filteredHeadends = headends?.filter(
      (headend) => headend.category.name === value
    );
    setFormState((prevState) => ({
      ...prevState,
      headendType: value,
      headendList: filteredHeadends,
      selectedHeadend: "",
      selectedDevices: [],
    }));
  };

  const devices = useMemo(() => {
    const { notification, notificationType } = formData;
    const isHeadend = notificationType === "Headend";
    handleStateChange("selectedDevices", []);
    if (notification === "alert" || isHeadend) {
      const directors = headend.directors.map((d) => {
        return { ...d.host };
      });
      const controllers = headend.controllers.map((d) => {
        return { ...d.host };
      });
      const analytics = headend?.analytics.flatMap((host) => host?.nodes || []);

      const results = [...directors, ...controllers, ...analytics];
      if (isHeadend)
        handleStateChange(
          "selectedDevices",
          results.map((res) => {
            return { value: res._id, label: res.name };
          })
        );
      return results;
    }
    const data = headend[notificationType.toLowerCase()] || [];
    if (notificationType === "Analytics") {
      return data.flatMap((host) => host?.nodes || []);
    }
    return data.map((d) => {
      return { ...d.host };
    });
  }, [
    formState.selectedHeadend,
    formData.notificationType,
    formData.notification,
    headend,
  ]);

  const renderMaintenanceFields = () => {
    const { notification } = formData;

    if (notification === "upgrade") {
      return (
        <>
          <Form.Group as={Row} controlId="" className="mt-3">
            <Form.Label column sm={3}>
              From Release
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                value={formData.release.from}
                placeholder="Enter From Release"
                onChange={(e) =>
                  handleFormDataChange("release.from", e.target.value)
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="" className="mt-3">
            <Form.Label column sm={3}>
              To Release
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                value={formData.release.to}
                placeholder="Enter To Release"
                onChange={(e) =>
                  handleFormDataChange("release.to", e.target.value)
                }
              />
            </Col>
          </Form.Group>
        </>
      );
    }

    if (notification === "alert") {
      return (
        <>
          <Form.Group as={Row} controlId="" className="mt-3">
            <Form.Label column sm={3}>
              Issue Details*
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                as="textarea"
                rows={3}
                value={formData.issueDetails}
                placeholder="Enter Issue Details"
                onChange={(e) =>
                  handleFormDataChange("issueDetails", e.target.value)
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="" className="mt-3">
            <Form.Label column sm={3}>
              Impact Details*
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                as="textarea"
                rows={3}
                value={formData.impactDetails}
                placeholder="Enter Impact Details"
                onChange={(e) =>
                  handleFormDataChange("impactDetails", e.target.value)
                }
              />
            </Col>
          </Form.Group>
        </>
      );
    }

    if (["emergency", "general"].includes(notification)) {
      return (
        <>
          <Form.Group as={Row} controlId="" className="mt-3">
            <Form.Label column sm={3}>
              Activity Description*
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                as="textarea"
                rows={3}
                value={formData.activityDescription}
                placeholder="Enter Activity Description"
                onChange={(e) =>
                  handleFormDataChange("activityDescription", e.target.value)
                }
              />
            </Col>
          </Form.Group>
        </>
      );
    }

    return null;
  };

  const handleFormSubmit = async () => {
    const { maintenanceTime } = formData;
    const now = new Date();
    const fromDate = new Date(maintenanceTime.from);
    const toDate = new Date(maintenanceTime.to);

    if (!maintenanceTime.from || !maintenanceTime.to) {
      alert("Please select both 'From' and 'To' dates and times.");
      return;
    }

    if (fromDate < now) {
      alert("'From' date and time cannot be in the past.");
      return;
    }

    if (toDate <= fromDate) {
      alert("'To' date and time should be later than 'From' date and time.");
      return;
    }

    // Proceed with form submission
    const user = JSON.parse(localStorage.getItem("user"))?._id;
    const status =
      formData.notification === "emergency"
        ? "EMERGENCY"
        : formData.notification === "alert"
        ? "ADHOC"
        : "PLANNED";

    const submissionData = {
      ...formData,
      devices: formState.selectedDevices.map((gateway) => gateway.value),
      headend: formState.selectedHeadend,
      user,
      currentStatus: status,
      history: [
        {
          status,
          message: `${capitalize(status)} ${formData.notification} `,
          user,
        },
      ],
    };
    await dispatch(saveMaintenance(submissionData));

    console.log("Submitting Data:", submissionData);
  };

  const sendEmail = async () => {
    const user = JSON.parse(localStorage.getItem("user"))?._id;
    const data = {
      ...createdMaintenance,
      currentStatus: `${createdMaintenance?.currentStatus}-EMAIL`,
      history: [
        {
          status: `${createdMaintenance?.currentStatus}-EMAIL`,
          message: `Initiated mail to notify the customer on the ${createdMaintenance?.currentStatus}`,
          template,
          user,
        },
        ...(createdMaintenance?.history || []),
      ],
    };
    await dispatch(
      updateMaintenance({
        data,
        template,
      })
    );
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Notification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {createdMaintenance?._id && template?.length > 0 ? (
          <>
            <Alert key={"success"} variant={"success"}>
              Maintenance has been created, verify the email and notify the
              customer
            </Alert>
            <Accordion defaultActiveKey={0} className="">
              {/* WAN IPs Section */}
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  To : ({createdMaintenance?.contacts?.to?.length})
                </Accordion.Header>
                <Accordion.Body>
                  {createdMaintenance?.contacts?.to?.map((to) => (
                    <Badge>{to}</Badge>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Cc : ({createdMaintenance?.contacts?.cc?.length})
                </Accordion.Header>
                <Accordion.Body>
                  {createdMaintenance?.contacts?.cc?.map((cc) => (
                    <Badge>{cc}</Badge>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Bcc : ({createdMaintenance?.contacts?.bcc?.length})
                </Accordion.Header>
                <Accordion.Body>
                  {createdMaintenance?.contacts?.bcc?.map((bcc) => (
                    <Badge>{bcc}</Badge>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Card className="mt-3">
              <Card.Body>
                <div
                  dangerouslySetInnerHTML={{
                    __html: maintenanceTemplate(template),
                  }}
                />
              </Card.Body>
            </Card>
          </>
        ) : (
          <Form>
            <Form.Group as={Row} controlId="category" className="mt-3">
              <Form.Label column sm={3}>
                Headend Categories*
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  value={formState.headendType}
                  onChange={(e) => handleTypeChange(e.target.value)}
                >
                  <option value="">Select</option>
                  {categories?.headend?.map(({ name }) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="" className="mt-3">
              <Form.Label column sm={3}>
                Headend Name*
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  value={formState.selectedHeadend}
                  onChange={(e) =>
                    handleStateChange("selectedHeadend", e.target.value)
                  }
                >
                  <option value="">Select</option>
                  {formState.headendList?.map(({ code, name, _id }) => (
                    <option key={_id} value={_id}>
                      {code}-{name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="" className="mt-3">
              <Form.Label column sm={3}>
                Select Maintenance*
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  value={formData.notification}
                  onChange={(e) =>
                    handleFormDataChange("notification", e.target.value)
                  }
                >
                  <option value="">Select</option>
                  <option value="upgrade">Upgrade</option>
                  <option value="alert">Alert</option>
                  <option value="emergency">Emergency Maintenance</option>
                  <option value="general">General Maintenance</option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="" className="mt-3">
              <Form.Label column sm={3}>
                Maintenance Type*
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  value={formData.notificationType}
                  onChange={(e) =>
                    handleFormDataChange("notificationType", e.target.value)
                  }
                >
                  <option value="">Select</option>
                  {notificationType[formData.notification]?.map(({ name }) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Row className="mt-3">
              <Col sm={3} className="d-flex align-items-center">
                Select Date and Time in IST
              </Col>
              <Col>
                <Form.Group as={Row} controlId="" className="">
                  <Form.Label column sm={3}>
                    From*
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="datetime-local"
                      controlId="fromDate"
                      value={formData.maintenanceTime.from}
                      min={new Date().toISOString().slice(0, 16)} // Disable past date & time
                      onChange={(e) =>
                        handleFormDataChange(
                          "maintenanceTime.from",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Row} controlId="" className="">
                  <Form.Label column sm={3}>
                    To*
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="datetime-local"
                      controlId="toDate"
                      value={formData.maintenanceTime.to}
                      min={
                        formData.maintenanceTime.from
                          ? new Date(formData.maintenanceTime.from)
                              .toISOString()
                              .slice(0, 16) // Disable time before "From" time
                          : new Date().toISOString().slice(0, 16) // Default to current date & time
                      }
                      onChange={(e) =>
                        handleFormDataChange(
                          "maintenanceTime.to",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group as={Row} controlId="gateways" className="mt-3">
              <Form.Label column sm={3}>
                Devices
              </Form.Label>
              <Col sm={9}>
                <MultiSelectSearch
                  options={devices}
                  isMulti={true}
                  selectedOption={formState.selectedDevices}
                  onChange={(data) =>
                    handleStateChange("selectedDevices", data)
                  }
                />
              </Col>
            </Form.Group>
            {renderMaintenanceFields()}
            <Form.Group as={Row} controlId="" className="mt-3">
              <Form.Label column sm={3}>
                Service Impact
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  value={formData.serviceImpact}
                  onChange={(e) =>
                    handleFormDataChange("serviceImpact", e.target.value)
                  }
                >
                  <option value="">Select</option>
                  <option value="No Service Impact">No Service Impact</option>
                  <option value="Service Impacting">Service Impacting</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="" className="mt-3">
              <Form.Label column sm={3}>
                Ticket Number
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Enter Ticket Number"
                  value={formData.ticket}
                  onChange={(e) =>
                    handleFormDataChange("ticket", e.target.value)
                  }
                />
              </Col>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {createdMaintenance?._id ? (
          <Button variant="primary" onClick={sendEmail}>
            Send Mail
          </Button>
        ) : (
          <Button variant="primary" onClick={handleFormSubmit}>
            Submit
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default MaintenanceAddForm;
