import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Link } = require("react-router-dom");

const RedirectTo = ({ path = "/dashboard", label = "Dashboard" }) => {
  return (
    <Link to={path} className="link">
      {label} <FontAwesomeIcon icon={faArrowRight} />
    </Link>
  );
};

export default RedirectTo;
