import { useMemo, useState, useCallback } from "react";
import { Card, Tabs, Tab, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";
import HostForm from "pages/Host/AddForm";
import { Orchestrators } from "stores/actions";
import HostView from "pages/Host/HostView";
import { usePermissions } from "hooks/usePermission";
import { useNavigate } from "react-router-dom";
import useActivityLogger from "hooks/useActivity";

const DetailedView = ({ headends, tenants, users, subscriptions }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logActivity = useActivityLogger();
  const { orchestrator } = useSelector((state) => state.orchestrators);
  const [key, setKey] = useState("tenants");
  const [hostToEdit, setHostToEdit] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedHost, setSelectedHost] = useState(null);
  const [showHostModal, setShowHostModal] = useState(false);

  const findMatchingHost = useCallback((nodes, itemId) => {
    if (!Array.isArray(nodes)) return null;
    for (const node of nodes) {
      const matchingHost = node?.hosts?.find((host) => host._id === itemId);
      if (matchingHost) return { nodeId: node._id, host: matchingHost };
    }
    return null;
  }, []);

  const onEdit = useCallback(
    (item) => {
      const result = findMatchingHost(orchestrator?.nodes, item.host._id);
      if (!result) {
        console.error("No matching host found");
        return;
      }
      setHostToEdit(result.host);
      setShowModal(true);
    },
    [orchestrator?.nodes, findMatchingHost]
  );

  const onSaveHost = useCallback(async () => {
    if (!orchestrator) return;

    try {
      await dispatch(Orchestrators.fetchOrchestratorsById(orchestrator._id));
      setShowModal(false); // Close the modal after successful save
    } catch (error) {
      console.error("Failed to refetch orchestrator:", error);
      // Handle errors (e.g., show an alert or notification)
    }
  }, [dispatch, orchestrator]);

  const onView = (item) => {
    // Use findMatchingHost to locate the host
    const selectedHost = findMatchingHost(orchestrator?.nodes, item.host._id);
    console.log(selectedHost);

    // Set the selected host and open the modal
    if (selectedHost) {
      setSelectedHost(selectedHost);
      setShowHostModal(true);
    } else {
      console.error("Host not found");
    }
  };
  const nodes = useMemo(
    () => orchestrator?.nodes?.flatMap((node) => node?.hosts || []) || [],
    [orchestrator?.nodes]
  );

  const onHeadendView = (item) => {
    logActivity({
      page: "dashboard",
      activity: "view-headend",
      name: item?.name,
    });
    navigate(`/headends/${item.id}`);
  };

  const renderTabContent = (title, data, columns, action = null) => (
    <>
      <div className="mb-3">
        <h5>
          <b>{title}</b>
        </h5>
        <small className="sub-info">{`Lists all ${title.toLowerCase()} managed by the orchestrator`}</small>
      </div>
      {data?.length > 0 ? (
        <PaginatedTable
          data={data}
          columns={COLUMNS[columns]}
          itemsPerPage={10}
          onView={action}
        />
      ) : (
        <center>No {title} found</center>
      )}
    </>
  );

  return (
    <>
      <Card>
        <Card.Body>
          <Tabs
            id="controlled-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="p-0"
          >
            <Tab eventKey="tenants" title="Tenants" className="p-3">
              {renderTabContent("Tenants", tenants, "tenants")}
            </Tab>
            <Tab eventKey="headends" title="Headends" className="p-3">
              {renderTabContent("Headends", headends, "headend", onHeadendView)}
            </Tab>
            {users.length > 0 && (
              <Tab eventKey="users" title="Users" className="p-3">
                {renderTabContent("Users", users, "orchUsers")}
              </Tab>
            )}
            {subscriptions.length > 0 && (
              <Tab
                eventKey="subscription"
                title="Subscriptions"
                className="p-3"
              >
                {renderTabContent(
                  "Subscriptions",
                  subscriptions,
                  "subscriptions"
                )}
              </Tab>
            )}
            <Tab eventKey="nodes" title="Nodes" className="p-3">
              <div className="mb-3">
                <h5>
                  <b>Nodes</b>
                </h5>
                <small className="sub-info">
                  List of all nodes under the Orchestrator
                </small>
              </div>
              <PaginatedTable
                data={nodes.map((host) => ({
                  ...host,
                  name: host.name,
                  fqdn: host.fqdn,
                  region: host?.region?.name,
                  host,
                }))}
                columns={COLUMNS["orchestratorHost"]}
                perPage={25}
                paginationRequired={false}
                searchRequired={false}
                filterRequired={false}
                onEdit={usePermissions(["WRAPPERS_EDIT"]) && onEdit}
                onView={onView}
              />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>

      {showModal && (
        <HostForm
          host={hostToEdit}
          showModal={showModal}
          closeModal={() => setShowModal(false)}
          onSave={onSaveHost}
          updateHostDetails={onSaveHost}
          isEdit={!!hostToEdit?._id}
        />
      )}

      {/* HostView Component in Modal */}

      {showHostModal && selectedHost && (
        <Modal
          show={showHostModal}
          onHide={() => setShowHostModal(false)}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>{`Host Details: ${selectedHost?.host?.name}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <HostView host={selectedHost?.host} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default DetailedView;
