import React from "react";

// Generic Shimmer component
const ShimmerMaps = ({ height = "100%", width = "100%", style = {} }) => (
  <div
    style={{
      height,
      width,
      backgroundColor: "#f0f0f0",
      position: "relative",
      ...style,
    }}
  >
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        background:
          "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
        backgroundSize: "200% 100%",
        animation: "shimmer 1.5s infinite",
      }}
    ></div>
    <style>
      {`
      @keyframes shimmer {
        0% { background-position: -200% 0; }
        100% { background-position: 200% 0; }
      }
      `}
    </style>
  </div>
);

export default ShimmerMaps;
