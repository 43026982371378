import React from "react";
import { Card, Row, Col, ListGroup, Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
// import CustomPieChart from "./Charts/CustomPieChart";
import Loader from "./Loader";
import ShimmerBoxes from "./Shimmer/ShimmerBoxes";
import CustomPieChart from "./Charts/CustomPieChart1";

const MonitorAuditSummary = () => {
  const { monitoringSummary, loading } = useSelector((state) => state.audit);
  const { loading: dashboardLoading } = useSelector((state) => state.dashboard);
  const createPieChartData = () => {
    return [
      {
        name: "Not In Sync",
        value: monitoringSummary.critical,
        color: "#ff3f3f",
      },
      // { name: "Mismatch", value: monitoringSummary.mismatch, key: "mismatch" },
      { name: "In Sync", value: monitoringSummary.enabled, color: "#198754" },
    ];
  };
  if (dashboardLoading?.dashboard) {
    return <ShimmerBoxes />;
  }
  return (
    <>
      {/* will reuse it later */}
      {/* <Row className="mt-0 p-0">
        <Col sm={4}>
          <Card className={`box red bg text-center`} style={{}}>
            <Card.Body className="px-0">
              <h2 className="m-0">{monitoringSummary?.critical}</h2>
            </Card.Body>
            <Card.Footer className="px-0">Critical</Card.Footer>
          </Card>
        </Col>
        <Col sm={4}>
          <Card className={`box orange bg text-center`} style={{}}>
            <Card.Body className="px-0">
              <h2 className="m-0">{monitoringSummary?.mismatch}</h2>
            </Card.Body>
            <Card.Footer className="px-0">Mismatch</Card.Footer>
          </Card>
        </Col>
        <Col sm={4}>
          <Card className={`box green bg text-center`} style={{}}>
            <Card.Body className="px-0">
              <h2 className="m-0">{monitoringSummary?.enabled}</h2>
            </Card.Body>
            <Card.Footer>Ok</Card.Footer>
          </Card>
        </Col>
      </Row> */}
      <CustomPieChart data={createPieChartData()} />
      {/* <CustomPieChart data={createPieChartData()} /> */}
      <ListGroup variant="flush">
        <ListGroup.Item
          className="mb-1 hover-item"
          style={{ cursor: "pointer" }}
        >
          <span className="link" style={{ textTransform: "capitalize" }}>
            <b>Not In Sync</b>
          </span>

          <b style={{ fontSize: "0.9rem" }}>
            {loading["monitoringReport"] ? (
              <Loader />
            ) : (
              <Badge bg="danger">{monitoringSummary?.critical}</Badge>
            )}
          </b>
        </ListGroup.Item>
        {/* <ListGroup.Item
          className="mb-1 hover-item"
          style={{ cursor: "pointer" }}
          // onClick={() => sendLog(fieldText)}
        >
          <span className="link" style={{ textTransform: "capitalize" }}>
            <b>Mismatch</b>
          </span>

          <b style={{ fontSize: "0.9rem" }}>
            {loading["monitoringReport"] ? (
              <Loader />
            ) : (
              <Badge bg="warning">{monitoringSummary?.mismatch}</Badge>
            )}
          </b>
        </ListGroup.Item> */}
        <ListGroup.Item
          className="mb-1 hover-item"
          style={{ cursor: "pointer" }}
          // onClick={() => sendLog(fieldText)}
        >
          <span className="link" style={{ textTransform: "capitalize" }}>
            <b>In Sync</b>
          </span>

          <b style={{ fontSize: "0.9rem" }}>
            {loading["monitoringReport"] ? (
              <Loader />
            ) : (
              <Badge bg="success">{monitoringSummary?.enabled}</Badge>
            )}
          </b>
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};

export default MonitorAuditSummary;
