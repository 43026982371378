import React, { useState, useEffect, useMemo } from "react";
import { Button, Row, Col } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { Profile } from "stores/actions";
import BackupProfileAddForm from "../components/AddForm";
import { PaginatedTable } from "components";
import { usePermissions } from "hooks/usePermission";
import COLUMNS from "constants/columns";

const BackupProfileTab = () => {
  const hasAddPermission = usePermissions(["BACKUP_PROFILE_ADD"]);
  const hasEditPermission = usePermissions(["BACKUP_PROFILE_EDIT"]);
  const { profileItems } = useSelector((state) => state.profiles);
  const [showModal, setShowModal] = useState(false);
  const [currentProfile, setCurrentProfile] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Profile.fetchProfileItems("backup"));
  }, []);

  useEffect(() => {
    if (profileItems.length > 0) {
      setSelectedProfile(profileItems[0]);
    }
  }, [profileItems]);

  const handleClone = async (profile) => {
    let name = `${profile.name}_cloned`;
    const profileNames = profileItems.filter((prof) =>
      prof.name.includes(name)
    );
    const data = {
      name:
        profileNames.length > 0 ? `${name}_${profileNames.length + 1}` : name,
      type: "backup",
      details: profile.details,
    };
    const clonedData = await dispatch(Profile.createProfileItem(data));
    setCurrentProfile(clonedData?.payload);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    await dispatch(Profile.deleteProfileItem(id));
  };

  const onView = (profile) => {
    setSelectedProfile(profile);
    // setShowModal(true);
  };

  const onEdit = (profile) => {
    setCurrentProfile(profile);
    setShowModal(true);
  };

  const profileData = useMemo(() => {
    const data = profileItems.map((profile) => ({
      ...profile,
      ...profile?.details,
      isEncryption: profile?.details?.isEncryption ? "enabled" : "-",
      jumpServerRequired: profile?.details?.jumpServerRequired
        ? "enabled"
        : "-",
      s3Upload: profile?.details?.s3Upload ? "enabled" : "-",
    }));

    return data;
  }, [profileItems]);

  return (
    <div>
      <Row className="mb-3">
        <Col>
          <div className="my-2">
            <h5>
              <b>Backup Profiles</b>
            </h5>
            <small className="sub-info">
              All backup related profiles are available here
            </small>
          </div>
        </Col>
        <Col className="col-auto">
          {hasAddPermission && (
            <Button onClick={() => setShowModal(true)}>Add New Profile</Button>
          )}
        </Col>
      </Row>

      {/* <Row>
        <Col md={3}>
          <PaginatedTable
            data={profileItems}
            columns={[{ dataField: "name", text: "Name" }]}
            perPage={25}
            onView={onView}
          />
        </Col>
        <Col>
          <BackupProfileView
            profile={selectedProfile}
            onEdit={hasEditPermission ? onEdit : null}
            onClone={handleClone}
          />
        </Col>
      </Row> */}

      <PaginatedTable
        data={profileData}
        columns={COLUMNS["backupProfiles"]}
        perPage={25}
        // onView={onView}
        onEdit={hasEditPermission ? onEdit : null}
        onClone={hasAddPermission ? handleClone : null}
      />

      <BackupProfileAddForm
        show={showModal}
        onClose={() => setShowModal(false)}
        currentProfile={currentProfile}
      />
    </div>
  );
};

export default BackupProfileTab;
