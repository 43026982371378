import Highlight from "components/HighlightText";

import { useMemo, useState } from "react";
import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";
import { useNavigate } from "react-router-dom";
const { Accordion, Badge } = require("react-bootstrap");

const TenantResult = ({ tenants, sendLog, query }) => {
  const navigate = useNavigate();

  const redirectToOrg = (id, name) => {
    sendLog("redirect", name);
    navigate(`/tenants/${id}`);
  };
  const tenantsData = useMemo(() => {
    return tenants.map((org) => {
      return {
        ...org,
        id: org._id,
        name: (
          <div
            className="link"
            onClick={() => redirectToOrg(org?._id, org?.name)}
          >
            <Highlight text={org?.name} highlight={query} />
          </div>
        ),
        parentOrg: org?.parentOrg || "",
        contacts: org?.contacts?.length || "",
        headends: org?.headends
          ?.map((headend) => headend?.code || headend?.name)
          .join(", "),
      };
    });
  }, [tenants]);

  return (
    <Accordion.Item eventKey="tenants">
      <Accordion.Header>
        Tenants :
        <Badge pill bg="info">
          {tenants?.length}
        </Badge>
      </Accordion.Header>
      <Accordion.Body>
        <PaginatedTable
          data={tenantsData}
          columns={COLUMNS["orgs"]}
          perPage={10}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default TenantResult;
