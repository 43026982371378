import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const WebsitePieChart = () => {
  const [data, setData] = useState([]);
  const { aiWebsites } = useSelector((state) => state.security);

  const websiteData = useMemo(() => {
    const groupedData = aiWebsites.reduce((acc, item) => {
      const { website, _value } = item; // Assuming `website` and `_value` are returned by the query
      if (!acc[website]) {
        acc[website] = { name: website, value: 0 }; // Recharts expects 'name' and 'value' keys
      }
      acc[website].value += _value;
      return acc;
    }, {});

    // Convert grouped data object into an array
    return Object.values(groupedData);
  }, [aiWebsites]);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AF19FF"];

  return (
    <div>
      <ResponsiveContainer width="100%" height={600}>
        <PieChart>
          <Pie
            data={websiteData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={150}
            fill="#8884d8"
            label={(entry) => `${entry.name}: ${entry.value}`} // Display website name and value
          >
            {websiteData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default WebsitePieChart;
