// pages/Unauthorized.js
import { RedirectTo } from "components";
import { useAuth } from "contexts";
import useActivityLogger from "hooks/useActivity";
import React, { useEffect } from "react";
import { Card } from "react-bootstrap";

const DefaultPage = ({ title, description, page, activity }) => {
  const logActivity = useActivityLogger();
  const { user } = useAuth();

  useEffect(() => {
    logActivity({
      page,
      activity,
    });
  }, []);

  return (
    <div
      className="bg-image"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card className="p-4 w-50">
        <Card.Body>
          <h1>{title}</h1>
          <p className="mb-5">{description}.</p>
          {user ? <RedirectTo /> : <RedirectTo path="/login" label="Login" />}
        </Card.Body>
      </Card>
    </div>
  );
};

export default DefaultPage;
