import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Card, Col, Row, Tabs } from "react-bootstrap";
import { useBreadcrumbs } from "contexts";
import { useSelector } from "react-redux";
import useActivityLogger from "hooks/useActivity";
import GatewayList from "./List";
import { Tab } from "bootstrap";
import GatewayMap from "components/Map/gatewayMap";

const Gateways = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  const { hosts } = useSelector((state) => state.host);
  const [gatewayType, setGatewayType] = useState("SHARED");
  const logActivity = useActivityLogger();

  // Memoize the sendLog function to prevent it from being recreated on every render
  const sendLog = useCallback(
    (page, activity, name) => {
      logActivity({
        page,
        activity,
        name,
      });
    },
    [logActivity]
  );

  // Disable exhaustive-deps to ensure this effect only runs on mount
  useEffect(() => {
    sendLog("gateway", "page-load", "Gateway");
    updateBreadcrumbs([{ text: "Home", url: "/dashboard" }]);
  }, []);

  const gateways = useMemo(() => {
    if (hosts?.length === 0 || !hosts[0]?.type) return [];

    const { gateways } = hosts?.filter((data) => data.type === gatewayType)[0];

    return (
      gateways?.map(({ host }) => {
        return {
          lat: host?.applianceLocation?.latitude || host?.coordinates?.lat,
          lng: host?.applianceLocation?.longitude || host?.coordinates?.lng,
          devices: [
            {
              gatewayName: host?.name,
              country: "",
              location: host?.location,
              serialNumber: host?.license.serialNumber,
              softwareVersion: host?.softwareVersion,
            },
          ],
        };
      }) || []
    );
  }, [hosts, gatewayType]);

  return (
    <>
      <div className="middle-section">
        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body>
                <div className="mb-3">
                  <h5>
                    <b>Gateways</b>
                  </h5>
                  <small className="sub-info">Gateway Summary</small>
                </div>
                <GatewayMap groupedDeviceData={gateways} />
              </Card.Body>
            </Card>
          </Col>

          <Col sm={12}>
            <Card className="my-2">
              <Card.Body>
                <Tabs
                  defaultActiveKey="summary"
                  id="gateway-tabs"
                  className="my-3"
                >
                  <Tab eventKey="summary" title={`Summary`}>
                    <GatewayList sendLog={sendLog} />
                  </Tab>
                  <Tab eventKey="monitoring" title={`Monitoring`}></Tab>
                  <Tab eventKey="capacity" title={`Capacity Planning`}></Tab>
                  <Tab eventKey="certificate" title={`Certificates`}></Tab>
                </Tabs>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(Gateways);
