import React, { useEffect, useMemo } from "react";
import { Card, Row, Col, ListGroup, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import DetailedView from "./DetailedView";
import { Orchestrators } from "stores/actions";
import capitalize from "utils/capitalize";
import BackButton from "components/BackButton";
import { usePermissions } from "hooks/usePermission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useActivityLogger from "hooks/useActivity";
import Loader from "components/Loader";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";

const OrchestratorsDetails = () => {
  const dispatch = useDispatch();
  const logActivity = useActivityLogger();
  const { id } = useParams();
  const { orchestrator, loading } = useSelector((state) => state.orchestrators);
  const canRefreshOrchestrator = usePermissions(["WRAPPERS_SYNC"]);

  const licenseMap = {
    VSA: "VSPA",
    SWG: "VSIA",
    VSA_AND_SWG: "VSIA and VSPA",
  };

  const sendLog = (activity, name) => {
    logActivity({
      page: "Orchestrators-details",
      activity,
      name,
    });
  };

  useEffect(() => {
    dispatch(Orchestrators.fetchOrchestratorsById(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (orchestrator?.name) sendLog("page-load", orchestrator?.name);
  }, [orchestrator]);

  const tenants = useMemo(() => {
    return (
      orchestrator?.headends?.flatMap((head) =>
        head?.organisations?.map((org) => ({
          id: org._id,
          name: org.name,
          parentOrg: org.parentOrg,
          globalOrgId: org.globalOrgId,
          headend: `${head?.code ?? ""}-${head?.name ?? ""}`,
        }))
      ) || []
    );
  }, [orchestrator?.headends]);

  const users = useMemo(() => {
    return (
      orchestrator?.users?.map(
        ({ username, name, email, twoFactor, tenant }) => ({
          username,
          name,
          email,
          twoFactor: twoFactor ? "Enabled" : "",
          tenant,
        })
      ) || []
    );
  }, [orchestrator?.users]);

  const headendData = useMemo(() => {
    return (
      orchestrator?.headends?.map((head) => ({
        id: head._id,
        name: head.name,
        code: head.code,
        infra: head.infra?.name,
        category: head.category?.name,
        fqdn: head.fqdn,
        active: head.active ? "Yes" : "No",
      })) || []
    );
  }, [orchestrator?.headends]);

  const subscriptions = useMemo(() => {
    return (
      orchestrator?.subscriptions?.map((sub) => ({
        name: sub.name,
        parent: sub.parentTenant,
        license: (sub.licenseDataList || [])
          .map((license) => licenseMap[license.type])
          .join(" , "),
      })) || []
    );
  }, [orchestrator?.subscriptions]);

  const renderCard = (title, count, color) => (
    <Col className="mb-3" sm={12} md={4}>
      <Card className={`box ${color} bg`}>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <h2>{count}</h2>
          <Card.Text>Total</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );

  const renderPackageInfo = () => {
    const { packageInfo } = orchestrator || {};
    if (!packageInfo) {
      return <p className="text-center m-3">No Package information found</p>;
    }

    return (
      <ListGroup variant="flush">
        {Object.entries(packageInfo).map(([key, value]) => (
          <ListGroup.Item key={key}>
            <span>{capitalize(key)}</span>
            <b>{value}</b>
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  };

  const triggerRefresh = () => {
    sendLog("orchestrator-refresh-click", orchestrator?.name);
    dispatch(Orchestrators.refreshConcerto(orchestrator?._id));
  };

  return (
    <div className="middle-section">
      <Row>
        <Col>
          <BackButton title="Orchestrators" />
          <div className="my-2">
            <h5>
              <b>Orchestrator Details</b>
            </h5>
            <small className="sub-info">
              Detailed view of the orchestrator
            </small>
          </div>
        </Col>
        <Col className="col-auto">
          {canRefreshOrchestrator &&
            orchestrator?.category?.name === "concerto" && (
              <Col className="col-auto">
                <Button onClick={triggerRefresh} className="add-btn">
                  {loading["refresh"] ? (
                    <Loader />
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faRefresh} /> Refresh
                    </>
                  )}
                </Button>
              </Col>
            )}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={8}>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col>
                  <small>NAME</small>
                  <h6>
                    <b>{orchestrator?.name}</b>
                  </h6>
                </Col>
                <Col>
                  <small>CATEGORY</small>
                  <h6>
                    <b>{orchestrator?.category?.name || "---"}</b>
                  </h6>
                </Col>
                <Col>
                  <small>FQDN</small>
                  <h6>
                    <b>
                      {orchestrator?.fqdn ? (
                        <a
                          href={orchestrator.fqdn}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link"
                        >
                          {orchestrator.fqdn}
                        </a>
                      ) : (
                        orchestrator?.nodes?.length > 0 &&
                        orchestrator.nodes[0]?.fqdn && (
                          <a
                            href={orchestrator.nodes[0].fqdn}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link"
                          >
                            {orchestrator.nodes[0].fqdn}
                          </a>
                        )
                      )}
                    </b>
                  </h6>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <DetailedView
            headends={headendData}
            tenants={tenants}
            users={users}
            subscriptions={subscriptions}
          />
        </Col>
        <Col>
          <Row>
            {renderCard("Tenants", tenants.length, "blue")}
            {renderCard("Headends", headendData.length, "red")}
            {renderCard("Users", users.length, "orange")}
          </Row>
          <div className="my-2">
            <h5>
              <b>Package Information</b>
            </h5>
            <small className="sub-info">
              Detailed view of the orchestrator's packages
            </small>
          </div>
          <Card className="mt-3">
            <Card.Body>{renderPackageInfo()}</Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default OrchestratorsDetails;
