import axios from "axios";
import userConfigDetails from "utils/userConfig";
import { login, silentRenew } from "./auth";

const API_SERVER = `${window?.appConfig?.REACT_APP_API_SERVER}/api`;

// Create an instance of axios
const API = axios.create({
  baseURL: API_SERVER,
});

// Function to delete all cookies
const deleteAllCookies = () => {
  const cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  }
};

// Flag to track if redirect has already occurred
let hasRedirected = false;

// Helper to get the OIDC key from localStorage
const getOidcUserKey = () => {
  return `oidc.user:${userConfigDetails.authority}:${userConfigDetails.client_id}`;
};

// Request interceptor
API.interceptors.request.use(
  (config) => {
    // Add authorization token or other headers
    const oidcKey = getOidcUserKey();
    const userDetails = JSON.parse(localStorage.getItem(oidcKey));

    if (userDetails && userDetails?.access_token) {
      config.headers.Authorization = `Bearer ${userDetails.access_token}`;
      config.headers.User = userDetails?.profile?.email;
    }

    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      if (!hasRedirected) {
        hasRedirected = true;

        console.warn("Unauthorized - Attempting silent renew.");

        try {
          // Attempt silent renew
          localStorage.setItem("currentUrl", window.location.href);
          await silentRenew();

          console.log("Silent renew successful, retrying the failed request.");
          hasRedirected = false; // Reset redirect flag if silent renew succeeded

          // Retry the original request
          // return API.request(error.config);
        } catch (silentRenewError) {
          console.error("Silent renew failed, redirecting to login.");
          // If silent renew fails, redirect to login
          login();
        }
      }
    }
    return Promise.reject(error);
  }
);

export default API;
