import { Tab } from "bootstrap";
import { Card, Tabs } from "react-bootstrap";
import PaginatedTable from "./PaginatedTable";
import COLUMNS from "constants/columns";
import { useCallback } from "react";
import { usePermissions } from "hooks/usePermission";
import { useSelector } from "react-redux";

const GatewayTab = ({ categorised, onView, onEdit }) => {
  const hasViewPermission = usePermissions(["HOSTS_VIEW"]);
  const hasEditPermission = usePermissions(["HOSTS_EDIT"]);
  const { loading } = useSelector((state) => state.host);

  // Extract host data with necessary transformations
  const getHostData = useCallback(
    (gateways) =>
      gateways?.map(({ host }) => ({
        id: host?._id,
        name: host?.name,
        category: host?.subtype || host?.category?.name,
        spackVersion: host?.spackVersion,
        tenants:
          host?.orgs?.length > 0 ? host.orgs.join(" , ") : host?.ownerOrgName,
        osspackVersion: host?.osspackVersion,
        bundleVersion: host?.appIdDetails?.appIdInstalledBundleVersion,
        ...host, // Spread other host properties if needed
        headend: `${host?.headendCode ?? ""}-${host?.headendName ?? ""}`,
        region: host?.region?.name,
      })),
    []
  );

  const onviewHost = (host) => {
    onView(host);
  };

  // Render individual tabs for categorised data
  const renderTabs = () =>
    categorised.map(({ type, gateways }, index) => {
      const title =
        type === "SHARED"
          ? `Versa Gateways - (${gateways?.length})`
          : `Tenant Gateways - (${gateways?.length})`;

      return (
        <Tab eventKey={type} title={title} key={`gateway-tab-${index}`}>
          <PaginatedTable
            data={getHostData(gateways)}
            columns={COLUMNS["hostGateway"]}
            perPage={20}
            onView={hasViewPermission ? onviewHost : null}
            onEdit={hasEditPermission ? onEdit : null}
            isLoading={loading["fetchHosts"]}
          />
        </Tab>
      );
    });

  return (
    <Card className="my-2">
      <Card.Body>
        <Tabs defaultActiveKey="SHARED" id="gateway-tabs" className="mb-3">
          {renderTabs()}
        </Tabs>
      </Card.Body>
    </Card>
  );
};

export default GatewayTab;
