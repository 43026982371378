// actions/headendActions.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "services/api";
import { apiError } from "../slices/apiSlice";
import MESSAGES from "constants/messages";
import { showAlert } from "../slices/alertSlice";
import React from "react";

const fetchHeadends = createAsyncThunk(
  "headend/fetchHeadends",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/headends`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchHeadendsUnderMaintenance = createAsyncThunk(
  "headend/fetchHeadendsUnderMaintenance",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/headends/maintenance`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const saveHeadend = createAsyncThunk(
  "headend/saveHeadends",
  async (headendData, { dispatch, rejectWithValue }) => {
    let message = MESSAGES["headend-save-sc"];
    try {
      let response = {};
      if (headendData?._id) {
        response = await API.put(`/headends/${headendData?._id}`, headendData);
      } else {
        response = await API.post("/headends", headendData);
      }
      dispatch(
        showAlert({
          message,
          variant: "success",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["headend-save-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

const onBoardExisting = createAsyncThunk(
  "headend/onBoardExisting",
  async (headendData, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.post("/headends/onboard", headendData);
      dispatch(
        showAlert({
          message: MESSAGES["headend-onboard-sc"],
          variant: "success",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["headend-onboard-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error);
    }
  }
);

const fetchHeadendById = createAsyncThunk(
  "headend/fetchHeadendById",
  async (headendId, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(`/headends/${headendId}`);
      return response.data;
    } catch (error) {
      dispatch(
        showAlert({
          message: MESSAGES["headends-fetch-fa"],
          variant: "danger",
        })
      );
      return rejectWithValue(error);
    }
  }
);

const fetchHeadendSummary = createAsyncThunk(
  "headend/fetchHeadendSummary",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/headends/summary`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const uploadHeadend = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await API.post("/headends/bulkupload", formData, config);
    return response.data;
  } catch (error) {
    dispatch(apiError(error.message));
    throw error;
  }
};

const refreshHeadend = createAsyncThunk(
  "headend/refreshHeadend",
  async (headend, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.post("/headends/refresh", { headend });
      return response.data;
    } catch (error) {
      dispatch(apiError(error.message));
      throw error;
    }
  }
);

export {
  fetchHeadends,
  fetchHeadendsUnderMaintenance,
  saveHeadend,
  onBoardExisting,
  fetchHeadendById,
  fetchHeadendSummary,
  uploadHeadend,
  refreshHeadend,
};
