import React from "react";
import { Card } from "react-bootstrap";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const totalValue = 83; // Placeholder for the total expenditure percentage

const CustomPieChart = ({ data }) => {
  return (
    <Card className="p-3 shadow-sm">
      <div className="d-flex flex-column align-items-center">
        <ResponsiveContainer width={200} height={200}>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={50}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              startAngle={90}
              endAngle={-270}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      {/* <div className="d-flex justify-content-center mt-3">
        {data.map((item, index) => (
          <div key={index} className="d-flex align-items-center mx-2">
            {console.log(item)}
            <div
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: item.color,
                marginRight: "5px",
                borderRadius: "50%",
              }}
            />
            <span>{item.name}</span>
          </div>
        ))}
      </div> */}
    </Card>
  );
};

export default CustomPieChart;
