import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";

const MultiSelectSearch = ({ options, onChange, isMulti, selectedOption }) => {
  const allOptions = useMemo(
    () =>
      options?.map((option) => ({
        value: option._id,
        label: option?.name,
      })),
    [options]
  );

  const normalizeSelectedOption = (option) => {
    if (Array.isArray(option)) {
      return option?.map((item) => ({
        value: item._id || item.value,
        label: item.name || item.label,
      }));
    }
    return option
      ? {
          value: option._id || option.value,
          label: option.name || option.label,
        }
      : null;
  };

  const [selectedOptions, setSelectedOptions] = useState(
    normalizeSelectedOption(selectedOption)
  );

  useEffect(() => {
    setSelectedOptions(normalizeSelectedOption(selectedOption));
  }, [selectedOption]);

  const handleChange = (selectedItems) => {
    setSelectedOptions(selectedItems);
    onChange(selectedItems);
  };

  return (
    <Select
      options={allOptions}
      isMulti={isMulti}
      value={selectedOptions}
      onChange={handleChange}
      placeholder="Select options..."
      isSearchable
    />
  );
};

export default MultiSelectSearch;
