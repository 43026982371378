import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, ListGroup, Row, Col, Badge, Tabs, Tab } from "react-bootstrap";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { useDispatch, useSelector } from "react-redux";
import { fetchTenantSummary } from "stores/actions";
import { useParams } from "react-router-dom";
import BackButton from "components/BackButton";

const TenantDetails = () => {
  const [key, setKey] = useState("tenants");
  const dispatch = useDispatch();
  const { orgSummary: summary } = useSelector((state) => state.organisation);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(fetchTenantSummary(id));
    }
  }, [id, dispatch]);

  const formatData = (list, formatter) => list?.map(formatter) || [];

  const tenantsData = useMemo(
    () =>
      formatData(summary?.childOrganisations?.list, (org) => ({
        id: org._id,
        name: org?.name,
        parentOrg: org?.parentOrg,
        plan: org?.plan,
      })),
    [summary?.childOrganisations]
  );

  const headendData = useMemo(
    () =>
      formatData(summary?.headends?.list, (head) => ({
        id: head?._id,
        name: head?.name,
        infra: head?.infra?.name,
        category: head?.category?.name,
        fqdn: head?.fqdn,
        active: head?.active ? "Yes" : "No",
      })),
    [summary?.headends]
  );

  const getHostsData = useCallback(
    (hosts) =>
      formatData(hosts, (host) => ({
        ...host,
        id: host._id,
        name: host?.name,
        headend: host?.headendName,
        category: host?.category?.name,
        owner: host?.ownerOrgName,
      })),
    []
  );

  const renderCard = (title, count, color) => (
    <Col className="mb-3" sm={12} md={4}>
      <Card className={`box ${color} bg`}>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <h2>{count}</h2>
          <Card.Text>Total</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );

  const renderTabContent = (title, data, columns) => (
    <>
      <Row className="py-2">
        <Col>
          <h5>
            <b>{title}</b>
          </h5>
          <small className="sub-info">{`Lists all ${title.toLowerCase()} owned by the tenant`}</small>
        </Col>
        <Col className="col-auto">
          <Badge>{data.length}</Badge>
        </Col>
      </Row>

      {data.length > 0 ? (
        <PaginatedTable
          data={data}
          columns={COLUMNS[columns]}
          itemsPerPage={10}
        />
      ) : (
        <center>No {title} found</center>
      )}
    </>
  );

  const renderContactDetails = ({ contacts = [] }) => (
    <>
      <Row>
        <Col>
          <div className="my-2">
            <h5>
              <b>Contact Details</b>
            </h5>
            <small className="sub-info">
              List provides all contact details
            </small>
          </div>
        </Col>
        <Col className="col-auto">
          <Badge>{contacts?.length}</Badge>
        </Col>
      </Row>

      <Row>
        {contacts.map((contact, index) => (
          <Col md={6} key={`${contact.name}-${index}`}>
            <Card className="mb-4">
              <Card.Body>
                <ListGroup variant="flush" key={contact._id}>
                  <ListGroup.Item>
                    <span>Type of contact:</span>
                    <b> {contact.contact_type}</b>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span>Name:</span>
                    <b> {contact.name}</b>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span>Email: </span>
                    <b>{contact.email.join(", ")}</b>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span>Timezone: </span>
                    <b>{contact.timezone}</b>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );

  return (
    <div className="middle-section">
      <Row>
        <Col>
          <BackButton title="Tenants" />
          <div className="my-2">
            <h5>
              <b>Tenant Details</b>
            </h5>
            <small className="sub-info">Detailed view of the tenant</small>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={7}>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col>
                  <small>NAME</small>
                  <h6>
                    <b>{summary?.organisation?.name}</b>
                  </h6>
                </Col>
                <Col>
                  <small>PARENT TENANT</small>
                  <h6>
                    <b>{summary?.organisation?.parentOrg || "---"}</b>
                  </h6>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Tabs
                id="tenants-tabs"
                activeKey={key}
                onSelect={setKey}
                className="p-0"
              >
                <Tab eventKey="tenants" title="Sub Tenants" className="p-3">
                  {renderTabContent("Sub Tenants", tenantsData, "tenants")}
                </Tab>
                <Tab eventKey="headends" title="Headends" className="p-3">
                  {renderTabContent("Headends", headendData, "headend")}
                </Tab>
                <Tab eventKey="hosts" title="Hosts" className="p-3">
                  <div className="my-2">
                    <h5>
                      <b>Hosts </b>
                    </h5>
                    <small className="sub-info">
                      Detailed view of all hosts use by the tenant
                    </small>
                  </div>
                  <Tabs
                    defaultActiveKey="owned"
                    id="host-tabs"
                    className="mb-3"
                  >
                    <Tab
                      eventKey="owned"
                      title={`Tenant Device (${summary?.hosts?.owned.length})`}
                    >
                      {renderTabContent(
                        "Owned Hosts",
                        getHostsData(summary?.hosts?.owned),
                        "tenantHost"
                      )}
                    </Tab>
                    <Tab
                      eventKey="shared"
                      title={`Versa Shared (${summary?.hosts?.shared.length})`}
                    >
                      {renderTabContent(
                        "Shared Hosts",
                        getHostsData(summary?.hosts?.shared),
                        "tenantHost"
                      )}
                    </Tab>
                  </Tabs>
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
        <Col md={5}>
          <Row>
            {renderCard(
              "Sub Tenants",
              summary?.childOrganisations?.total,
              "blue"
            )}
            {renderCard("Headends", summary?.headends?.total, "red")}
            {renderCard("Hosts", summary?.hosts?.total, "orange")}
          </Row>
          {renderContactDetails({ contacts: summary?.organisation?.contacts })}
        </Col>
      </Row>
    </div>
  );
};

export default TenantDetails;
