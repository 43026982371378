// reducers/hostSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchMaintenance,
  fetchMaintenanceDetails,
  saveMaintenance,
  deleteMaintenance,
  updateMaintenance,
  fetchMaintenanceSummary,
} from "../actions";

const maintenanceSlice = createSlice({
  name: "maintenance",
  initialState: {
    maintenance: [],
    maintenanceDetails: {},
    summary: {},
    template: "",
    createdMaintenance: {},
    contacts: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMaintenance.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createdMaintenance = {};
        state.template = "";
      })
      .addCase(fetchMaintenance.fulfilled, (state, action) => {
        state.loading = false;
        state.maintenance = action.payload;
      })
      .addCase(fetchMaintenance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchMaintenanceDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.maintenanceDetails = {};
      })
      .addCase(fetchMaintenanceDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.maintenanceDetails = action.payload;
      })
      .addCase(fetchMaintenanceDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchMaintenanceSummary.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMaintenanceSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.summary = action.payload;
      })
      .addCase(fetchMaintenanceSummary.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(saveMaintenance.pending, (state) => {
        state.loading = true;
        state.createdMaintenance = {};
        state.template = "";
        state.error = null;
      })
      .addCase(saveMaintenance.fulfilled, (state, action) => {
        state.loading = false;
        state.template = action.payload.template;
        state.contacts = action.payload.contacts;
        state.createdMaintenance = action.payload.data;
        state.maintenance = [action.payload.data, ...state.maintenance];
      })
      .addCase(saveMaintenance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteMaintenance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteMaintenance.fulfilled, (state, action) => {
        state.loading = false;
        state.maintenance = state.maintenance.filter(
          (data) => data._id !== action.payload._id
        );
      })
      .addCase(deleteMaintenance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateMaintenance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateMaintenance.fulfilled, (state, action) => {
        state.loading = false;
        state.maintenance = state.maintenance.map((data) => {
          if (data._id === action.payload._id) {
            return action.payload;
          }
          return data;
        });
      })
      .addCase(updateMaintenance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default maintenanceSlice.reducer;
