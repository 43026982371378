import React, { useState } from "react";
import {
  Table,
  Pagination,
  Form,
  Col,
  Row,
  Card,
  InputGroup,
  FormControl,
  Badge,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClockRotateLeft,
  faChevronDown,
  faChevronRight,
  faCheckCircle,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import ShimmerTable from "components/Shimmer/ShimmerTable";
import Loader from "components/Loader";

const ReportTable = () => {
  const { nmap, loading } = useSelector((state) => state.security);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [expandedRow, setExpandedRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const totalReports = nmap?.reports?.length || 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const totalPages = Math.ceil(totalReports / itemsPerPage);

  let last_updated = nmap?.reports?.[0]?.ips
    ? Object.values(nmap.reports[0].ips)?.[0]?.last_scanned
      ? new Date(
          Object.values(nmap.reports[0].ips)[0].last_scanned
        ).toLocaleDateString("en-GB", {
          weekday: "short",
          day: "2-digit",
          month: "short",
          year: "numeric",
          timeZone: "UTC",
        })
      : null
    : null;

  // Deep search function
  const deepSearch = (reports, query) => {
    if (!query) return reports;
    const lowerQuery = query.toLowerCase();

    return reports.filter((report) => {
      // Check if report name matches
      const nameMatches = report.name.toLowerCase().includes(lowerQuery);

      // Check if any IP or port matches
      const ipMatches = Object.values(report.ips).some((ip) => {
        const ipIdMatches = ip.id.toLowerCase().includes(lowerQuery);
        const portMatches = Object.entries(ip.ports).some(
          ([port, details]) =>
            port.includes(lowerQuery) ||
            details.state.toLowerCase().includes(lowerQuery) ||
            details.service.toLowerCase().includes(lowerQuery)
        );
        return ipIdMatches || portMatches;
      });

      // Return the report if name, IP, or port matches
      return nameMatches || ipMatches;
    });
  };

  // Filtered reports based on search query
  const filteredReports = deepSearch(nmap?.reports || [], searchQuery);
  const paginatedReports = filteredReports.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const toggleRow = (id) => {
    setExpandedRow((prev) => (prev === id ? null : id));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setExpandedRow(null); // Collapse any expanded row when changing pages
  };

  return (
    <div>
      <Row className="mt-3 mb-3">
        <Col>
          <h5>
            <b>Nmap Report</b>
          </h5>
          <small className="sub-info">
            All Nmap scanned reports are available here
          </small>
        </Col>
        <Col className="col-auto"></Col>
      </Row>
      <Card>
        <Card.Body>
          <Row className="mb-3">
            <Col>
              <InputGroup>
                <FormControl
                  placeholder="Search here...."
                  className="search-input w-75 px-1"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col className="col-auto">
              <div className="updated-box">
                {loading["nmap"] ? (
                  <Loader />
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faClockRotateLeft}
                      className="green cl"
                    />{" "}
                    <span>Last scanned: </span>
                    <b>{last_updated || "N/A"}</b>
                  </>
                )}
              </div>
            </Col>
          </Row>
          {loading["nmap"] ? (
            <ShimmerTable />
          ) : filteredReports.length > 0 ? (
            <>
              <Table className={`custom-table`}>
                <thead>
                  <tr>
                    <th style={{ width: "10px", textAlign: "center" }}></th>
                    <th>Name</th>
                    <th>IP</th>
                    <th>Last Scanned</th>
                    <th>Reachable</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedReports.map((report) => (
                    <React.Fragment key={report._id}>
                      <tr>
                        <td
                          onClick={() => toggleRow(report._id)}
                          style={{ cursor: "pointer", textAlign: "center" }}
                          className="p-2"
                        >
                          <FontAwesomeIcon
                            icon={
                              expandedRow === report._id
                                ? faChevronDown
                                : faChevronRight
                            }
                          />
                        </td>
                        <td>{report.name}</td>
                        <td>
                          <Badge bg="info">
                            {Object.keys(report.ips).length}
                          </Badge>
                        </td>
                        <td>
                          {new Date(
                            Math.max(
                              ...Object.values(report.ips).map((ip) =>
                                new Date(ip.last_scanned).getTime()
                              )
                            )
                          ).toUTCString()}
                        </td>
                        <td>
                          <FontAwesomeIcon
                            className={
                              Object.values(report.ips).every(
                                (ip) => ip.reachable
                              )
                                ? "cl green"
                                : "cl red"
                            }
                            icon={
                              Object.values(report.ips).every(
                                (ip) => ip.reachable
                              )
                                ? faCheckCircle
                                : faCircleXmark
                            }
                          />
                        </td>
                      </tr>
                      {expandedRow === report._id && (
                        <tr className="expanded">
                          {/* <td colSpan={1}></td> */}
                          <td colSpan={5} className="p-0 m-0 p-3">
                            <Card>
                              <Card.Body>
                                <Table
                                  bordered
                                  size="sm"
                                  style={{ margin: 0, padding: 0 }}
                                  className="custom-table"
                                >
                                  <thead>
                                    <tr>
                                      <th>IP</th>
                                      <th>Ports</th>
                                      <th>Last Scanned</th>
                                      <th>Reachable</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.values(report.ips).map((ip) => (
                                      <tr key={ip.id}>
                                        <td>
                                          <b>{ip.id}</b>
                                        </td>
                                        <td>
                                          {Object.entries(ip.ports).length >
                                          0 ? (
                                            <ul key={ip.id}>
                                              {Object.entries(ip.ports).map(
                                                (
                                                  [port, details],
                                                  index,
                                                  array
                                                ) => (
                                                  <ul
                                                    key={port}
                                                    style={{
                                                      padding: "0px",
                                                      margin: "0px",
                                                    }}
                                                  >
                                                    <li>Port: {port}</li>
                                                    <li>
                                                      State:
                                                      {details.state}
                                                    </li>
                                                    <li>
                                                      Service:
                                                      {details.service}
                                                    </li>
                                                    {index <
                                                      array.length - 1 && (
                                                      <hr />
                                                    )}{" "}
                                                    {/* Add <hr /> between multiple ports */}
                                                  </ul>
                                                )
                                              )}
                                            </ul>
                                          ) : (
                                            "Restricted"
                                          )}
                                        </td>
                                        <td>{ip.last_scanned}</td>
                                        <td>
                                          <FontAwesomeIcon
                                            className={
                                              ip.reachable === true
                                                ? "cl green"
                                                : "cl red"
                                            }
                                            icon={
                                              ip.reachable === true
                                                ? faCheckCircle
                                                : faCircleXmark
                                            }
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </Card.Body>
                            </Card>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
              <Row>
                <Col>
                  <small>Items per page:</small>
                  <Form.Control
                    as="select"
                    value={itemsPerPage}
                    style={{
                      width: "auto",
                      display: "inline-block",
                      marginLeft: "10px",
                    }}
                    onChange={(e) => {
                      setItemsPerPage(Number(e.target.value));
                      setCurrentPage(1);
                    }}
                  >
                    {[10, 15, 20, 25, 50].map((count) => (
                      <option value={count} key={`count_${count}`}>
                        {count}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col>
                  <Pagination className="justify-content-end">
                    <Pagination.First
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                    />
                    <Pagination.Prev
                      onClick={() =>
                        handlePageChange(Math.max(currentPage - 1, 1))
                      }
                      disabled={currentPage === 1}
                    />
                    {[...Array(totalPages).keys()].map((_, index) => (
                      <Pagination.Item
                        key={index + 1}
                        active={currentPage === index + 1}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() =>
                        handlePageChange(Math.min(currentPage + 1, totalPages))
                      }
                      disabled={currentPage === totalPages}
                    />
                    <Pagination.Last
                      onClick={() => handlePageChange(totalPages)}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination>
                </Col>
              </Row>
            </>
          ) : (
            <div>No data matches your search query.</div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ReportTable;
