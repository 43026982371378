import { useEffect, useState } from "react";
import TenantForm from "./AddForm";
import { useDispatch, useSelector } from "react-redux";
import { createTenant, fetchTenants } from "stores/actions";
import TenantListView from "./List";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Row,
} from "react-bootstrap";
import { useBreadcrumbs } from "contexts";
import { CONTACT_INIT, ORGNAISATION_INIT } from "constants/headendInit";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import FileUpload from "components/BulkUpload";
import { usePermissions } from "hooks/usePermission";
import useActivityLogger from "hooks/useActivity";
import Loader from "components/Loader";
import ShimmerTable from "components/Shimmer/ShimmerTable";

const Tenants = () => {
  const dispatch = useDispatch();
  const [downloadList, setDownloadList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [organization, setOrganization] = useState(ORGNAISATION_INIT);
  const { tenants, loading } = useSelector((state) => state.organisation);
  const { activityLogs } = useSelector((state) => state.dashboard);
  const { updateBreadcrumbs } = useBreadcrumbs();
  const logActivity = useActivityLogger();

  const sendLog = (page, activity, name) => {
    logActivity({
      page,
      activity,
      name,
    });
  };

  useEffect(() => {
    sendLog("Tenants", "page-load", "Tenants");
    updateBreadcrumbs([{ text: "Home", url: "/dashboard" }]);
  }, []);

  const handleSaveTenant = async (updatedTenant, closeModal) => {
    try {
      sendLog("Tenants", "save-org", updatedTenant?.name);
      const res = await dispatch(createTenant(updatedTenant));
      closeModal();
    } catch (error) {
      sendLog("Tenants", "save-org-failed", updatedTenant?.name);
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(fetchTenants());
  }, []);

  const downloadJson = async () => {
    try {
      let jsonData = tenants;
      if (downloadList?.length > 0) {
        jsonData = tenants.filter((org) => downloadList.includes(org._id));
      }

      const data = jsonData.map((org) => {
        return {
          _id: org._id,
          name: org.name,
          contacts: org.contacts.length === 0 ? [CONTACT_INIT] : org.contacts,
        };
      });

      const jsonString = JSON.stringify(data);

      const blob = new Blob([jsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "data.json";
      link.click();
      sendLog("Tenants", "download-org-json", "Download");
    } catch (error) {
      sendLog("Tenants", "download-org-json-failed", "Download");
      console.error("Error downloading JSON file:", error);
    }
  };

  return (
    <>
      <div className="middle-section">
        <Row>
          <Col sm={8}>
            <Row className="my-2">
              <Col>
                <div>
                  <h5>
                    <b>Tenants</b>
                  </h5>
                  <small className="sub-info">List of all tenants</small>
                </div>
              </Col>
              {usePermissions(["TENANTS_ADD"]) && (
                <Col className="col-auto">
                  <Dropdown align="end">
                    <Dropdown.Toggle className="menu-btn">
                      <FontAwesomeIcon icon={faEllipsisVertical} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={downloadJson}>
                        <button className="btn blue cl">
                          <FontAwesomeIcon icon={faDownload} /> Download
                        </button>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <FileUpload type="organisations" />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              )}
            </Row>

            <Card className="my-2">
              <Card.Body>
                {loading["fetchTenants"] ? (
                  <ShimmerTable />
                ) : (
                  <TenantListView
                    setDownloadList={setDownloadList}
                    downloadList={downloadList}
                    onSave={handleSaveTenant}
                    sendLog={sendLog}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Row>
              <Col>
                <Card className="blue bg">
                  <Card.Body>
                    <h1>{tenants?.length}</h1>
                    Tenants
                  </Card.Body>
                </Card>
              </Col>
              {usePermissions(["TENANTS_ADD"]) && (
                <Col className="col-auto">
                  <Card
                    className="text-center"
                    onClick={() => setShowModal(true)}
                  >
                    <Card.Body className="">
                      <h1> +</h1>
                      New Tenant
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
            {/* <Activities type="organisation" /> */}
          </Col>
        </Row>
      </div>
      <TenantForm
        organization={organization}
        onSave={handleSaveTenant}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
};

export default Tenants;
