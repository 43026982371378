import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Row, Col, Modal, Card, Table } from "react-bootstrap";
import { fetchHeadendById, saveHeadend, Host } from "stores/actions";
import deepClone from "utils/deepClone";
import { HOST_INIT } from "constants/headendInit";
import useActivityLogger from "hooks/useActivity";
import capitalize from "utils/capitalize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ipOptions } from "constants/mapping";

const HostForm = ({
  host,
  showModal,
  closeModal,
  isEdit,
  updateHostDetails,
  onSave = () => {}, // Default to a no-op function
  headend,
  canEdit = true,
}) => {
  const dispatch = useDispatch();
  const logActivity = useActivityLogger();
  const [formData, setFormData] = useState(HOST_INIT);
  const [selectedWanIps, setSelectedWanIps] = useState([]);
  const [selectedIps, setSelectedIps] = useState([]);
  const { categories } = useSelector((state) => state.categories);
  const { headend: headendDetails } = useSelector((state) => state.headend);
  const { regions } = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (host?._id) setFormData(host);
  }, [host]);

  useEffect(() => {
    if (headend?._id) dispatch(fetchHeadendById(headend?._id));
  }, [headend, dispatch]);

  const logHostActivity = (activity, name) => {
    logActivity({
      page: "host-form",
      activity,
      name,
    });
  };
  const deleteItems = (listName, selectedList) => {
    const updatedList = formData[listName].filter(
      (_, index) => !selectedList.includes(index)
    );
    setFormData((prevState) => ({
      ...prevState,
      [listName]: updatedList,
    }));
  };
  const handleBulkDelete = (listName) => {
    let selectedList = [];
    let clearSelection;

    // Determine the selected list and clearing function
    switch (listName) {
      case "wanIps":
        selectedList = selectedWanIps;
        clearSelection = () => setSelectedWanIps([]);
        break;
      case "ips":
        selectedList = selectedIps;
        clearSelection = () => setSelectedIps([]);
        break;
      default:
        console.error("Unknown list name:", listName);
        return;
    }

    // Delete the items from the list
    deleteItems(listName, selectedList);

    // Clear the selected items
    clearSelection();
  };

  const handleCheckboxChange = (listName, id) => {
    let setSelected, selectedList;

    // Determine the selected list and state updater dynamically
    switch (listName) {
      case "wanIps":
        setSelected = setSelectedWanIps;
        selectedList = selectedWanIps;
        break;
      case "ips":
        setSelected = setSelectedIps;
        selectedList = selectedIps;
        break;
      default:
        console.error("Unknown list name:", listName);
        return;
    }

    // Toggle the selection
    if (selectedList.includes(id)) {
      setSelected(selectedList.filter((item) => item !== id));
    } else {
      setSelected([...selectedList, id]);
    }
  };

  const handleHardwareChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      hardware: {
        ...prevData.hardware,
        [name]: value,
      },
    }));
  };

  const handleInputChange = (e) => {
    if (!canEdit) return;
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNestedChange = (index, fieldName, value, type) => {
    if (!canEdit) return;
    const ipType = type === "wan" ? "wanIps" : "ips";
    const updatedIPs = deepClone(formData[ipType]);
    updatedIPs[index][fieldName] = value;
    setFormData((prevData) => ({ ...prevData, [ipType]: updatedIPs }));
  };

  const handleLicenseChange = (e) => {
    if (!canEdit) return;
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      license: { ...prevData.license, [name]: value },
    }));
  };

  const handleAddItem = (key, defaultItem) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: [...(prevData[key] || []), defaultItem],
    }));
  };

  const handleDeleteItem = (key, index) => {
    setFormData((prevData) => {
      const updatedList = deepClone(prevData[key]);
      updatedList.splice(index, 1);
      return { ...prevData, [key]: updatedList };
    });
  };

  const handleSubmit = async (e) => {
    if (!canEdit) return;
    e.preventDefault();
    let savedHost;

    if (headend) {
      savedHost = await dispatch(
        Host.saveHost({
          ...formData,
          headendName: headendDetails?.name,
          headendCode: headendDetails?.code,
        })
      );
      logHostActivity("save-new-host", formData.name);
      const updateData = {
        _id: headendDetails?._id,
        ...(["vms", "orchestrator-upgrade", "waf"].includes(
          savedHost?.category?.name
        ) && {
          [savedHost?.category?.name]: [
            ...(Array.isArray(headendDetails[savedHost?.category?.name])
              ? headendDetails[savedHost?.category?.name]
              : []), // Ensure it's an array or default to an empty array
            { host: savedHost },
          ],
        }),
      };
      await dispatch(saveHeadend(updateData));
      logHostActivity("update-headend", headendDetails?.name);
    } else if (host._id) {
      logHostActivity("update-host", formData.name);
      savedHost = await dispatch(Host.updateHost(formData));
      if (updateHostDetails) updateHostDetails(savedHost);
    } else {
      logHostActivity("save-new-host", formData.name);
      savedHost = await dispatch(Host.saveHost(formData));
      onSave(savedHost);
    }

    closeModal();
  };

  const formatFieldName = (field) => {
    return capitalize(field.replace(/([a-z])([A-Z])/g, "$1 $2"));
  };

  const ipSelectOptions = useMemo(() => {
    return ipOptions[formData?.category?.name] || ipOptions["default"] || [];
  }, [formData?.category]);

  const renderIPs = (
    ipType,
    type = "ips",
    selectedList,
    handleCheckboxChange
  ) => {
    return formData[ipType]?.map((ip, index) => (
      <tr key={index} className="mb-2 align-items-center">
        <td xs="auto">
          <Form.Check
            type="checkbox"
            checked={selectedList.includes(index)}
            onChange={() => handleCheckboxChange(ipType, index)}
          />
        </td>

        <td>
          {/* <Form.Control type="text" value={ip?.type || ""} readOnly /> */}
          <Form.Control
            as="select"
            name="type"
            className="select-box form-select"
            value={ip?.type}
            onChange={(e) =>
              handleNestedChange(index, "type", e.target.value, type)
            }
          >
            <option value="">Select Type</option>
            {ipSelectOptions?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Form.Control>
        </td>

        <td>
          <Form.Control
            type="text"
            value={ip?.private_ip || ""}
            onChange={(e) =>
              handleNestedChange(index, "private_ip", e.target.value, type)
            }
            required
          />
        </td>

        <td>
          <Form.Control
            type="text"
            value={ip?.public_ip || ""}
            onChange={(e) =>
              handleNestedChange(index, "public_ip", e.target.value, type)
            }
            required
          />
        </td>
        <td>
          <Form.Control
            type="text"
            value={ip?.port || ""}
            onChange={(e) =>
              handleNestedChange(index, "port", e.target.value, type)
            }
            required
          />
        </td>
      </tr>
    ));
  };

  const renderLicenseFields = () => (
    <Row className="mb-2">
      {["solutionTier", "bandwidth", "startDate", "endDate"].map((field) => (
        <Col key={field}>
          <Form.Group controlId={`license.${field}`}>
            <Form.Label>{formatFieldName(field)}</Form.Label>
            <Form.Control
              type="text"
              value={formData?.license?.[field] || ""}
              name={field}
              onChange={handleLicenseChange}
            />
          </Form.Group>
        </Col>
      ))}
    </Row>
  );

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      size="xl"
      className="custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{`Manage host details ${
          headend ? `for ${headend?.name}` : ""
        }`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Row className="mb-3">
                    <Col>
                      <div>
                        <h5>
                          <b>Basic Details</b>
                        </h5>
                        <small className="sub-info">Manage basic details</small>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    {["name", "hostName", "fqdn", "ipmi_ip", "ipAddress"].map(
                      (field) => (
                        <Col key={field} sm={6} className="mb-2">
                          <Form.Group controlId={field}>
                            <Form.Label>{formatFieldName(field)}</Form.Label>
                            <Form.Control
                              type="text"
                              name={field}
                              value={formData?.[field] || ""}
                              onChange={handleInputChange}
                              required
                            />
                          </Form.Group>
                        </Col>
                      )
                    )}

                    <Col sm={6} className="mb-2">
                      <Form.Group controlId="category">
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                          as="select"
                          name="category"
                          className="select-box form-select"
                          value={formData?.category?._id || formData?.category}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Type</option>
                          {categories?.host?.map((category) => (
                            <option key={category._id} value={category._id}>
                              {category?.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    {formData?.category?.subtypes?.length > 0 && (
                      <Col sm={6} className="mb-2">
                        <Form.Group controlId="sub-category">
                          <Form.Label>Sub Category</Form.Label>
                          <Form.Control
                            as="select"
                            name="subtype"
                            className="select-box form-select"
                            value={(
                              formData?.subtype || formData?.type
                            ).toLowerCase()}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Type</option>
                            {formData?.category?.subtypes?.map((subtype) => (
                              <option key={subtype} value={subtype}>
                                {subtype}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                </Card.Body>
              </Card>
              <Row className="mt-4">
                <Col>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col>
                          <div>
                            <h5>
                              <b>Location Details</b>
                            </h5>
                            <small className="sub-info">
                              Manage location details
                            </small>
                          </div>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col sm={6}>
                          <Form.Group controlId="region">
                            <Form.Label>Region:</Form.Label>
                            <Form.Control
                              as="select"
                              name="region"
                              className="select-box form-select"
                              value={
                                formData?.region?._id || formData?.region || ""
                              }
                              onChange={handleInputChange}
                            >
                              <option value="">Select Region</option>
                              {regions?.map((region) => (
                                <option key={region._id} value={region._id}>
                                  {region?.name}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="location">
                            <Form.Label>Location</Form.Label>
                            <Form.Control
                              type="text"
                              name="location"
                              value={formData?.location || ""}
                              onChange={handleInputChange}
                              required
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Card className="mb-4">
                    <Card.Body>
                      <Row className="mb-3">
                        <Col>
                          <div>
                            <h5>
                              <b>IPs</b>
                            </h5>
                            <small className="sub-info">
                              Manage IP details
                            </small>
                          </div>
                        </Col>
                        {canEdit && (
                          <Col className="col-auto">
                            <Button
                              className="px-3 py-1"
                              size="sm"
                              onClick={() =>
                                handleAddItem("ips", {
                                  type: "WAN",
                                  private_ip: "",
                                  public_ip: "",
                                })
                              }
                            >
                              {" "}
                              <FontAwesomeIcon
                                icon={faPlus}
                                className="nav-icon"
                              />
                            </Button>
                            <Button
                              className="px-3 py-1 ms-2"
                              size="sm"
                              variant="danger"
                              onClick={() => handleBulkDelete("ips")}
                              disabled={!selectedIps.length}
                            >
                              {" "}
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="nav-icon"
                              />
                            </Button>
                          </Col>
                        )}
                      </Row>
                      <Table>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Type</th>
                            <th>Private Ip</th>
                            <th>Public Ip</th>
                            <th>Port</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderIPs(
                            "ips",
                            "",
                            selectedIps,
                            handleCheckboxChange
                          )}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                  <Row className="mb-3">
                    <Col>
                      <div>
                        <h5>
                          <b>Device Details</b>
                        </h5>
                        <small className="sub-info">
                          Manage device details
                        </small>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    {["cpu", "cpuCount", "memory", "disk"].map((field) => (
                      <Col key={field} sm={6} className="mb-2">
                        <Form.Group controlId={field}>
                          <Form.Label>{formatFieldName(field)}</Form.Label>
                          <Form.Control
                            type={"text"}
                            name={field}
                            value={formData?.hardware?.[field]}
                            onChange={handleHardwareChange}
                            required
                          />
                        </Form.Group>
                      </Col>
                    ))}

                    {["serialNo", "model"].map((field) => (
                      <Col key={field} sm={6} className="mb-2">
                        <Form.Group controlId={field}>
                          <Form.Label>{formatFieldName(field)}</Form.Label>
                          <Form.Control
                            type="text"
                            name={field}
                            value={formData?.hardware?.[field] || ""}
                            onChange={handleHardwareChange}
                            required
                          />
                        </Form.Group>
                      </Col>
                    ))}
                    <Col sm={6}>
                      <Form.Group controlId="provider">
                        <Form.Label>Provider</Form.Label>
                        <Form.Control
                          type="text"
                          name="provider"
                          value={formData?.provider || ""}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card className="my-4">
                <Card.Body>
                  <Row className="mb-3">
                    <Col>
                      <div>
                        <h5>
                          <b>Version Details</b>
                        </h5>
                        <small className="sub-info">Manage versions</small>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {["softwareVersion", "osspackVersion", "spackVersion"].map(
                      (field) => (
                        <Col key={field} sm={6} className="mb-2">
                          <Form.Group controlId={field}>
                            <Form.Label>{formatFieldName(field)}</Form.Label>
                            <Form.Control
                              type="text"
                              name={field}
                              value={formData?.[field] || ""}
                              onChange={handleInputChange}
                              required
                            />
                          </Form.Group>
                        </Col>
                      )
                    )}
                  </Row>
                </Card.Body>
              </Card>
              <Card className="my-4">
                <Card.Body>
                  <Row className="mb-3">
                    <Col>
                      <div>
                        <h5>
                          <b>License Details</b>
                        </h5>
                        <small className="sub-info">
                          Manage license details
                        </small>
                      </div>
                    </Col>
                  </Row>
                  {renderLicenseFields()}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      {canEdit && (
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            {isEdit ? "Update Host" : "Add Host"}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default HostForm;
