import { PaginatedTable } from "components";
import Highlight from "components/HighlightText";
import COLUMNS from "constants/columns";
import { useMemo } from "react";

import { useNavigate } from "react-router-dom";
const { Accordion, Badge } = require("react-bootstrap");

const OrchestratorResult = ({ orchestrators, sendLog, query }) => {
  const navigate = useNavigate();

  const redirecTo = (orchestrator) => {
    sendLog("orchestrator-click", orchestrator?.name);
    navigate(`/orchestrators/${orchestrator?._id}`);
  };

  const orchestratorData = useMemo(() => {
    return orchestrators.map((orchestrator) => ({
      id: orchestrator._id,
      name: (
        <b
          className="link"
          onClick={() => {
            redirecTo(orchestrator);
          }}
        >
          <Highlight text={orchestrator?.name} highlight={query} />
        </b>
      ),
      headends: orchestrator?.headends
        .map(
          (headend) =>
            `${headend.code ? headend.code + "-" : ""}${headend.name}`
        )
        .join(", "),
      category: orchestrator?.category[0]?.name,
    }));
  }, [orchestrators]);

  return (
    <Accordion.Item eventKey="orchestrators">
      <Accordion.Header>
        Orchestrator :
        <Badge pill bg="info">
          {orchestrators?.length}
        </Badge>
      </Accordion.Header>
      <Accordion.Body>
        <PaginatedTable
          data={orchestratorData}
          columns={COLUMNS["orchestrators"]}
          itemsPerPage={25}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default OrchestratorResult;
