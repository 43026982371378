import StatusCard from "components/StatusCard";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

const MonitorStatusCards = ({ data = [], labelRequired = true }) => {
  const [stats, setStats] = useState({
    failed: [],
    // mismatch: [],
    enabled: [],
  });

  useEffect(() => {
    if (data.length) {
      const newStats = {
        failed: [],
        // mismatch: [],
        enabled: [],
      };

      data.forEach((item) => {
        if (item.status === "enabled") {
          // item.mismatch
          //   ? newStats.mismatch.push(item)
          //   :
          newStats.enabled.push(item);
        } else {
          newStats.failed.push(item);
        }
      });

      setStats(newStats);
    }
  }, [data]);

  return (
    <Row>
      {[
        { color: "red", label: "Not In Sync", count: stats.failed.length },
        // { color: "orange", label: "Mismatch", count: stats.mismatch.length },
        { color: "green", label: "In Sync", count: stats.enabled.length },
      ].map(({ color, label, count }) => (
        <Col key={label}>
          <h5 className={`p-2 px-3 ${color} bg m-0`}>
            {count}
            <br />
            <small style={{ fontSize: "1rem" }}>{label}</small>
          </h5>
        </Col>
      ))}
    </Row>
  );
};

export default MonitorStatusCards;
