import {
  faBuilding,
  faCloudArrowUp,
  faComments,
  faGamepad,
  faGear,
  faHome,
  faLayerGroup,
  faNetworkWired,
  faScrewdriverWrench,
  faSearch,
  faServer,
  faShieldHalved,
  faTv,
} from "@fortawesome/free-solid-svg-icons";

const LOGO =
  "https://versa-networks.com/wordpress/wp-content/themes/bootstrap-theme/assets/images/new-homepage/versa-new-logo.svg";

const MENU = [
  {
    name: "Dashboard",
    link: "/dashboard",
    permission: "DASHBOARD_VIEW",
    icon: faHome,
  },
  {
    name: "Search",
    link: "/search",
    permission: "SEARCH_VIEW",
    icon: faSearch,
  },
  {
    name: "Data Center",
    link: "/dc",
    permission: "DC_VIEW",
    icon: faLayerGroup,
  },
  {
    name: "Orchestrators",
    link: "/orchestrators",
    permission: "WRAPPERS_VIEW",
    icon: faNetworkWired,
  },
  {
    name: "Headends",
    link: "/headends",
    permission: "HEADENDS_VIEW",
    icon: faGamepad,
  },

  {
    name: "Gateways",
    link: "/gateways",
    permission: "GATEWAY_VIEW",
    icon: faGamepad,
  },
  {
    name: "Hosts",
    link: "/host",
    permission: "HOSTS_VIEW",
    icon: faServer,
  },
  {
    name: "Tenants",
    link: "/tenants",
    permission: "TENANTS_VIEW",
    icon: faBuilding,
  },
  {
    name: "Monitoring",
    link: "/monitoring",
    permission: "MONITORING_VIEW",
    icon: faTv,
  },
  {
    name: "Backups",
    link: "/backups",
    permission: "BACKUPS_VIEW",
    icon: faCloudArrowUp,
  },
  {
    name: "Security",
    link: "/security",
    permission: "SECURITY_VIEW",
    icon: faShieldHalved,
  },
  {
    name: "Maintenance",
    link: "/maintenance",
    permission: "NOTIFICATION_VIEW",
    icon: faScrewdriverWrench,
  },
  {
    name: "Admininstration",
    link: "/manage",
    permission: "MANAGE_VIEW",
    icon: faGear,
  },
  {
    name: "Feedbacks",
    link: "/feedbacks",
    permission: "FEEDBACKS_VIEW",
    icon: faComments,
  },
];

export { MENU, LOGO };
