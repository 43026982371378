import BackButton from "components/BackButton";
import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  Card,
  ListGroup,
  Row,
  Col,
  Modal,
  Button,
  Badge,
  Form,
  Tabs,
  Tab,
  Accordion,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchMaintenanceDetails, updateMaintenance } from "stores/actions";
import capitalize from "utils/capitalize";
import maintenanceTemplate from "../template";
import TEMPLATES from "constants/templates";

const MaintenanceDetails = () => {
  const dispatch = useDispatch();
  const { maintenanceDetails: maintenance } = useSelector(
    (state) => state.maintenance
  );
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [extendTime, setExtendTime] = useState("");
  const [showExtendPrompt, setShowExtendPrompt] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState(false);
  const [key, setKey] = useState("details");
  const { id } = useParams();

  const colors = {
    PLANNED: "yellow",
    COMPLETE: "green",
    EXTEND: "blue",
    ADHOC: "yellow",
    EMERGENCY: "violet",
    CANCEL: "red",
    START: "orange",
  };

  const buttonList = useMemo(() => {
    let status = [];

    switch (maintenance?.currentStatus) {
      case "ADHOC":
      case "PLANNED":
      case "EMERGENCY":
      case "PLANNED-EMAIL":
      case "EMERGENCY-EMAIL":
      case "ADHOC-EMAIL":
        status = ["START"];
        break;
      case "START":
        status = ["COMPLETE", "EXTEND"];
        break;
      case "EXTEND":
        status = ["COMPLETE"];
        break;
      default:
        break;
    }
    return status;
  }, [maintenance?.currentStatus]);

  const status = useMemo(() => {
    const currentDate = new Date();
    const isOngoing =
      currentDate >= maintenance?.maintenanceTime?.from &&
      currentDate <= maintenance?.maintenanceTime?.to;
    return isOngoing
      ? "ongoing"
      : currentDate < maintenance?.maintenanceTime?.from
      ? "scheduled"
      : "completed";
  }, [maintenance]);

  const handleStatusAction = async (action) => {
    const user = JSON.parse(localStorage.getItem("user"))?._id;

    const message = action === "EXTEND" && extendTime ? extendTime : "";
    let history = [{ status: action, message, user }, ...maintenance.history];
    const data = {
      ...maintenance,
      history,
      currentStatus: action,
      extendTime,
    };

    await dispatch(updateMaintenance({ data, template: emailTemplate }));
    setShowModal(true);
  };

  const confirmAction = (action) => {
    setSelectedAction(action);

    if (action === "EXTEND") {
      setShowExtendPrompt(true); // Show prompt for "EXTEND" action
    } else {
      if (action === "START") {
        setEmailTemplate(TEMPLATES["maintenance"]["start"]);
      } else if (action === "COMPLETE") {
        setEmailTemplate(TEMPLATES["maintenance"]["complete"]);
      }
      setShowConfirmation(true); // Show confirmation dialog for other actions
    }
  };

  const handleExtendSubmit = () => {
    setShowExtendPrompt(false);
    handleStatusAction("EXTEND");
  };

  const handleConfirmationSubmit = () => {
    setShowConfirmation(false);
    handleStatusAction(selectedAction);
  };

  useEffect(() => {
    dispatch(fetchMaintenanceDetails(id));
  }, [id]);

  return (
    <div className="middle-section">
      <Row>
        <Col>
          <BackButton title="Maintenance" />
          <div>
            <h5>
              <b>Maintenance Details</b>
            </h5>
            <small className="sub-info">
              Detailed view of the maintenance notification created
            </small>
          </div>
        </Col>
        <Col className="col-auto">
          {/* {status !== "completed" && */}
          {buttonList?.map((button) => (
            <Button
              variant={`${button === "CANCEL" ? "danger" : "primary"}`}
              key={button}
              onClick={() => confirmAction(button)}
              className="mx-2"
            >
              {button}
            </Button>
          ))}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={8}>
          <Row>
            <Col sm={12}>
              <Card className="mb-3">
                <Card.Body>
                  <Row>
                    <Col>
                      <small>NAME</small>
                      <h6>
                        <b>{capitalize(maintenance?.notification)}</b>
                      </h6>
                    </Col>
                    <Col>
                      <small>TYPE</small>
                      <h6>
                        <b>{maintenance?.notificationType}</b>
                      </h6>
                    </Col>
                    <Col>
                      <small>HEADEND</small>
                      <h6>
                        <b>
                          {maintenance?.headend?.code}-
                          {maintenance?.headend?.name}
                        </b>
                      </h6>
                    </Col>
                    <Col>
                      <small>STATUS</small>
                      <h6>
                        <b>{maintenance?.currentStatus}</b>
                      </h6>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <span>Service Impact:</span>
                      <b>{maintenance?.serviceImpact}</b>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <span>Ticket:</span>
                      <b>{maintenance?.ticket}</b>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <span>
                        <b>Maintenance Time:</b>
                        <br />
                        From -
                        <br />
                        To -
                        {maintenance?.extendTime && (
                          <>
                            <br />
                            Extended -
                          </>
                        )}
                      </span>
                      <b>
                        <br />
                        {maintenance?.maintenanceTime?.from}
                        <br />
                        {maintenance?.maintenanceTime?.to}
                        {maintenance?.extendTime && (
                          <>
                            <br />
                            {maintenance?.extendTime}
                          </>
                        )}
                      </b>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <span>
                        <b> Release:</b>
                        <br />
                        From -
                        <br />
                        To -
                      </span>
                      <b>
                        <br />
                        {maintenance?.release?.from}
                        <br />
                        {maintenance?.release?.to}
                      </b>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <span>Headend Name:</span>
                      <b>{maintenance?.headend?.name}</b>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <span>Current Status:</span>
                      <b>{maintenance?.currentStatus}</b>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>

            <Col md={6}>
              <Card className="h-100">
                <Card.Body>
                  <Tabs>
                    <Tab eventKey="to" title="To" className="p-3">
                      {maintenance?.contacts?.to?.map((to) => (
                        <Badge className="m-1">{to}</Badge>
                      ))}
                    </Tab>
                    <Tab eventKey="cc" title="Cc" className="p-3">
                      {maintenance?.contacts?.cc?.map((cc) => (
                        <Badge className="m-1">{cc}</Badge>
                      ))}
                    </Tab>
                    <Tab eventKey="bcc" title="Bcc" className="p-3">
                      {maintenance?.contacts?.bcc?.map((bcc) => (
                        <Badge className="m-1">{bcc}</Badge>
                      ))}
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          {maintenance?.devices?.length > 0 && (
            <>
              <Card>
                <Card.Body>
                  <div className="my-2">
                    <h5>
                      <b>Impact Details</b>
                    </h5>
                  </div>
                  <p>{maintenance?.impactDetails}</p>
                </Card.Body>
              </Card>
              <Card className="mt-3">
                <Card.Body>
                  <div className="my-2">
                    <h5>
                      <b>
                        Devices{" "}
                        <Badge pill>{maintenance?.devices?.length}</Badge>
                      </b>
                    </h5>
                    <small className="sub-info">
                      List of all impacted devices
                    </small>
                  </div>
                  <div className="mt-3">
                    {maintenance?.devices?.map((device, index) => (
                      <Badge
                        key={index}
                        className="p-2 px-3 mx-1 my-1"
                        bg="info"
                        pill
                      >
                        <b style={{ fontSize: "0.9rem" }}>{device?.name}</b>
                      </Badge>
                    ))}
                  </div>
                </Card.Body>
              </Card>
              <div className="my-2 mt-4">
                <h5>
                  <b>History</b>
                </h5>
                <small className="sub-info">
                  All history related to status are available here
                </small>
              </div>
              <div className="px-4 mt-3">
                {maintenance?.history?.map((history) => (
                  <Row key={history._id}>
                    <Col className={`timeline ${colors[history.status]}`}>
                      <Row className="details br">
                        <Col>
                          <small className="time">{history.createdAt}</small>
                          <br />
                          <small>
                            <b className="title">{history?.status}</b>
                          </small>
                        </Col>
                        <Col className="col-auto text-end">
                          <small>
                            <b>{history?.user.name}</b>
                          </small>
                          <br />
                          <small>{history?.user.email}</small>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))}
              </div>
            </>
          )}
        </Col>
      </Row>

      {/* Confirmation Modal */}
      <Modal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            dangerouslySetInnerHTML={{
              __html: maintenanceTemplate(emailTemplate),
            }}
          />
        </Modal.Body>
        <Modal.Footer className="w-100">
          <div className="float-start">
            Are you sure you want to perform the action:{" "}
            <b>{capitalize(selectedAction)}</b>?
          </div>
          <div className="float-end">
            <Button
              variant="secondary"
              onClick={() => setShowConfirmation(false)}
              className="mx-2"
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmationSubmit}>
              Confirm
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Extend Prompt Modal */}
      <Modal show={showExtendPrompt} onHide={() => setShowExtendPrompt(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Extend Maintenance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Enter New Extend Time:</Form.Label>
            <Form.Control
              type="datetime-local"
              value={extendTime}
              onChange={(e) => setExtendTime(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowExtendPrompt(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleExtendSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MaintenanceDetails;
