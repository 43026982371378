import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Host } from "stores/actions";
import SingleView from "pages/Host/SingleHostView";
import HostForm from "pages/Host/AddForm";
import GatewayTab from "components/GatewayTab";
import ShimmerTable from "components/Shimmer/ShimmerTable";

const GatewayList = ({ sendLog }) => {
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedHost, setSelectedHost] = useState(null);
  const [hostToEdit, setEditToHost] = useState(null);
  const { hosts, loading } = useSelector((state) => state.host);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Host.fetchHosts("gateway"));
  }, []);

  const onView = (host) => {
    sendLog("gateway", "click-gateway-view", host?.name);
    setShowViewModal(true);
    setSelectedHost(host);
  };

  const onEdit = (item) => {
    sendLog("gateway", "click-gateway-edit", item?.name);
    setEditToHost(item);
    setShowModal(true);
  };

  return (
    <div>
      {loading["fetchHosts"] ? (
        <ShimmerTable />
      ) : (
        <GatewayTab categorised={hosts} onEdit={onEdit} onView={onView} />
      )}
      <HostForm
        host={hostToEdit}
        removeHost={setEditToHost}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        isEdit={!!hostToEdit?._id}
      />
      <SingleView
        host={selectedHost}
        removeHost={setSelectedHost}
        showModal={showViewModal}
        closeModal={() => setShowViewModal(false)}
      />
    </div>
  );
};

export default GatewayList;
