import React, { useMemo, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";
import useActivityLogger from "hooks/useActivity";
import MonitorSingleView from "../Components/MonitorSingleView";
import MonitorStatusCards from "../Components/MonitorStatusCard";
import TABS from "constants/tabs";
import ShimmerTable from "components/Shimmer/ShimmerTable";
import ShimmerNameList from "components/Shimmer/ShimmerNameList";

const MonitoringAuditReport = () => {
  const logActivity = useActivityLogger();
  const { monitoringReport, loading } = useSelector((state) => state.audit);
  const [data, setData] = useState({});
  const [dataKey, setDataKey] = useState("director");

  // Use memoization for headend data
  const headendData = useMemo(() => {
    const updatedData = monitoringReport.map((monitoringData) => ({
      ...monitoringData,
      name: monitoringData?.headend,
    }));

    setData(updatedData[0]);
    return updatedData;
  }, [monitoringReport]);

  const onView = (item) => {
    logActivity({
      page: "monitor-audit",
      activity: "click-view",
      item: item?.name,
    });
    setDataKey("director");
    setData(item);
  };

  const flatHeadendData = useMemo(() => {
    return (
      TABS["headend"]
        .filter((item) => data && data[item])
        .map((item) => [...data[item]])
        .flat() || []
    );
  }, [data]);

  return (
    <Row>
      <Col md={3}>
        {/* Use ShimmerTable when loading, else use ShimmerNameList */}
        {loading["monitoringReport"] ? (
          <ShimmerNameList />
        ) : (
          <PaginatedTable
            data={headendData}
            columns={COLUMNS["monitoring"]}
            perPage={25}
            onView={onView}
            onLoading={loading["fetchMonitoringAuditReport"]}
          />
        )}
      </Col>
      <Col md={9}>
        <Card className="mb-3">
          <Card.Body>
            <Row>
              <Col md={8}>
                {/* Conditionally render shimmer or actual content */}
                {loading["monitoringReport"] ? (
                  <ShimmerTable />
                ) : (
                  <>
                    <h4 className="mt-2">
                      <b>{data?.headend}</b>
                    </h4>
                    <small className="sub-info">
                      All monitoring data for {data?.headend}
                    </small>
                  </>
                )}
              </Col>
              <Col>
                <MonitorStatusCards data={flatHeadendData} />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <MonitorSingleView
          data={data}
          dataKey={dataKey}
          setDataKey={setDataKey}
        />
      </Col>
    </Row>
  );
};

export default MonitoringAuditReport;
