import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Modal, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "react-select";
import { Orchestrators } from "stores/actions";
import HostForm from "../Host/AddForm";
import { HOST_INIT } from "constants/headendInit";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import deepClone from "utils/deepClone";
import useActivityLogger from "hooks/useActivity";

const HEADEND_WRAPPER_INIT = {
  name: "",
  category: "",
  nodes: [{ fqdn: "", hosts: [] }],
  headends: [],
};

const InputField = ({ label, type, name, value, handleChange }) => (
  <Form.Group controlId={name}>
    <Form.Label>{label}</Form.Label>
    <Form.Control
      type={type}
      name={name}
      value={value}
      onChange={handleChange}
      required
    />
  </Form.Group>
);

const NodesCard = ({
  node,
  nodeIndex,
  handleDeleteNode,
  handleInputChange,
  handleAddHost,
  editHost,
}) => (
  <Card className="mb-3">
    <Card.Header>
      <Row>
        <Col>Nodes</Col>
        <Col className="col-auto">
          <Button variant="danger" onClick={() => handleDeleteNode(nodeIndex)}>
            <FontAwesomeIcon icon={faClose} />
          </Button>
        </Col>
      </Row>
    </Card.Header>
    <Card.Body>
      <Form.Group as={Row} controlId={`fqdn-${nodeIndex}`} className="mb-3">
        <Form.Label column sm="2">
          FQDN
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="text"
            name="fqdn"
            value={node.fqdn}
            onChange={(e) => handleInputChange(e, nodeIndex)}
          />
        </Col>
      </Form.Group>

      <PaginatedTable
        data={node?.hosts?.map((host, index) => ({
          name: host?.name,
          fqdn: host?.fqdn,
          region: host?.location,
          host,
          nodeIndex,
          hostIndex: index,
        }))}
        columns={COLUMNS["orchestratorHost"]}
        onEdit={editHost}
        paginationRequired={false}
        searchRequired={false}
        filterRequired={false}
      />

      <Button variant="secondary" onClick={() => handleAddHost(nodeIndex)}>
        Add Host
      </Button>
    </Card.Body>
  </Card>
);

const OrchestratorsForm = ({ orchestrators, showModal, closeModal }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(HEADEND_WRAPPER_INIT);
  const [hostToEdit, setHostToEdit] = useState(null);
  const [hostIndex, setHostIndex] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const { headends } = useSelector((state) => state.headend);
  const { categories } = useSelector((state) => state.categories);
  const { orchestrator } = useSelector((state) => state.orchestrators);
  const logActivity = useActivityLogger();

  const sendLog = (page, activity, name) => {
    logActivity({ page, activity, name });
  };

  useEffect(() => {
    if (orchestrators)
      setFormData({
        ...orchestrators,
        ...orchestrator,
        headends: orchestrator?.headends?.map((headend) => headend?._id),
        nodes: orchestrator?.nodes?.length
          ? orchestrator?.nodes
          : HEADEND_WRAPPER_INIT.nodes,
      });
  }, [orchestrator, orchestrators, showModal]);

  const handleInputChange = (e, nodeIndex, hostIndex) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      const updatedNodes = deepClone(prevState.nodes);
      if (nodeIndex !== undefined) {
        if (hostIndex !== undefined) {
          updatedNodes[nodeIndex].hosts[hostIndex][name] = value;
        } else {
          updatedNodes[nodeIndex][name] = value;
        }
        return { ...prevState, nodes: updatedNodes };
      }
      return { ...prevState, [name]: value };
    });
  };

  const handleAddNode = () =>
    setFormData((prevState) => ({
      ...prevState,
      nodes: [...prevState.nodes, { fqdn: "", hosts: [] }],
    }));

  const handleDeleteNode = (nodeIndex) =>
    setFormData((prevState) => ({
      ...prevState,
      nodes: prevState.nodes.filter((_, index) => index !== nodeIndex),
    }));

  const handleAddHost = (nodeIndex) => {
    setSelectedNode(nodeIndex);
    setShowEditModal(true);
    setHostToEdit(HOST_INIT);
  };

  const handleDeleteHost = (nodeIndex, hostIndex) =>
    setFormData((prevState) => ({
      ...prevState,
      nodes: prevState.nodes.map((node, idx) =>
        idx === nodeIndex
          ? { ...node, hosts: node?.hosts?.filter((_, i) => i !== hostIndex) }
          : node
      ),
    }));

  const editHost = ({ host, hostIndex, nodeIndex }) => {
    setSelectedNode(nodeIndex);
    setHostIndex(hostIndex);
    setShowEditModal(true);
    setHostToEdit(host);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    sendLog(
      "Orchestrators",
      orchestrators?._id ? "update-orchestrator" : "save-new-orchestrator",
      formData?.name
    );

    if (orchestrators?._id) {
      let formDataApi = deepClone(formData);
      let updatedFormDataApi = formDataApi?.nodes?.map((node) => ({
        ...node, // Retain other properties of the node
        hosts: node?.hosts?.map((host) => host?._id), // Map each host to its _id
      }));
      dispatch(
        Orchestrators.updateOrchestrators({
          _id: formData?._id,
          name: formData?.name,
          category: formData?.category,
          nodes: updatedFormDataApi,
          headends: formData?.headends,
        })
      );
    } else {
      dispatch(Orchestrators.saveOrchestrators(formData));
    }

    setFormData(HEADEND_WRAPPER_INIT);
    closeModal();
  };

  const handleHeadendChange = (selectedOptions) =>
    setFormData((prevState) => ({
      ...prevState,
      headends: selectedOptions?.map((option) => option.value),
    }));

  const onSaveHost = (host) => {
    const updatedNodes = deepClone(formData.nodes);
    if (hostIndex !== null) {
      updatedNodes[selectedNode].hosts[hostIndex] = host;
    } else {
      updatedNodes[selectedNode].hosts = [
        ...(updatedNodes[selectedNode].hosts || []),
        host,
      ];
    }
    setFormData((prevState) => ({ ...prevState, nodes: updatedNodes }));
    setHostToEdit(null);
    setHostIndex(null);
  };

  const headendOptions = headends?.map((headend) => ({
    value: headend._id,
    label: `${headend.code}-${headend.name}`,
  }));

  return (
    <Modal show={showModal} onHide={closeModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {orchestrators ? "Edit Orchestrator" : "Add New Orchestrator"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col>
              <InputField
                label="Name"
                type="text"
                name="name"
                value={formData.name}
                handleChange={handleInputChange}
              />
            </Col>
            <Col>
              <Form.Group controlId="category">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  value={formData?.category?._id || formData?.category}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Type</option>
                  {categories?.orchestrator?.map((type) => (
                    <option key={type._id} value={type._id}>
                      {type.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Card className="my-3">
            <Card.Body>
              <Form.Group as={Row} controlId="headends">
                <Form.Label column sm="2">
                  Headends
                </Form.Label>
                <Col sm="10">
                  <ReactSelect
                    isMulti
                    options={headendOptions}
                    value={headendOptions?.filter((option) =>
                      formData?.headends?.includes(option.value)
                    )}
                    onChange={handleHeadendChange}
                    classNamePrefix="select"
                  />
                </Col>
              </Form.Group>
            </Card.Body>
          </Card>

          {formData.nodes.map((node, nodeIndex) => (
            <NodesCard
              key={nodeIndex}
              node={node}
              nodeIndex={nodeIndex}
              handleDeleteNode={handleDeleteNode}
              handleInputChange={handleInputChange}
              handleAddHost={handleAddHost}
              editHost={editHost}
            />
          ))}

          <Modal.Footer>
            <Button variant="primary" type="submit">
              Save Orchestrator
            </Button>
          </Modal.Footer>
        </Form>
        <HostForm
          host={hostToEdit}
          showModal={showEditModal}
          closeModal={() => setShowEditModal(false)}
          onSave={onSaveHost}
          updateHostDetails={onSaveHost}
          isEdit={Boolean(hostToEdit?._id)}
        />
      </Modal.Body>
    </Modal>
  );
};

export default OrchestratorsForm;
