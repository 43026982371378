// HeadendDirectorsForm.js
import React, { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { DIRECTOR_INIT } from "constants/headendInit";
import deepClone from "utils/deepClone";
import { PaginatedTable } from "components";
import HostForm from "../../Host/AddForm";
import COLUMNS from "constants/columns";

const HeadendDirectorsForm = ({ directors, updateFormData, isEdit }) => {
  const [showModal, setShowModal] = useState(false);
  const [hostToEdit, setEditToHost] = useState(null);
  const { regions } = useSelector((state) => state.dashboard);

  const addDirector = () => {
    updateFormData([...directors, { ...DIRECTOR_INIT, isPrimary: false }]);
  };

  const onEdit = (item) => {
    setEditToHost(item);
    setShowModal(true);
  };

  const updateHost = (hostData) => {
    const updatedDirectors = deepClone(directors);
    const index = updatedDirectors.findIndex(
      (dir) => dir?.host.name === hostData.name
    );
    if (index > -1) {
      updatedDirectors[index].host = hostData;
    }
    updateFormData(updatedDirectors);
  };

  const directorsData = useMemo(() => {
    return directors?.map((director) => {
      return {
        ...director.host,
        id: director?.host._id,
        primary: director?.host?.isPrimary ? "Primary" : "Secondary",
        name: director?.host?.name,
        region: regions.find((region) => region._id === director?.host?.region)
          ?.name,
        fqdn: director?.host.fqdn,
      };
    });
  }, [directors]);

  return (
    <div>
      <PaginatedTable
        data={directorsData}
        columns={COLUMNS["director"]}
        itemsPerPage={25}
        onEdit={onEdit}
      />

      {!isEdit && (
        <Button variant="primary" size="sm" onClick={addDirector}>
          + Director
        </Button>
      )}

      <HostForm
        host={hostToEdit}
        removeHost={setEditToHost}
        showModal={showModal}
        isEdit={true}
        updateHostDetails={updateHost}
        closeModal={() => {
          setEditToHost(null);
          setShowModal(false);
        }}
      />
    </div>
  );
};

export default HeadendDirectorsForm;
