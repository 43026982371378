import deepClone from "./deepClone";

// Function to handle sorting logic
const sortData = (sData) =>
  deepClone(sData).sort((a, b) => {
    if (a.status === "not enabled") return -1;
    if (b.status === "not enabled") return 1;
    if (a.mismatch && !b.mismatch) return -1;
    if (!a.mismatch && b.mismatch) return 1;
    return 0;
  });

// Function to handle formatting of table data
const formatTableData = (tData) => {
  const sortedData = sortData(tData);

  return sortedData.map((fData) => ({
    ...fData,
    status: fData.status === "enabled" ? "success" : "failed",
    // fData.status === "enabled"
    //   ? fData?.mismatch
    //     ? "mismatch"
    //     : "success"
    //   : "failed",
    // parameters_monitored: (
    //   <ul className="m-0 p-0">
    //     {fData.parameters_monitored.map((param, idx) => (
    //       <li key={idx} className="ellipsis">
    //         <strong>{param.parameter}</strong> - <br />
    //         Threshold: {param.threshold} ({param.monitoring_type})
    //       </li>
    //     ))}
    //   </ul>
    // ),
    // mismatch: fData?.mismatch && (
    //   <ul className="m-0 p-0">
    //     {Object.entries(fData?.mismatch).map(([key, val], idx) => (
    //       <li key={idx} className="ellipsis">
    //         <span>{key}</span>
    //         <br />
    //         <b>{val}</b>
    //       </li>
    //     ))}
    //   </ul>
    // ),
  }));
};

export default formatTableData;
