// HeadendAnalyticsForm.js
import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { ANALYTICS_INIT } from "constants/headendInit";
import HostForm from "../../Host/AddForm";
import { Host } from "stores/actions";
import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";

const HeadendAnalyticsForm = ({ analytics, updateFormData, isEdit }) => {
  const [showModal, setShowModal] = useState(false);
  const [hostToEdit, setEditToHost] = useState(null);

  const onEdit = (host) => {
    setEditToHost(host);
    setShowModal(true);
  };

  const addAnalytics = () => {
    updateFormData([...analytics, ANALYTICS_INIT]);
  };

  // const processAnalyitcNode

  return (
    <div>
      {analytics?.map((analytic, analyticsIndex) => (
        <Card key={analyticsIndex} className="mb-3">
          <Card.Header>
            <b>{analytic.name || `Analytics ${analyticsIndex + 1}`}</b>
          </Card.Header>
          <Card.Body>
            <PaginatedTable
              data={analytic?.nodes}
              columns={COLUMNS["analytics"]}
              itemsPerPage={25}
              onEdit={onEdit}
            />
          </Card.Body>
        </Card>
      ))}
      {!isEdit && (
        <Button size="sm" variant="primary" onClick={addAnalytics}>
          + Analytics
        </Button>
      )}

      <HostForm
        host={hostToEdit}
        removeHost={setEditToHost}
        showModal={showModal}
        isEdit={true}
        updateHostDetails={Host.updateHost}
        closeModal={() => {
          setEditToHost(null);
          setShowModal(false);
        }}
      />
    </div>
  );
};

export default HeadendAnalyticsForm;
