import React from "react";

const ShimmerInfraCard = () => {
  return (
    <div>
      {/* First row with two shimmer texts side-by-side */}
      <div
        className="shimmer-card"
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: 10,
        }}
      >
        <div
          className="shimmer-text"
          style={{ height: "100px", backgroundColor: "#f0f0f0" }}
        ></div>
        <div
          className="shimmer-text"
          style={{ height: "100px", backgroundColor: "#f0f0f0" }}
        ></div>
      </div>
    </div>
  );
};

export default ShimmerInfraCard;
