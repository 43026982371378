import React, { useEffect, useState } from "react";
import { Card, Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import capitalize from "utils/capitalize";
import DeviceTableView from "./DeviceTableView";

const AppliancesView = ({ appliances }) => {
  const [devices, setDevices] = useState({
    versaDevice: [],
    tenantDevice: [],
  });

  useEffect(() => {
    let versaDevice = [],
      tenantDevice = [];

    appliances.forEach(({ host, _id }) => {
      const isVersaManaged =
        ["Versa", "VERSA", "Provider"].includes(host?.ownerOrgName) ||
        host?.ownerOrgName?.includes("VERSA") ||
        host?.name?.includes("VERSA");

      const data = {
        host: {
          ...host,
          id: _id,
          name: host?.name,
          ownerOrg: host?.ownerOrgName,
          type: capitalize(host?.type),
          bundleVersion: host?.appIdDetails?.appIdInstalledBundleVersion,
        },
      };
      if (isVersaManaged) versaDevice.push(data);
      else tenantDevice.push(data);
    });
    setDevices({
      versaDevice,
      tenantDevice,
    });
  }, [appliances]);

  return (
    <>
      <div className="my-3">
        <h5>
          <b>Appliances</b>
        </h5>
        <small className="sub-info">List of all Appliances</small>
      </div>
      <Card>
        <Card.Body>
          <Tabs
            defaultActiveKey="tenant"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab
              eventKey="versa"
              title={`Versa Appliances(${devices?.versaDevice.length})`}
            >
              <DeviceTableView
                data={devices?.versaDevice}
                type=""
                columnsKey="appliance"
                title="Versa Devices"
              />
            </Tab>
            <Tab
              eventKey="tenant"
              title={`Tenant Appliances(${devices?.tenantDevice.length})`}
            >
              <DeviceTableView
                data={devices?.tenantDevice}
                type=""
                columnsKey="appliance"
                title="Tenant Devices"
              />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

export default AppliancesView;
