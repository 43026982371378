// slices/tenantSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { Profile } from "../actions";

const profileSlice = createSlice({
  name: "profiles",
  initialState: {
    profiles: [], // Initial state for tenants
    profileItems: [],
    loading: {
      fetchProfiles: false,
    },
    error: null,
    orgSummary: {},
  },

  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Profile.fetchProfiles.pending, (state) => {
        state.loading["fetchProfiles"] = true;
        state.error = null;
      })
      .addCase(Profile.fetchProfiles.fulfilled, (state, action) => {
        state.loading["fetchProfiles"] = false;
        state.profiles = action.payload;
      })
      .addCase(Profile.fetchProfiles.rejected, (state, action) => {
        state.loading["fetchProfiles"] = false;
        state.error = action.payload;
      })

      .addCase(Profile.createProfile.pending, (state) => {
        state.loading["createProfile"] = true;
        state.error = null;
      })
      .addCase(Profile.createProfile.fulfilled, (state, action) => {
        state.loading["createProfile"] = false;
        state.profiles = [action.payload, ...state.profiles];
      })
      .addCase(Profile.createProfile.rejected, (state, action) => {
        state.loading["createProfile"] = false;
        state.error = action.payload;
      })

      .addCase(Profile.fetchProfileItems.pending, (state) => {
        state.loading["fetchProfiles"] = true;
        state.error = null;
      })
      .addCase(Profile.fetchProfileItems.fulfilled, (state, action) => {
        state.loading["fetchProfiles"] = false;
        state.profileItems = action.payload;
      })
      .addCase(Profile.fetchProfileItems.rejected, (state, action) => {
        state.loading["fetchProfiles"] = false;
        state.error = action.payload;
      })

      .addCase(Profile.createProfileItem.pending, (state) => {
        state.loading["createProfileItem"] = true;
        state.error = null;
      })
      .addCase(Profile.createProfileItem.fulfilled, (state, action) => {
        state.loading["createProfileItem"] = false;
        state.profileItems = [action.payload, ...state.profileItems];
      })
      .addCase(Profile.createProfileItem.rejected, (state, action) => {
        state.loading["createProfileItem"] = false;
        state.error = action.payload;
      });
  },
});

export const {} = profileSlice.actions;

export default profileSlice.reducer;
