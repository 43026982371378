import MonitoringProfileView from "pages/Monitoring/Components/Profile/ProfileSingleView";
import React, { useState, useEffect } from "react";
import { Button, Col, Row, Modal, Form, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { saveHeadend } from "stores/actions";

const HeadendMonitor = () => {
  const dispatch = useDispatch();
  const [showManageModal, setShowManageModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [chosenProfiles, setChosenProfiles] = useState([]); // Profiles chosen by the user
  const [selectedProfile, setSelectedProfile] = useState({});
  const { profiles } = useSelector((state) => state.profiles); // All available profiles
  const { headend } = useSelector((state) => state.headend); // Current headend

  // Initialize chosen profiles based on populated `headend.profiles`
  useEffect(() => {
    if (headend?.profiles?.length) {
      setChosenProfiles(headend.profiles); // Use the populated profiles directly
    }
  }, [headend]);

  const handleToggleProfile = (profileId) => {
    // Toggle profile selection
    if (chosenProfiles.some((profile) => profile._id === profileId)) {
      setChosenProfiles(
        chosenProfiles.filter((profile) => profile._id !== profileId)
      );
    } else {
      const profileToAdd = profiles.find(
        (profile) => profile._id === profileId
      );
      setChosenProfiles([...chosenProfiles, profileToAdd]);
    }
  };

  const handleSaveProfiles = async () => {
    // Dispatch save action with updated chosen profiles
    await dispatch(
      saveHeadend({
        _id: headend?._id,
        profiles: chosenProfiles.map((profile) => profile._id), // Send only the IDs back
      })
    );
    setShowManageModal(false); // Close modal
  };

  return (
    <>
      <Row className="mb-3">
        <Col>
          <div>
            <h5>
              <b>Profiles</b>
            </h5>
            <small className="sub-info">Manage monitoring profiles</small>
          </div>
        </Col>
        <Col className="col-auto">
          <Button onClick={() => setShowManageModal(true)}>
            MANAGE PROFILE
          </Button>
        </Col>
      </Row>
      <Row>
        {headend?.profiles?.map((profile) => (
          <Col md={4} className="mb-3">
            <Card
              className="hover-item"
              key={profile._id}
              onClick={() => {
                setSelectedProfile(profile);
                setShowProfileModal(true);
              }}
            >
              <Card.Body className="py-2">{profile.name}</Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Manage Profile Modal */}
      <Modal
        show={showManageModal}
        onHide={() => setShowManageModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Manage Monitoring Profiles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="select-list">
            {profiles.map((profile) => (
              <li
                key={profile._id}
                onClick={() => handleToggleProfile(profile._id)}
                className={`m-2 ${
                  chosenProfiles.some((p) => p._id === profile._id)
                    ? "bg-primary text-white"
                    : "bg-light"
                }`}
              >
                <Form.Check
                  type="checkbox"
                  className="float-start"
                  checked={chosenProfiles.some((p) => p._id === profile._id)}
                />
                {profile.name}
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowManageModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveProfiles}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showProfileModal}
        onHide={() => setShowProfileModal(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Profile Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MonitoringProfileView selectedProfile={selectedProfile} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HeadendMonitor;
