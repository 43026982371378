import React, { useMemo, useState } from "react";
import { Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { VMS_INIT } from "constants/headendInit";
import HostForm from "../../Host/AddForm";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import deepClone from "utils/deepClone";
import { Host } from "stores/actions";
import { HARDWARE_INIT as hardware } from "constants/headendInit";

const HeadendVmsForm = ({ vms, updateFormData, isEdit }) => {
  const host = useSelector((state) => state.categories.categories.host);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [hostToEdit, setEditToHost] = useState(null);

  // Memoized VMS data for rendering the table
  const vmsData = useMemo(() => {
    return vms?.map((vm) => {
      const categoryMatch = host?.find(
        (category) => category?._id === vm?.host?.category
      );
      return {
        ...vm?.host,
        id: vm?.host?._id,
        name: vm?.host?.name,
        type: categoryMatch?.name || vm?.host?.category.name,
      };
    });
  }, [vms, host]);

  const handleVmsChange = (index, e) => {
    const { name, value } = e.target;

    // Check if the field is nested under 'hardware'
    const [parent, child] = name.split(".");
    const updatedVms = deepClone(vms);

    if (parent === "hardware") {
      updatedVms[index].host.hardware[child] = value; // Update nested field
    } else {
      updatedVms[index][name] = value; // Update top-level field
    }

    updateFormData(updatedVms);
  };

  // Add a new VMS
  const addVms = () => {
    updateFormData([...vms, VMS_INIT]);
  };

  // Edit VMS
  const onEdit = (item) => {
    const selected = vms.find((vm) => item.id === vm?.host?._id);

    if (!selected) {
      return;
    }

    const data = {
      ...selected,
      host: {
        ...selected.host,
        ...hardware,
        hardware: {
          ...hardware, // Default fields
          ...selected?.host?.hardware, // Merge existing fields
        },
      },
    };

    setEditToHost(data);
    setShowModal(true);
  };

  const updateHostDetails = async (hostData) => {
    const updatedVms = deepClone(vms);
    const index = updatedVms.findIndex(
      (vm) => vm?.host?.name === hostData.name
    );

    if (index > -1) {
      const updatedHost = await dispatch(Host.updateHost(hostData));

      updatedVms[index].host = {
        ...updatedVms[index].host, // Preserve existing fields
        ...updatedHost, // Update new fields
        hardware: {
          // ...updatedVms[index].host.hardware, // Preserve existing hardware fields
          ...updatedHost.hardware, // Update new hardware fields
        },
      };

      updateFormData(updatedVms);
    }
  };

  return (
    <Container>
      <PaginatedTable
        data={vmsData}
        columns={COLUMNS["vms"]}
        itemsPerPage={10}
        onEdit={onEdit}
      />
      {!isEdit && (
        <div className="mt-3">
          <Button size="sm" variant="primary" onClick={addVms}>
            + Add VMS
          </Button>
        </div>
      )}
      <HostForm
        host={hostToEdit?.host}
        removeHost={setEditToHost}
        showModal={showModal}
        isEdit={true}
        updateHostDetails={updateHostDetails}
        closeModal={() => {
          setEditToHost(null);
          setShowModal(false);
        }}
      />
    </Container>
  );
};

export default HeadendVmsForm;
