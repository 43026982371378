import { Alert, Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onBoardExisting } from "stores/actions";
import { MultiSelectSearch } from "components";
import { showAlert } from "stores/slices/alertSlice";

const Onboard = ({ redirectToManage }) => {
  const [showModal, setShowModal] = useState(false);
  const [onBoarding, setOnboarding] = useState(false);
  const { categories } = useSelector((state) => state.categories);
  const { error } = useSelector((state) => state.headend);
  const [formData, setFormData] = useState({
    ip: "",
    code: "",
    name: "",
    username: "",
    password: "",
    category: null,
  });

  const dispatch = useDispatch();

  const handleFormdata = (e) => {
    if (e.target) {
      const { name, value, field } = e.target;
      let val = name === "code" ? value.toUpperCase() : value;
      setFormData((prev) => ({
        ...prev,
        [field || name]: val,
      }));
    } else {
      // Handle category change from MultiSelectSearch
      setFormData((prev) => ({
        ...prev,
        category: e?.value || null, // Set category as the selected value's ID
      }));
    }
  };

  const handleSubmit = async () => {
    setOnboarding(true);

    try {
      const base_url = `${!formData.ip.includes("http") ? "https://" : ""}${
        formData.ip
      }`;

      const data = {
        url: base_url.trim(),
        headendCode: formData.code.trim(),
        headendName: formData.name.trim(),
        username: formData.username.trim(),
        password: formData.password.trim(),
        category: categories?.headend?.find(
          (cat) => cat._id === formData.category
        )?.name,
      };

      const headendData = await dispatch(onBoardExisting(data));
      console.log("headendData", headendData);
      setOnboarding(false);
      if (headendData?.payload?.error) {
        // alert(headendData?.payload?.error);
        dispatch(
          showAlert({
            message: headendData?.payload?.error,
            variant: "danger",
          })
        );
      } else {
        // redirectToManage(headendData?.payload._id);
        redirectToManage("redirect-onboard", {
          id: headendData?.payload?.headend?._id,
          name:
            headendData?.payload?.headend?.code ||
            headendData?.payload?.headend?.name,
        }); //Need to change it
      }
    } catch (error) {
      console.error("Error during login", error);
      // Handle login error
    }
  };

  function handleClose() {
    setShowModal(false);
  }

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Onboard Headend</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form>
                <Row>
                  <Col md={3}>
                    <Form.Group controlId="msCode" className="mb-3">
                      <Form.Label>MS Code:</Form.Label>
                      <Form.Control
                        type="text"
                        name="code"
                        className="p-2"
                        value={formData.code}
                        onChange={handleFormdata}
                        placeholder="MS:..."
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={9}>
                    <Form.Group controlId="ip" className="mb-3">
                      <Form.Label>FQDN/IP:</Form.Label>
                      <Form.Control
                        type="text"
                        name="ip"
                        className="p-2"
                        value={formData.ip}
                        onChange={handleFormdata}
                        placeholder="Provide director IP"
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="headendName" className="mb-3">
                      <Form.Label>Headend Name:</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        className="p-2"
                        value={formData.name}
                        onChange={handleFormdata}
                        placeholder="Name"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="category">
                      <Form.Label>Category:</Form.Label>
                      <MultiSelectSearch
                        options={categories?.headend}
                        isMulti={false}
                        selectedOption={categories?.headend?.find(
                          (cat) => cat._id === formData.category
                        )}
                        onChange={(data) => handleFormdata(data)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="username" className="mb-3">
                      <Form.Label>Username:</Form.Label>
                      <Form.Control
                        type="text"
                        name="username"
                        className="p-2"
                        value={formData.username}
                        onChange={handleFormdata}
                        placeholder="Enter username"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="password" className="mb-3">
                      <Form.Label>Password:</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        className="p-2"
                        value={formData.password}
                        onChange={handleFormdata}
                        placeholder="Enter Password"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Button
                  className=" w-100 mb-3 mt-3 p-2"
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={onBoarding}
                >
                  {onBoarding ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {"  "}
                      Onboarding...
                    </>
                  ) : (
                    "Onboard"
                  )}
                </Button>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Button
        className="text-center"
        variant="secondary"
        onClick={() => setShowModal(true)}
      >
        + Onboard
      </Button>
      {error?.onboard && <Alert variant="danger">{error?.onboard}</Alert>}
    </>
  );
};

export default Onboard;
