import React from "react";
import PropTypes from "prop-types";

const Highlight = ({ text, highlight }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }

  const regex = new RegExp(`(${highlight})`, "gi");
  const parts = text?.split(regex);

  return (
    <span className="capitalize">
      {parts?.map((part, index) =>
        part?.toLowerCase() === highlight.toLowerCase() ? (
          <strong key={`hightlight-${index}`} className="highlight">
            {part}
          </strong>
        ) : (
          part
        )
      )}
    </span>
  );
};

Highlight.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
};

export default Highlight;
