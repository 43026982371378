import React, { useState } from "react";
import { Card, Col, Row, Tab, Tabs, Modal } from "react-bootstrap";
import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";
import deepClone from "utils/deepClone";
import formatTableData from "utils/monitorTableFormat";
import MonitorStatusCards from "./MonitorStatusCard";
import TABS from "constants/tabs";
import capitalize from "utils/capitalize";
import { useSelector } from "react-redux";
import MonitoringProfileItemView from "./ProfileItems/ProfileSingleView";

const MonitorSingleView = ({ data, dataKey, setDataKey }) => {
  const { profileItems } = useSelector((state) => state.profiles);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState({});

  const onView = (item) => {
    console.log(profileItems, item);
    const profile = profileItems.find((profile) => profile.name === item?.name);
    console.log(profile);
    setSelectedProfile(profile);
    setShowProfileModal(true);
  };
  // General component to render each Tab with its respective data
  const renderTabContent = (tabKey, title, dataKey, description) => (
    <>
      <Row className="mb-3">
        <Col md={8}>
          <h5>
            <b>{title} report</b>
          </h5>
          <small className="sub-info">{description}</small>
        </Col>
        <Col>
          <MonitorStatusCards
            data={(data && data[dataKey]) || []}
            dataKey={dataKey}
          />
        </Col>
      </Row>

      <PaginatedTable
        columns={COLUMNS["monitoringAudit"]}
        data={data?.[dataKey] ? formatTableData(data?.[dataKey]) : []}
        onView={onView}
      />
    </>
  );

  return (
    <>
      <Card>
        <Card.Body className="py-1">
          <Tabs
            id="monitor-tabs"
            activeKey={dataKey}
            onSelect={(k) => setDataKey(k)}
            className="mb-3"
          >
            {Array.isArray(TABS["headend"]) &&
              TABS["headend"].map((item) =>
                data && data[item] ? (
                  <Tab eventKey={item} title={capitalize(item)} key={item}>
                    {renderTabContent(
                      item,
                      capitalize(item),
                      item,
                      `All audit related to ${item} monitoring`
                    )}
                  </Tab>
                ) : null
              )}
          </Tabs>
        </Card.Body>
      </Card>
      <Modal
        show={showProfileModal}
        onHide={() => setShowProfileModal(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Profile Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MonitoringProfileItemView profile={selectedProfile} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MonitorSingleView;
