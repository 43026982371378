// HeadendControllersForm.js
import React, { useEffect, useMemo, useState } from "react";
import {
  Accordion,
  Badge,
  Button,
  Card,
  Col,
  Form,
  Row,
} from "react-bootstrap";
import { CONTROLLER_INIT } from "constants/headendInit";
import HostForm from "../../Host/AddForm";
import deepClone from "utils/deepClone";
import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";

const HeadendControllersForm = ({
  controllers,
  updateFormData,
  updateHostInformation,
  isEdit,
  organisations,
  isShared,
}) => {
  const [selectedOption, setSelectedOption] = useState();
  const [showModal, setShowModal] = useState(false);
  const [hostToEdit, setEditToHost] = useState(null);

  useEffect(() => {
    setSelectedOption({
      ownerOrg: selectedOption?.ownerOrg?.label
        ? selectedOption?.ownerOrg
        : controllers?.ownerOrg,
    });
  }, [controllers]);

  const findOrganisation = (orgId) => {
    return organisations.find((org) => org._id === orgId);
  };

  const handleSelect = (index, e) => {
    const { value, field } = e.target;
    const updatedControllers = [...controllers];
    updatedControllers[index] = {
      ...updatedControllers[index],
      [field]: value,
    };
    const updatedSelectedOptions = {
      ...selectedOption,
      [field]: e.target,
    };
    setSelectedOption(updatedSelectedOptions);
    updateFormData(updatedControllers);
  };

  const handleControllerChange = (index, e) => {
    const { name, value } = e.target;
    const updatedControllers = [...controllers];
    updatedControllers[index] = { ...updatedControllers[index], [name]: value };
    updateFormData(updatedControllers);
  };

  const handleOrgsChanges = (index, e, orgIndex) => {
    const { value, name } = e.target;
    const clonedControllers = deepClone(controllers);
    const orgsMapping = { ...clonedControllers[index]?.orgMapping[orgIndex] };
    orgsMapping[name] = value;
    clonedControllers[index].orgMapping[orgIndex] = orgsMapping;
    updateFormData(clonedControllers);
  };

  const addController = () => {
    updateFormData([...controllers, CONTROLLER_INIT]);
  };

  const onEdit = (item) => {
    setEditToHost(item);
    setShowModal(true);
  };

  const updateHost = (hostData) => {
    const updatedControllers = deepClone(controllers);
    const index = updatedControllers.findIndex(
      (dir) => dir?.host.name === hostData.name
    );
    if (index > -1) {
      updatedControllers[index].host = hostData;
    }
    updateFormData(updatedControllers);
  };

  const controllerData = useMemo(() => {
    return controllers?.map((controller) => {
      return {
        ...controller.host,
        id: controller._id,
        name: controller?.host?.name,
        ip: controller?.host?.ipAddress,
        region: controller?.host?.location,
        bundleVersion:
          controller?.host?.appIdDetails?.appIdInstalledBundleVersion,
      };
    });
  }, [controllers]);

  return (
    <div>
      <PaginatedTable
        data={controllerData}
        columns={COLUMNS["controller"]}
        itemsPerPage={25}
        onEdit={onEdit}
      />
      {/* {controllers?.map((controller, index) => (
        <Card key={index} className="mb-3">
          <Card.Header>
            {controller?.host?.name || `Controller ${index + 1}`}{" "}
          </Card.Header>
          <Card.Body>
            <Form>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Host:</Form.Label>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          name="name"
                          value={controller?.host?.name}
                          className="disabled"
                          disabled={true}
                        />
                      </Col>
                      <Col sm={2}>
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="link"
                          onClick={() => onEdit(controller?.host)}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group>
                    <Form.Label>IP:</Form.Label>
                    <Form.Control
                      type="text"
                      name="ip"
                      value={controller.ip}
                      onChange={(e) => handleControllerChange(index, e)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={`organisations[${index}].org`}>
                    <Form.Label>Organisation:</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={controller?.host?.ownerOrgName}
                      className="disabled"
                      disabled={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Accordion flush>
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    Tenants :
                    <Badge pill bg="info">
                      {controller?.orgMapping?.length}
                    </Badge>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col>
                        <b>Tenants</b>
                      </Col>
                      <Col>
                        <b>Remote ID</b>
                      </Col>
                      <Col>
                        <b>Remote Key</b>
                      </Col>
                      <Col>
                        <b>Local ID</b>
                      </Col>
                      <Col>
                        <b>Local Key</b>
                      </Col>
                    </Row>
                    {controller?.orgMapping?.map((org, orgIndex) => (
                      <>
                        <Row className="controller-org mt-2">
                          <Col>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                name="remoteId"
                                value={findOrganisation(org.orgId)?.name}
                                disabled={true}
                              />
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                name="remoteId"
                                value={org?.remoteId}
                                onChange={(e) =>
                                  handleOrgsChanges(index, e, orgIndex)
                                }
                              />
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                name="remoteKey"
                                value={org?.remoteKey}
                                onChange={(e) =>
                                  handleOrgsChanges(index, e, orgIndex)
                                }
                              />
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                name="localId"
                                value={org?.localId}
                                onChange={(e) =>
                                  handleOrgsChanges(index, e, orgIndex)
                                }
                              />
                            </Form.Group>
                          </Col>

                          <Col>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                name="localKey"
                                value={org?.localKey}
                                onChange={(e) =>
                                  handleOrgsChanges(index, e, orgIndex)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Form>
          </Card.Body>
        </Card>
      ))} */}
      {!isEdit && (
        <Button size="sm" variant="primary" onClick={addController}>
          + Controller
        </Button>
      )}
      <HostForm
        host={hostToEdit}
        removeHost={setEditToHost}
        showModal={showModal}
        isEdit={true}
        updateHostDetails={updateHost}
        closeModal={() => {
          setEditToHost(null);
          setShowModal(false);
        }}
      />
    </div>
  );
};

export default HeadendControllersForm;
