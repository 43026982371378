import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import useActivityLogger from "hooks/useActivity";
import { fetchMonitoringAuditReport, Profile } from "stores/actions";
import MonitoringAuditReport from "./Tabs/MonitoringAuditReport";
import MonitoringProfileTab from "./Tabs/MonitoringProfiles";
import { usePermissions } from "hooks/usePermission";

const Monitoring = () => {
  const dispatch = useDispatch();
  const hasProfileViewPermission = usePermissions(["MONITOR_PROFILE_VIEW"]);
  const [key, setKey] = useState(
    localStorage.getItem("monitoring_page") || "audit"
  );

  const logActivity = useActivityLogger();
  useEffect(() => {
    logActivity({
      page: "audit",
      activity: "page-load",
    });

    dispatch(fetchMonitoringAuditReport("all"));
    dispatch(Profile.fetchProfileItems("monitoring"));
  }, []);

  const handleKey = (key) => {
    setKey(key);
    localStorage.setItem("monitoring_page", key);
  };

  return (
    <>
      {/* <Row>
        <Col>
          <div className="mt-1">
            <h5>
              <b>Monitoring Audit Report</b>
            </h5>
            <small className="sub-info">Check latest report</small>
          </div>
        </Col>
      </Row> */}

      <div className="middle-section">
        <div className="my-2">
          <h5>
            <b>Monitoring</b>
          </h5>
          <small className="sub-info">
            All monitoring related details are available here
          </small>
        </div>
        <Card className="mt-3">
          <Card.Body className="py-1">
            <Tabs
              id="controlled-tabs"
              activeKey={key}
              onSelect={handleKey}
              className="mb-3 p-0"
            >
              <Tab eventKey="audit" title="AUDIT">
                <MonitoringAuditReport />
              </Tab>
              {hasProfileViewPermission && (
                <Tab eventKey="profile" title="PROFILES">
                  <MonitoringProfileTab />
                </Tab>
              )}
            </Tabs>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default Monitoring;
