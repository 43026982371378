import { Card, Col, Modal, Offcanvas, Row } from "react-bootstrap";
import HostView from "./HostView";
import COLUMNS from "constants/columns";
import { PaginatedTable } from "components";

const SingleView = ({
  host,
  removeHost,
  showModal,
  closeModal,
  orgMapping = [],
}) => {
  return (
    <>
      <Offcanvas
        show={showModal}
        onHide={closeModal}
        placement="end"
        style={{ width: "35%" }}
      >
        <Offcanvas.Header className="bg-light" closeButton>
          <Offcanvas.Title>
            <b>{host?.name}</b>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <HostView host={host} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default SingleView;
