import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, Modal, Form, Button } from "react-bootstrap";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { fetchFeedbacks, updateFeedback } from "stores/actions";

import { showAlert } from "stores/slices/alertSlice";
import MESSAGES from "constants/messages";
import { usePermissions } from "hooks/usePermission";

const Feedbacks = () => {
  const dispatch = useDispatch();
  const { feedbacks } = useSelector((state) => state.dashboard);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [currentFeedback, setCurrentFeedback] = useState({});
  const [adminComment, setAdminComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for submission
  const hasEditPermission = usePermissions(["FEEDBACKS_EDIT"]);

  // Format the feedback data for the table
  const formattedData = feedbacks.map((feedback) => ({
    ...feedback,
    adminCommentsFormatted:
      feedback.comments?.length > 0
        ? feedback.comments.map((c) => c.message).join(", ")
        : "No comments available",
    markAsCompletedCheckbox: hasEditPermission ? (
      <input
        type="checkbox"
        checked={feedback.currentStatus === "completed"}
        onChange={() => handleMarkAsCompleted(feedback)}
      />
    ) : (
      feedback.currentStatus
    ),
  }));

  useEffect(() => {
    dispatch(fetchFeedbacks());
  }, [dispatch]);

  const handleMarkAsCompleted = async (feedback) => {
    const newStatus =
      feedback.currentStatus === "completed" ? "open" : "completed";

    try {
      // Dispatch the updateFeedback action with the new status
      await dispatch(
        updateFeedback({
          id: feedback._id,
          updateData: { currentStatus: newStatus }, // Use the newStatus variable
        })
      ).unwrap(); // Ensure promise rejection is handled

      // Fetch updated feedbacks after the status change
      dispatch(fetchFeedbacks());

      // Display success alert based on the new status
      const alertMessage =
        newStatus === "completed"
          ? MESSAGES["feedback-completed-sc"]
          : MESSAGES["feedback-open-sc"];
      dispatch(
        showAlert({
          message: alertMessage,
          variant: "success",
        })
      );
    } catch (error) {
      // Display error alert if the update fails
      dispatch(
        showAlert({
          message: MESSAGES["feedback-status-fa"],
          variant: "danger",
        })
      );
    }
  };

  const handleAddComment = (feedback) => {
    setCurrentFeedback(feedback);
    setAdminComment("");
    setShowCommentModal(true);
  };

  const handleCommentChange = (e) => {
    setAdminComment(e.target.value);
  };

  const handleSaveComment = () => {
    if (isSubmitting || adminComment.trim().length === 0) return;

    setIsSubmitting(true);
    dispatch(
      updateFeedback({
        id: currentFeedback._id,
        updateData: {
          comments: { message: adminComment, createdAt: new Date() },
        },
      })
    )
      .then(() => {
        setShowCommentModal(false);
        dispatch(fetchFeedbacks());
        dispatch(
          showAlert({
            message: MESSAGES["comment-added-sc"],
            variant: "success",
          })
        );
      })
      .catch(() => {
        dispatch(
          showAlert({
            message: MESSAGES["comment-added-fa"],
            variant: "danger",
          })
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleCloseCommentModal = () => {
    setShowCommentModal(false);
  };

  return (
    <>
      <div className="middle-section">
        <div className="mb-3">
          <h5>
            <b>Feedbacks</b>
          </h5>
          <small className="sub-info">
            All feedbacks for the portal will be available here
          </small>
        </div>
        <Row className="mt-2">
          <Col>
            <Card>
              <Card.Body>
                <PaginatedTable
                  columns={COLUMNS["feedbacks"]}
                  data={formattedData}
                  onEdit={hasEditPermission ? handleAddComment : null}
                  rowStyle={(row) =>
                    row.currentStatus === "completed"
                      ? {
                          textDecoration: "line-through",
                        }
                      : {}
                  }
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Admin Comment Modal */}
        <Modal show={showCommentModal} onHide={handleCloseCommentModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Admin Comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="adminCommentForm.ControlTextarea">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={adminComment}
                  onChange={handleCommentChange}
                  placeholder="Enter your comment here..."
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCommentModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSaveComment}
              disabled={isSubmitting || adminComment.trim().length === 0}
            >
              {isSubmitting ? "Saving..." : "Save Comment"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Feedbacks;
