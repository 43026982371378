import { Button, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import useActivityLogger from "hooks/useActivity";
import MaintenanceAddForm from "./AddForm";
import MaintenanceList from "./List";
import { usePermissions } from "hooks/usePermission";

const Maintenance = () => {
  const [show, setShow] = useState(false);
  const hasAddPermission = usePermissions(["NOTIFICATION_ADD"]);
  const logActivity = useActivityLogger();
  useEffect(() => {
    logActivity({
      page: "notification",
      activity: "page-load",
    });
  }, []);

  return (
    <div className="middle-section">
      <Row>
        <Col>
          <div className="my-2">
            <h5>
              <b>Scheduled Notifications</b>
            </h5>
            <small className="sub-info">
              All maintenance related reports are available here
            </small>
          </div>
        </Col>
        <Col className="col-auto">
          {hasAddPermission && (
            <Button
              className="text-center"
              variant="primary"
              onClick={() => setShow(true)}
            >
              Add new notification
            </Button>
          )}
        </Col>
      </Row>
      <MaintenanceList />
      {hasAddPermission && (
        <MaintenanceAddForm show={show} handleClose={() => setShow(false)} />
      )}
    </div>
  );
};

export default Maintenance;
