import TEMPLATES from "constants/templates";

const maintenanceTemplate = (content) => `
    <!DOCTYPE html>
    <html>
    <head>
        <style>
            body {
                font-family: Arial, sans-serif;
                line-height: 1.6;
                margin: 0;
                padding: 0;
            }
            .container {
               
                border: 1px solid #e0e0e0;
                border-radius: 5px;
                padding: 20px;
                background-color: #f9f9f9;
            }
            .email-header {
                text-align: center;
            }
            .email-header h1 {
                font-size: 24px;
                color: #003366;
                margin: 10px 0;
            }
            .content {
                margin: 20px 0;
            }
            .content p {
                margin: 10px 0;
                color: #333;
            }
            .table-container {
                margin: 20px 0;
            }
            table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 20px;
                margin-top: 10px;
            }
            th, td {
                border: 1px solid #e0e0e0;
                padding: 10px;
                text-align: left;
            }
            th {
                background-color: #003366;
                color: white;
            }
            .footer {
                margin-top: 20px;
                font-size: 12px;
                color: #666;
            }
            .footer a {
                color: #0066cc;
                text-decoration: none;
            }
            .contact {
                background-color: #003366;
                color: white;
                padding: 10px;
                border-radius: 5px;
            }
            .contact p {
                margin-top: 2px;
            }
            .contact a {
                color: #ffffff;
                text-decoration: none;
            }
            .w-100{
                width:100% !important;
               
                display:block;
            }
        </style>
    </head>
    <body>
        <div class="container">
            ${TEMPLATES["maintenance"]["header"]}
            ${content}
            ${TEMPLATES["maintenance"]["footer"]}
        </div>
    </body>
    </html>`;

export default maintenanceTemplate;
