import { createAsyncThunk } from "@reduxjs/toolkit";

// Helper function to create async thunks
const createThunk = (type, apiCall) => {
  return createAsyncThunk(type, async (payload, { rejectWithValue }) => {
    try {
      const response = await apiCall(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
};

export default createThunk;
