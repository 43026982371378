// pages/Feedbacks.js
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import PaginatedTable from "components/PaginatedTable";
import COLUMNS from "constants/columns";
import { fetchMaintenance } from "stores/actions";
import capitalize from "utils/capitalize";
import { useNavigate } from "react-router-dom";

const MaintenanceList = () => {
  const dispatch = useDispatch();
  const { maintenance } = useSelector((state) => state.maintenance);

  const navigate = useNavigate();

  const handleSelection = (data) => {
    navigate(`/maintenance/${data._id}`);
  };

  useEffect(() => {
    dispatch(fetchMaintenance());
  }, []);

  const mainentanceData = useMemo(() => {
    return maintenance.map((item) => ({
      ...item,
      name: `${capitalize(item.notification)} ${item.notificationType}`,
      headend: `${item?.headend?.code}-${item?.headend?.name}`,
      from: item?.maintenanceTime?.from,
      to: item?.maintenanceTime?.to,
    }));
  });

  return (
    <>
      <Card className="mt-3">
        <Card.Body>
          <PaginatedTable
            columns={COLUMNS["maintenance"]}
            data={mainentanceData}
            onView={handleSelection}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default MaintenanceList;
