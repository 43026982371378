import React from "react";

const ShimmerNameList = () => {
  // Array to represent 10 shimmer placeholders
  const namesArray = [...Array(25)];

  return (
    <div className="shimmer-name-list">
      {namesArray.map((_, index) => (
        <div key={index} className="shimmer-name-item">
          <div 
            className="shimmer-text" 
            style={{
              width: `${Math.floor(Math.random() * (80 - 40 + 1)) + 40}%`, // Randomized width for shimmer effect
              height: "20px",
              margin: "10px 0",
              backgroundColor: "#e0e0e0",
              borderRadius: "4px"
            }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default ShimmerNameList;
