import React, { useState } from "react";

const ReadMore = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const maxLength = 200; // Set a limit for truncation
  return (
    <>
      {typeof text === "object" ? (
        text
      ) : (
        <>
          {isExpanded
            ? text
            : text?.length > 0 &&
              text?.slice(0, maxLength) +
                (text?.length > maxLength ? "..." : "")}
          {text?.length > maxLength && (
            <button onClick={() => setIsExpanded(!isExpanded)} className="link">
              {isExpanded ? "Read Less" : "Read More"}
            </button>
          )}
        </>
      )}
    </>
  );
};

export default ReadMore;
