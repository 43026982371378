import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Row, Col, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Profile } from "stores/actions";

const ProfileAddForm = ({ show, onClose, currentProfile }) => {
  const dispatch = useDispatch();
  const [chosenProfiles, setChosenProfiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const { profileItems } = useSelector((state) => state.profiles); // All available profiles

  const [profileForm, setProfileForm] = useState({
    name: "",
    type: "monitoring",
    isActive: true,
    items: [],
  });

  useEffect(() => {
    if (currentProfile?._id) {
      setProfileForm(currentProfile);
      setChosenProfiles(currentProfile.items || []);
    } else {
      setProfileForm({
        name: "",
        type: "monitoring",
        isActive: true,
        items: [],
      });
      setChosenProfiles([]);
    }
  }, [currentProfile]);

  const handleToggleProfile = (profileId) => {
    setChosenProfiles((prevChosen) =>
      prevChosen.some((profile) => profile._id === profileId)
        ? prevChosen.filter((profile) => profile._id !== profileId)
        : [
            ...prevChosen,
            profileItems.find((profile) => profile._id === profileId),
          ]
    );
  };

  const handleFormChange = (field, value) => {
    setProfileForm((prevForm) => ({ ...prevForm, [field]: value }));
  };

  const handleSaveProfiles = async () => {
    const profileData = { ...profileForm, items: chosenProfiles };
    if (currentProfile?._id) {
      await dispatch(Profile.updateProfile(profileData));
    } else {
      await dispatch(Profile.createProfile(profileData));
    }
    onClose();
  };

  const filteredProfileItems = profileItems.filter((profile) =>
    profile.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal show={show} onHide={onClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>Manage Monitoring Profiles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-3">
          <Card.Body>
            <Form.Group as={Row} controlId="profileName">
              <Form.Label column sm={3}>
                Name
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="text"
                  value={profileForm.name}
                  placeholder="Enter name"
                  onChange={(e) => handleFormChange("name", e.target.value)}
                />
              </Col>
            </Form.Group>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Form.Group as={Row} controlId="profileItems">
              <Form.Label column sm={12} className="mb-2">
                <h5>
                  <b>Profile Items</b>
                </h5>
                <small className="sub-info">
                  Choose profile items from the list
                </small>
              </Form.Label>
            </Form.Group>
            <Row>
              <Col sm={3} className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Search items"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Col>
            </Row>
            <ul className="select-list mt-3">
              {filteredProfileItems.map((profile) => (
                <li
                  key={`profile-${profile._id}`}
                  onClick={() => handleToggleProfile(profile._id)}
                  className={`m-1 p-2 border rounded ${
                    chosenProfiles.some((p) => p._id === profile._id)
                      ? "bg-primary text-white"
                      : "bg-light"
                  }`}
                >
                  <Form.Check
                    type="checkbox"
                    className="float-start me-2"
                    checked={chosenProfiles.some((p) => p._id === profile._id)}
                  />
                  {profile.name}
                </li>
              ))}
            </ul>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSaveProfiles}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProfileAddForm;
