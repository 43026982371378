import React from "react";
import { Card, Row, Col, Dropdown, ListGroup } from "react-bootstrap";

const ShimmerCard = () => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row>
          <Col className="mb-3 px-3">
            <div className="mb-3">
              <h5
                className="shimmer shimmer-text"
                style={{ width: "60%", height: "20px" }}
              >
                &nbsp;
              </h5>
              <small
                className="sub-info shimmer shimmer-text"
                style={{ width: "40%" }}
              >
                &nbsp;
              </small>
            </div>
            <Row className="mb-2">
              <Col>
                <div>
                  <h5
                    className="shimmer shimmer-text"
                    style={{ width: "30%", height: "40px" }}
                  >
                    &nbsp;
                  </h5>
                </div>
              </Col>
            </Row>
            <Row className="mt-4 mb-2">
              <Col>
                <div>
                  <h5 className="shimmer shimmer-text" style={{ width: "30%" }}>
                    &nbsp;
                  </h5>
                </div>
              </Col>
              <Col className="col-auto">
                <b
                  className="gradient-txt shimmer shimmer-text"
                  style={{ fontSize: "1.3rem", width: "30px" }}
                >
                  &nbsp;
                </b>
              </Col>
            </Row>
            <div
              style={{
                maxHeight: "28vh",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              {Array(2)
                .fill("")
                .map((_, index) => (
                  <div className="mb-2" key={index}>
                    <Row className="mt-1">
                      <Col md={10}>
                        <div>
                          <h6
                            className="gradient-txt shimmer shimmer-text"
                            style={{ fontSize: "1.1rem", width: "70%" }}
                          >
                            &nbsp;
                          </h6>
                        </div>
                      </Col>
                      <Col className="col-auto">
                        <b
                          className="gradient-txt shimmer shimmer-text"
                          style={{ fontSize: "1.1rem", width: "20px" }}
                        >
                          &nbsp;
                        </b>
                      </Col>
                    </Row>

                    <ListGroup variant="flush">
                      {Array(2)
                        .fill("")
                        .map((_, i) => (
                          <ListGroup.Item key={i}>
                            <span
                              className="shimmer shimmer-text"
                              style={{ width: "40%" }}
                            >
                              &nbsp;
                            </span>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </div>
                ))}
            </div>
          </Col>
          {/* <Col md={9}>
            <div
              style={{
                height: "28vh",
                backgroundColor: "#f1f3f4",
              }}
              className="shimmer shimmer-box"
            >
              &nbsp;
            </div>
          </Col> */}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ShimmerCard;
