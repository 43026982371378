import { PaginatedTable } from "components";
import Highlight from "components/HighlightText";
import COLUMNS from "constants/columns";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const { Accordion, Badge } = require("react-bootstrap");

const HeadendResult = ({ headends, sendLog, query }) => {
  const navigate = useNavigate();
  const redirectToManage = (headend) => {
    sendLog("redirect", headend?.name);
    navigate(`/headends/${headend?._id}`);
  };

  const headendData = useMemo(() => {
    return headends.map((headend) => {
      return {
        id: headend?._id,
        name: (
          <b className="link" onClick={() => redirectToManage(headend)}>
            <Highlight
              text={`${headend?.code || ""}-${headend?.name}`}
              highlight={query}
            />
          </b>
        ),
        infra: headend?.infra[0]?.name,
        category: headend?.category[0]?.name,
        fqdn: headend?.fqdn,
        active: headend?.active ? "Yes" : "No",
      };
    });
  }, [headends]);

  return (
    <Accordion.Item eventKey="headends">
      <Accordion.Header>
        Headends :
        <Badge pill bg="info">
          {headends?.length}
        </Badge>
      </Accordion.Header>
      <Accordion.Body>
        <PaginatedTable
          data={headendData}
          columns={COLUMNS["headend"]}
          perPage={20}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default HeadendResult;
