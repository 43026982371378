import { Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useActivityLogger from "hooks/useActivity";
import { Security as SecurityApi } from "stores/actions";
import ReportTable from "./Components/ReportTable";
import GenerativeAi from "./Components/GenerativeAi";

const Security = () => {
  const dispatch = useDispatch();
  const { nmap, loading } = useSelector((state) => state.security);
  const [key, setKey] = useState("nmap");

  const logActivity = useActivityLogger();
  useEffect(() => {
    logActivity({
      page: "security",
      activity: "page-load",
    });
    dispatch(SecurityApi.fetchNmapReport());
    dispatch(SecurityApi.fetchGenerativeAiChart());
    dispatch(SecurityApi.fetchGenerativeAiWebsites());
    dispatch(SecurityApi.fetchGenerativeAiPolicyAction());
  }, []);

  return (
    <div className="middle-section">
      <div className="my-2">
        <h5>
          <b>Security reports</b>
        </h5>
        <small className="sub-info">
          All security related reports are available here
        </small>
      </div>
      <Row className="manage-tabs my-2">
        <Tabs
          id="controlled-tabs"
          activeKey={key}
          onSelect={setKey}
          className="mb-3  mr-3 p-0"
          // as={Card}
        >
          <Tab eventKey="nmap" title="NMAP">
            <ReportTable nmap={nmap} />
          </Tab>
          <Tab eventKey="generativeAi" title="Generative AI">
            <GenerativeAi />
          </Tab>
        </Tabs>
      </Row>
    </div>
  );
};

export default Security;
