import { PaginatedTable } from "components";
import COLUMNS from "constants/columns";
import { useMemo } from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import capitalize from "utils/capitalize";

const HostView = ({ host }) => {
  const isDirector = useMemo(() => {
    const categoryName = host?.category?.name || host?.category;
    return categoryName?.toLowerCase() === "director";
  }, [host]);

  return (
    <>
      <Row className="mb-3">
        <Col md={12}>
          <Card className="mb-3">
            <Row>
              <Col md={12}>
                <Card.Body>
                  <div>
                    <h5>
                      <b>Basic Details</b>
                    </h5>
                    <small className="sub-info">
                      Basic details view of the Host
                    </small>
                  </div>

                  <Row className="mt-3">
                    {[
                      { label: "Name", value: host?.name },
                      { label: "Host Name", value: host?.hostName },
                      { label: "Headend", value: host?.headendName },
                      { label: "Cluster", value: host?.analyticName },
                      {
                        label: "Category",
                        value: host?.category?.name
                          ? capitalize(host?.category?.name)
                          : host?.category,
                      },
                      {
                        label: "Device Type",
                        value: host?.subtype || host?.type,
                      },
                      { label: "FQDN", value: host?.fqdn },
                      { label: "IP", value: host?.ipAddress },
                      { label: "Owner Org", value: host?.ownerOrgName },
                      { label: "Location", value: host?.location },
                      {
                        label: "Region",
                        value: host?.region?.name || host?.region,
                      },
                    ]
                      .filter((item) => item.value)
                      .map((item, index) => (
                        <Col md={6} key={index} className="mb-3">
                          <small>{item.label}</small>
                          <br />
                          <strong>{item.value}</strong>
                        </Col>
                      ))}
                  </Row>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={12}>
          <Card className="mb-3">
            <Card.Body>
              <div className="mb-3">
                <h5>
                  <b>Interface IPs</b>
                </h5>
                <small className="sub-info">
                  All IPs related to device will be displayed
                </small>
              </div>

              <PaginatedTable
                data={host?.ips}
                columns={COLUMNS["ip"]}
                paginationRequired={false}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="bottom-div bg-light text-center">
        <Link className="btn btn-primary w-50" to={`/host/${host?._id}`}>
          Detailed View
        </Link>
      </div>
    </>
  );
};

export default HostView;
